import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { useAppSelector } from '..';
import axios from 'axios';
import getCookie from '@/utils/getCookie';
import { endpointPathMap } from '@/utils/getOed';

export type OEDsSortMode =
  | 'default'
  | 'title'
  | 'modifyDateOldToNew'
  | 'modifyDateNewToOld'
  | 'creationDateNewToOld'
  | 'creationDateOldToNew';

export type OEDsData = {
  id: number;
  title: string;
  updatedAt: number;
  createdAt: number;
  oedType: string;
  url: string;
  active: boolean;
  metadata: Record<string, any>;
};

export type OEDsState = {
  initialData: OEDsData[] | null;
  data: OEDsData[] | null;
  isLoading: boolean;
  hasError: boolean;
  sortMode: OEDsSortMode;
  initialFilteredOeds: OEDsData[] | null;
  totalOeds: number;
  metadataKeys: Array<string>;
};

const initialState: OEDsState = {
  initialData: null,
  data: null,
  isLoading: false,
  hasError: false,
  sortMode: 'default',
  initialFilteredOeds: null,
  totalOeds: 0,
  metadataKeys: [],
};

async function getContent(
  oedType:
    | 'quiz'
    | 'pin'
    | 'pintexto'
    | 'acordeon'
    | 'slideshow'
    | 'quiz-moderna'
    | 'miniclipe-moderna'
    | 'galeria-moderna'
    | 'carrossel-moderna'
    | 'quiz-nextoed'
    | 'zoom'
    | 'carrossel-nextoed',
) {
  try {
    const userToken = getCookie('next-oed_user-auth-token');
    const response = await axios.get(
      `https://np-builder.nextpage.com.br/content-manager/collection-types/api::${endpointPathMap[oedType]}?page=1&pageSize=300`,
      {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      },
    );
    return { type: oedType, data: response.data.results };
  } catch (error) {
    return { type: oedType, data: [] };
  }
}

export const loadOeds = createAsyncThunk<
  { oeds: Array<OEDsData>; excludedOeds: Array<OEDsData> },
  undefined
>('oeds/get', async () => {
  const requests = [];
  requests.push(
    getContent('pin'),
    getContent('acordeon'),
    getContent('pintexto'),
    getContent('quiz'),
    getContent('slideshow'),
    getContent('quiz-moderna'),
    getContent('miniclipe-moderna'),
    getContent('galeria-moderna'),
    getContent('carrossel-moderna'),
    getContent('quiz-nextoed'),
    getContent('zoom'),
    getContent('carrossel-nextoed'),
  );

  const result = await Promise.all(requests);

  const arrOeds = result.reduce((acc, cur, _idx) => {
    const oeds = cur.data.map((oed: any) => {
      let OEDUrl = `https://np-builder-strapi-infografico.vercel.app/${cur.type}/${oed.id}`;
      if (cur.type === 'quiz')
        OEDUrl = `https://strapi-oedtemplate-renderer.vercel.app/${cur.type}/${oed.id}`;
      return {
        id: oed.id,
        title: oed.title,
        updatedAt: new Date(oed.updatedAt).getTime(),
        createdAt: new Date(oed.createdAt).getTime(),
        oedType: cur.type,
        url: OEDUrl,
        active: Boolean(oed.publishedAt),
        metadata: oed.metadata,
      };
    });
    return [...acc, ...oeds];
  }, [] as Array<OEDsData>);

  const excludedOeds = arrOeds.filter((oed: any) => oed.active === false);
  const activeOeds = arrOeds.filter((oed: any) => oed.active === true);

  function orderOEDs(oeds: Array<OEDsData>) {
    let listOeds = [...(oeds || [])];
    listOeds.sort((a, b) => b.updatedAt - a.updatedAt);
    return listOeds;
  }

  return { oeds: orderOEDs(activeOeds), excludedOeds: orderOEDs(excludedOeds) };
});

export const oedsSlice = createSlice({
  name: 'oeds',
  initialState,
  reducers: {
    sort: (state, action: PayloadAction<OEDsSortMode>) => {
      let newData = null;
      const newSortMode = action?.payload || 'default';

      if (
        !action.payload ||
        !state.initialData ||
        action.payload === 'default'
      ) {
        return {
          ...state,
          data: state.initialFilteredOeds,
          sortMode: newSortMode,
        };
      }

      if (action.payload === 'modifyDateNewToOld') {
        let listOeds = [...(state.data || [])];
        listOeds.sort((a, b) => b.updatedAt - a.updatedAt);
        newData = listOeds;
      }

      if (action.payload === 'modifyDateOldToNew') {
        let listOeds = [...(state.data || [])];
        listOeds.sort((a, b) => a.updatedAt - b.updatedAt);
        newData = listOeds;
      }

      if (action.payload === 'creationDateNewToOld') {
        let listOeds = [...(state.data || [])];
        listOeds.sort((a, b) => b.createdAt - a.createdAt);
        newData = listOeds;
      }

      if (action.payload === 'creationDateOldToNew') {
        let listOeds = [...(state.data || [])];
        listOeds.sort((a, b) => a.createdAt - b.createdAt);
        newData = listOeds;
      }

      if (action.payload === 'title') {
        let listOeds = [...(state.data || [])];
        listOeds.sort((a, b) => {
          const titleA = a.title.toLowerCase();
          const titleB = b.title.toLowerCase();

          if (titleA < titleB) return -1;
          if (titleA > titleB) return 1;
          return 0;
        });

        newData = listOeds;
      }

      return { ...state, data: newData, sortMode: newSortMode };
    },
    filterOedsBy: (
      state,
      action: PayloadAction<{ type: Array<string>; value: string }>,
    ) => {
      function removeDuplicates(array: Array<any>) {
        const seen = new Set();
        return array.filter((obj) => {
          const key = obj.id + '|' + obj.oedType;
          if (seen.has(key)) {
            return false;
          }
          seen.add(key);
          return true;
        });
      }

      const { type, value } = action.payload;

      if (!value || value.length === 0)
        return { ...state, data: state.initialData };

      const parsedTypes = type
        .filter((curT) => curT != 'title')
        .map((t) => {
          const splitted = t.split('metadata-');
          if (splitted.length > 1) splitted.shift();
          return splitted.join('metadata-');
        });

      const filteredOedsByMetadata =
        state.initialData?.filter((oed) => {
          let exists = false;
          parsedTypes.forEach((meta) => {
            const oedMetadata = (oed.metadata || {})[meta];
            if (oedMetadata && !exists)
              exists = oedMetadata.toLowerCase().includes(value.toLowerCase());
          });
          return exists;
        }) || [];

      const filteredOedsByTitle = type.includes('title')
        ? state.initialData?.filter((oed) =>
            oed.title.toLowerCase().includes(value.toLowerCase()),
          ) || []
        : [];

      const unifiedOEDs = removeDuplicates([
        ...filteredOedsByMetadata,
        ...filteredOedsByTitle,
      ]);

      return {
        ...state,
        data: unifiedOEDs,
        initialFilteredOeds: unifiedOEDs,
      };
    },
  },
  extraReducers(builder) {
    builder.addCase(loadOeds.pending, (state) => {
      state.isLoading = true;
      state.data = null;
      state.hasError = false;
    });

    builder.addCase(loadOeds.rejected, (state) => {
      state.isLoading = false;
      state.data = null;
      state.hasError = true;
    });

    builder.addCase(loadOeds.fulfilled, (state, action) => {
      const metadadosObject = {};
      action.payload.oeds.forEach((oed) => {
        Object.assign(metadadosObject, oed.metadata);
      });

      const oedsMetadados = Object.keys(metadadosObject);

      state.isLoading = false;
      state.data = action.payload.oeds;
      state.initialData = action.payload.oeds;
      state.initialFilteredOeds = action.payload.oeds;
      state.hasError = false;
      state.sortMode = 'default';
      state.totalOeds =
        action.payload.oeds.length + action.payload.excludedOeds.length;
      state.metadataKeys = oedsMetadados;
    });
  },
});

export const oeds = oedsSlice.reducer;

// console.log(oeds);

export const oedsActions = oedsSlice.actions;

export const useOeds = () => {
  return useAppSelector((state) => {
    const { isLoading, data, hasError } = state.oeds;
    console.log(data);
    return { isLoading, data, hasError };
  });
};
