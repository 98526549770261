export const colors = {
  'black-100': '#000000',
  'black-200': '#262626',

  'white-100': '#FFFFFF',
  'white-200': '#ECECEC',

  'gray-100': '#F2F2F2',
  'gray-150': '#F4F4F4',
  'gray-200': '#ECECEC',
  'gray-250': '#E2E2E2',

  'gray-300': '#CECECE',
  'gray-400': '#B7B7B7',
  'gray-500': '#ABABAB',
  'gray-600': '#7A7A7A',

  'gray-700': '#7E7E95',

  'purple-100': '#D4C9FF',
  'purple-200': '#6E4FC5',
  'purple-300': '#522eb7',

  errorMessage: '#ff0000',

  'primary-100': '#F4DECD', // from Figma
  'primary-200': '#ffcda9',
  'primary-300': '#faae77',
  'primary-400': '#F7B141', // from Figma
  'primary-500': '#EC7016', // from Figma
  'primary-600': '#c9631a',
  'primary-700': '#a5571f',

  'secondary-100': '#E1E9F8', // from Figma
  'secondary-200': '#afc8f9',
  'secondary-300': '#88A5DF', // from Figma
  'secondary-400': '#5a87e1',
  'secondary-500': '#2D67D8', // from Figma
  'secondary-600': '#1453CE', // from Figma
  'secondary-700': '#2e5195',
};

export const fontSizes = {
  xxs: '0.625rem', // 10px
  xs: '0.75rem', // 12px
  sm: '0.875rem', // 14px
  md: '1rem', // 16px
  lg: '1.125rem', // 18px
  xl: '1.25rem', // 20px
  '2xl': '1.375rem', // 22px
  '3xl': '1.5rem', // 24px
  '4xl': '1.625rem', // 26px
  '5xl': '1.75rem', // 28px
  '6xl': '1.875rem', // 30px
  '7xl': '2rem', // 32px
};

export const fontWeights = {
  regular: '400',
  medium: '500',
  semiBold: '600',
  bold: '700',
};

export const fonts = {
  default: 'Poppins, sans-serif',
  code: 'monospace',
};

export const radii = {
  px: '1px',
  xs: '4px',
  sm: '6px',
  md: '10px',
  lg: '16px',
  xl: '32px',
  '2xl': '50px',
  full: '99999px',
};

export const space = {
  // 16xrem
  1: '0.25rem', // 4px
  2: '0.5rem', // 8px
  3: '0.75rem', // 12px
  4: '1rem', // 16px
  5: '1.25rem', // 20px
  6: '1.5rem', // 24px
  7: '1.75rem', // 28px
  8: '2rem', // 32px
  9: '2.25rem', // 36px
  10: '2.5rem', // 40px
  11: '2.625rem', // 42px
  12: '2.75rem', // 44px
  13: '2.875rem', // 46px
  14: '3rem', // 48px
  15: '3.125rem', // 50px
};
