import { globalStyles } from '@/styles/stitches/global';
import { AppProps } from 'next/app';
import { Router } from 'next/router';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ToastContainer } from 'react-toastify';
import NProgress from 'nprogress';
import Modal from 'react-modal';
import { Provider as ReduxProvider } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import { store } from '@/store';
import * as yup from 'yup';
import isHexColor from '@/utils/isHexColor';

Router.events.on('routeChangeStart', () => {
  NProgress.start();
});

Router.events.on('routeChangeComplete', () => {
  NProgress.done();
});

Router.events.on('routeChangeError', () => {
  NProgress.done();
});

declare module 'yup' {
  interface StringSchema {
    hexColor(): StringSchema;
  }

  interface ObjectSchema<
    TIn extends yup.Maybe<yup.AnyObject>,
    TContext extends yup.AnyObject = yup.AnyObject,
    TDefault = any,
    TFlags extends yup.Flags = '',
    TType extends yup.Maybe<yup.AnyObject> = yup.AnyObject | undefined,
    TOut extends TType = TType,
  > extends yup.Schema<TType, TContext, TOut> {
    reactSelect(): ObjectSchema<{ label: string; value: string }, TContext>;
  }
}

yup.addMethod(yup.string, 'hexColor', function () {
  return this.test(`hexColor`, 'Cor inválida', function (value) {
    const result = isHexColor(value || '');
    return result;
  });
});

yup.addMethod(yup.object, 'reactSelect', function () {
  return this.shape({
    label: yup.string().required(''),
    value: yup.string().required(''),
  }).test(`reactSelect`, 'Selecione uma opção', function (value) {
    if (!value.value) return false;
    return true;
  });
});

export const queryClient = new QueryClient();

Modal.setAppElement('#__next');

globalStyles();

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <QueryClientProvider client={queryClient}>
      <ReduxProvider store={store}>
        <ToastContainer />
        <Component {...pageProps} />
      </ReduxProvider>
    </QueryClientProvider>
  );
}

export default MyApp;
