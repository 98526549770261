import { useMutation, useQuery, UseMutationOptions, UseQueryOptions } from 'react-query';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };

function fetcher<TData, TVariables>(endpoint: string, requestInit: RequestInit, query: string, variables?: TVariables) {
  return async (): Promise<TData> => {
    const res = await fetch(endpoint, {
      method: 'POST',
      ...requestInit,
      body: JSON.stringify({ query, variables }),
    });

    const json = await res.json();

    if (json.errors) {
      const { message } = json.errors[0];

      throw new Error(message);
    }

    return json.data;
  }
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  jsonb: { input: any; output: any; }
  timestamp: { input: any; output: any; }
  timestamptz: { input: any; output: any; }
  uuid: { input: any; output: any; }
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']['input']>;
  _gt?: InputMaybe<Scalars['Boolean']['input']>;
  _gte?: InputMaybe<Scalars['Boolean']['input']>;
  _in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Boolean']['input']>;
  _lte?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Scalars['Boolean']['input']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']['input']>>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']['input']>;
  _gt?: InputMaybe<Scalars['Int']['input']>;
  _gte?: InputMaybe<Scalars['Int']['input']>;
  _in?: InputMaybe<Array<Scalars['Int']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Int']['input']>;
  _lte?: InputMaybe<Scalars['Int']['input']>;
  _neq?: InputMaybe<Scalars['Int']['input']>;
  _nin?: InputMaybe<Array<Scalars['Int']['input']>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Array_Comparison_Exp = {
  /** is the array contained in the given array value */
  _contained_in?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the array contain the given value */
  _contains?: InputMaybe<Array<Scalars['String']['input']>>;
  _eq?: InputMaybe<Array<Scalars['String']['input']>>;
  _gt?: InputMaybe<Array<Scalars['String']['input']>>;
  _gte?: InputMaybe<Array<Scalars['String']['input']>>;
  _in?: InputMaybe<Array<Array<Scalars['String']['input']>>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Array<Scalars['String']['input']>>;
  _lte?: InputMaybe<Array<Scalars['String']['input']>>;
  _neq?: InputMaybe<Array<Scalars['String']['input']>>;
  _nin?: InputMaybe<Array<Array<Scalars['String']['input']>>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']['input']>;
  _gt?: InputMaybe<Scalars['String']['input']>;
  _gte?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']['input']>;
  _in?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']['input']>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']['input']>;
  _lt?: InputMaybe<Scalars['String']['input']>;
  _lte?: InputMaybe<Scalars['String']['input']>;
  _neq?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']['input']>;
  _nin?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "address" */
export type Address = {
  __typename?: 'address';
  active?: Maybe<Scalars['Boolean']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  district?: Maybe<Scalars['String']['output']>;
  id: Scalars['uuid']['output'];
  state?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  street_number?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  /** An object relationship */
  user?: Maybe<User>;
  user_id?: Maybe<Scalars['uuid']['output']>;
  zipcode?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "address" */
export type Address_Aggregate = {
  __typename?: 'address_aggregate';
  aggregate?: Maybe<Address_Aggregate_Fields>;
  nodes: Array<Address>;
};

export type Address_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Address_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Address_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Address_Aggregate_Bool_Exp_Count>;
};

export type Address_Aggregate_Bool_Exp_Bool_And = {
  arguments: Address_Select_Column_Address_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Address_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Address_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Address_Select_Column_Address_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Address_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Address_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Address_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Address_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "address" */
export type Address_Aggregate_Fields = {
  __typename?: 'address_aggregate_fields';
  avg?: Maybe<Address_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Address_Max_Fields>;
  min?: Maybe<Address_Min_Fields>;
  stddev?: Maybe<Address_Stddev_Fields>;
  stddev_pop?: Maybe<Address_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Address_Stddev_Samp_Fields>;
  sum?: Maybe<Address_Sum_Fields>;
  var_pop?: Maybe<Address_Var_Pop_Fields>;
  var_samp?: Maybe<Address_Var_Samp_Fields>;
  variance?: Maybe<Address_Variance_Fields>;
};


/** aggregate fields of "address" */
export type Address_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Address_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "address" */
export type Address_Aggregate_Order_By = {
  avg?: InputMaybe<Address_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Address_Max_Order_By>;
  min?: InputMaybe<Address_Min_Order_By>;
  stddev?: InputMaybe<Address_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Address_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Address_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Address_Sum_Order_By>;
  var_pop?: InputMaybe<Address_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Address_Var_Samp_Order_By>;
  variance?: InputMaybe<Address_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "address" */
export type Address_Arr_Rel_Insert_Input = {
  data: Array<Address_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Address_On_Conflict>;
};

/** aggregate avg on columns */
export type Address_Avg_Fields = {
  __typename?: 'address_avg_fields';
  street_number?: Maybe<Scalars['Float']['output']>;
  zipcode?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "address" */
export type Address_Avg_Order_By = {
  street_number?: InputMaybe<Order_By>;
  zipcode?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "address". All fields are combined with a logical 'AND'. */
export type Address_Bool_Exp = {
  _and?: InputMaybe<Array<Address_Bool_Exp>>;
  _not?: InputMaybe<Address_Bool_Exp>;
  _or?: InputMaybe<Array<Address_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  city?: InputMaybe<String_Comparison_Exp>;
  country?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  district?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  state?: InputMaybe<String_Comparison_Exp>;
  street?: InputMaybe<String_Comparison_Exp>;
  street_number?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  zipcode?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "address" */
export enum Address_Constraint {
  /** unique or primary key constraint on columns "id" */
  AddressPkey = 'address_pkey'
}

/** input type for incrementing numeric columns in table "address" */
export type Address_Inc_Input = {
  street_number?: InputMaybe<Scalars['Int']['input']>;
  zipcode?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "address" */
export type Address_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  district?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  street_number?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
  zipcode?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type Address_Max_Fields = {
  __typename?: 'address_max_fields';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  district?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  street_number?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
  zipcode?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "address" */
export type Address_Max_Order_By = {
  city?: InputMaybe<Order_By>;
  country?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  district?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  street?: InputMaybe<Order_By>;
  street_number?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  zipcode?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Address_Min_Fields = {
  __typename?: 'address_min_fields';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  district?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  street_number?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
  zipcode?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "address" */
export type Address_Min_Order_By = {
  city?: InputMaybe<Order_By>;
  country?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  district?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  street?: InputMaybe<Order_By>;
  street_number?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  zipcode?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "address" */
export type Address_Mutation_Response = {
  __typename?: 'address_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Address>;
};

/** on_conflict condition type for table "address" */
export type Address_On_Conflict = {
  constraint: Address_Constraint;
  update_columns?: Array<Address_Update_Column>;
  where?: InputMaybe<Address_Bool_Exp>;
};

/** Ordering options when selecting data from "address". */
export type Address_Order_By = {
  active?: InputMaybe<Order_By>;
  city?: InputMaybe<Order_By>;
  country?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  district?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  street?: InputMaybe<Order_By>;
  street_number?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
  zipcode?: InputMaybe<Order_By>;
};

/** primary key columns input for table: address */
export type Address_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "address" */
export enum Address_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  City = 'city',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  District = 'district',
  /** column name */
  Id = 'id',
  /** column name */
  State = 'state',
  /** column name */
  Street = 'street',
  /** column name */
  StreetNumber = 'street_number',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Zipcode = 'zipcode'
}

/** select "address_aggregate_bool_exp_bool_and_arguments_columns" columns of table "address" */
export enum Address_Select_Column_Address_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** select "address_aggregate_bool_exp_bool_or_arguments_columns" columns of table "address" */
export enum Address_Select_Column_Address_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** input type for updating data in table "address" */
export type Address_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  district?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  street_number?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
  zipcode?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type Address_Stddev_Fields = {
  __typename?: 'address_stddev_fields';
  street_number?: Maybe<Scalars['Float']['output']>;
  zipcode?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "address" */
export type Address_Stddev_Order_By = {
  street_number?: InputMaybe<Order_By>;
  zipcode?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Address_Stddev_Pop_Fields = {
  __typename?: 'address_stddev_pop_fields';
  street_number?: Maybe<Scalars['Float']['output']>;
  zipcode?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "address" */
export type Address_Stddev_Pop_Order_By = {
  street_number?: InputMaybe<Order_By>;
  zipcode?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Address_Stddev_Samp_Fields = {
  __typename?: 'address_stddev_samp_fields';
  street_number?: Maybe<Scalars['Float']['output']>;
  zipcode?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "address" */
export type Address_Stddev_Samp_Order_By = {
  street_number?: InputMaybe<Order_By>;
  zipcode?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "address" */
export type Address_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Address_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Address_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  district?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  street_number?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
  zipcode?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type Address_Sum_Fields = {
  __typename?: 'address_sum_fields';
  street_number?: Maybe<Scalars['Int']['output']>;
  zipcode?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "address" */
export type Address_Sum_Order_By = {
  street_number?: InputMaybe<Order_By>;
  zipcode?: InputMaybe<Order_By>;
};

/** update columns of table "address" */
export enum Address_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  City = 'city',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  District = 'district',
  /** column name */
  Id = 'id',
  /** column name */
  State = 'state',
  /** column name */
  Street = 'street',
  /** column name */
  StreetNumber = 'street_number',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Zipcode = 'zipcode'
}

export type Address_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Address_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Address_Set_Input>;
  /** filter the rows which have to be updated */
  where: Address_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Address_Var_Pop_Fields = {
  __typename?: 'address_var_pop_fields';
  street_number?: Maybe<Scalars['Float']['output']>;
  zipcode?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "address" */
export type Address_Var_Pop_Order_By = {
  street_number?: InputMaybe<Order_By>;
  zipcode?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Address_Var_Samp_Fields = {
  __typename?: 'address_var_samp_fields';
  street_number?: Maybe<Scalars['Float']['output']>;
  zipcode?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "address" */
export type Address_Var_Samp_Order_By = {
  street_number?: InputMaybe<Order_By>;
  zipcode?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Address_Variance_Fields = {
  __typename?: 'address_variance_fields';
  street_number?: Maybe<Scalars['Float']['output']>;
  zipcode?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "address" */
export type Address_Variance_Order_By = {
  street_number?: InputMaybe<Order_By>;
  zipcode?: InputMaybe<Order_By>;
};

/** columns and relationships of "billing_address" */
export type Billing_Address = {
  __typename?: 'billing_address';
  active?: Maybe<Scalars['Boolean']['output']>;
  /** An object relationship */
  billing_address?: Maybe<Billing_Address>;
  /** An array relationship */
  billing_addresses: Array<Billing_Address>;
  /** An aggregate relationship */
  billing_addresses_aggregate: Billing_Address_Aggregate;
  city?: Maybe<Scalars['String']['output']>;
  contact_name?: Maybe<Scalars['String']['output']>;
  contact_number?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id: Scalars['uuid']['output'];
  state?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  street_number?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
  zipcode?: Maybe<Scalars['Int']['output']>;
};


/** columns and relationships of "billing_address" */
export type Billing_AddressBilling_AddressesArgs = {
  distinct_on?: InputMaybe<Array<Billing_Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Billing_Address_Order_By>>;
  where?: InputMaybe<Billing_Address_Bool_Exp>;
};


/** columns and relationships of "billing_address" */
export type Billing_AddressBilling_Addresses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Billing_Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Billing_Address_Order_By>>;
  where?: InputMaybe<Billing_Address_Bool_Exp>;
};

/** aggregated selection of "billing_address" */
export type Billing_Address_Aggregate = {
  __typename?: 'billing_address_aggregate';
  aggregate?: Maybe<Billing_Address_Aggregate_Fields>;
  nodes: Array<Billing_Address>;
};

export type Billing_Address_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Billing_Address_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Billing_Address_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Billing_Address_Aggregate_Bool_Exp_Count>;
};

export type Billing_Address_Aggregate_Bool_Exp_Bool_And = {
  arguments: Billing_Address_Select_Column_Billing_Address_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Billing_Address_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Billing_Address_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Billing_Address_Select_Column_Billing_Address_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Billing_Address_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Billing_Address_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Billing_Address_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Billing_Address_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "billing_address" */
export type Billing_Address_Aggregate_Fields = {
  __typename?: 'billing_address_aggregate_fields';
  avg?: Maybe<Billing_Address_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Billing_Address_Max_Fields>;
  min?: Maybe<Billing_Address_Min_Fields>;
  stddev?: Maybe<Billing_Address_Stddev_Fields>;
  stddev_pop?: Maybe<Billing_Address_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Billing_Address_Stddev_Samp_Fields>;
  sum?: Maybe<Billing_Address_Sum_Fields>;
  var_pop?: Maybe<Billing_Address_Var_Pop_Fields>;
  var_samp?: Maybe<Billing_Address_Var_Samp_Fields>;
  variance?: Maybe<Billing_Address_Variance_Fields>;
};


/** aggregate fields of "billing_address" */
export type Billing_Address_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Billing_Address_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "billing_address" */
export type Billing_Address_Aggregate_Order_By = {
  avg?: InputMaybe<Billing_Address_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Billing_Address_Max_Order_By>;
  min?: InputMaybe<Billing_Address_Min_Order_By>;
  stddev?: InputMaybe<Billing_Address_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Billing_Address_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Billing_Address_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Billing_Address_Sum_Order_By>;
  var_pop?: InputMaybe<Billing_Address_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Billing_Address_Var_Samp_Order_By>;
  variance?: InputMaybe<Billing_Address_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "billing_address" */
export type Billing_Address_Arr_Rel_Insert_Input = {
  data: Array<Billing_Address_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Billing_Address_On_Conflict>;
};

/** aggregate avg on columns */
export type Billing_Address_Avg_Fields = {
  __typename?: 'billing_address_avg_fields';
  street_number?: Maybe<Scalars['Float']['output']>;
  zipcode?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "billing_address" */
export type Billing_Address_Avg_Order_By = {
  street_number?: InputMaybe<Order_By>;
  zipcode?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "billing_address". All fields are combined with a logical 'AND'. */
export type Billing_Address_Bool_Exp = {
  _and?: InputMaybe<Array<Billing_Address_Bool_Exp>>;
  _not?: InputMaybe<Billing_Address_Bool_Exp>;
  _or?: InputMaybe<Array<Billing_Address_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  billing_address?: InputMaybe<Billing_Address_Bool_Exp>;
  billing_addresses?: InputMaybe<Billing_Address_Bool_Exp>;
  billing_addresses_aggregate?: InputMaybe<Billing_Address_Aggregate_Bool_Exp>;
  city?: InputMaybe<String_Comparison_Exp>;
  contact_name?: InputMaybe<String_Comparison_Exp>;
  contact_number?: InputMaybe<String_Comparison_Exp>;
  country?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  state?: InputMaybe<String_Comparison_Exp>;
  street?: InputMaybe<String_Comparison_Exp>;
  street_number?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  zipcode?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "billing_address" */
export enum Billing_Address_Constraint {
  /** unique or primary key constraint on columns "id" */
  BillingAddressPkey = 'billing_address_pkey'
}

/** input type for incrementing numeric columns in table "billing_address" */
export type Billing_Address_Inc_Input = {
  street_number?: InputMaybe<Scalars['Int']['input']>;
  zipcode?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "billing_address" */
export type Billing_Address_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  billing_address?: InputMaybe<Billing_Address_Obj_Rel_Insert_Input>;
  billing_addresses?: InputMaybe<Billing_Address_Arr_Rel_Insert_Input>;
  city?: InputMaybe<Scalars['String']['input']>;
  contact_name?: InputMaybe<Scalars['String']['input']>;
  contact_number?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  street_number?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
  zipcode?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type Billing_Address_Max_Fields = {
  __typename?: 'billing_address_max_fields';
  city?: Maybe<Scalars['String']['output']>;
  contact_name?: Maybe<Scalars['String']['output']>;
  contact_number?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  street_number?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
  zipcode?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "billing_address" */
export type Billing_Address_Max_Order_By = {
  city?: InputMaybe<Order_By>;
  contact_name?: InputMaybe<Order_By>;
  contact_number?: InputMaybe<Order_By>;
  country?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  street?: InputMaybe<Order_By>;
  street_number?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  zipcode?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Billing_Address_Min_Fields = {
  __typename?: 'billing_address_min_fields';
  city?: Maybe<Scalars['String']['output']>;
  contact_name?: Maybe<Scalars['String']['output']>;
  contact_number?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  street_number?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
  zipcode?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "billing_address" */
export type Billing_Address_Min_Order_By = {
  city?: InputMaybe<Order_By>;
  contact_name?: InputMaybe<Order_By>;
  contact_number?: InputMaybe<Order_By>;
  country?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  street?: InputMaybe<Order_By>;
  street_number?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  zipcode?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "billing_address" */
export type Billing_Address_Mutation_Response = {
  __typename?: 'billing_address_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Billing_Address>;
};

/** input type for inserting object relation for remote table "billing_address" */
export type Billing_Address_Obj_Rel_Insert_Input = {
  data: Billing_Address_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Billing_Address_On_Conflict>;
};

/** on_conflict condition type for table "billing_address" */
export type Billing_Address_On_Conflict = {
  constraint: Billing_Address_Constraint;
  update_columns?: Array<Billing_Address_Update_Column>;
  where?: InputMaybe<Billing_Address_Bool_Exp>;
};

/** Ordering options when selecting data from "billing_address". */
export type Billing_Address_Order_By = {
  active?: InputMaybe<Order_By>;
  billing_address?: InputMaybe<Billing_Address_Order_By>;
  billing_addresses_aggregate?: InputMaybe<Billing_Address_Aggregate_Order_By>;
  city?: InputMaybe<Order_By>;
  contact_name?: InputMaybe<Order_By>;
  contact_number?: InputMaybe<Order_By>;
  country?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  street?: InputMaybe<Order_By>;
  street_number?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  zipcode?: InputMaybe<Order_By>;
};

/** primary key columns input for table: billing_address */
export type Billing_Address_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "billing_address" */
export enum Billing_Address_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  City = 'city',
  /** column name */
  ContactName = 'contact_name',
  /** column name */
  ContactNumber = 'contact_number',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  State = 'state',
  /** column name */
  Street = 'street',
  /** column name */
  StreetNumber = 'street_number',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Zipcode = 'zipcode'
}

/** select "billing_address_aggregate_bool_exp_bool_and_arguments_columns" columns of table "billing_address" */
export enum Billing_Address_Select_Column_Billing_Address_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** select "billing_address_aggregate_bool_exp_bool_or_arguments_columns" columns of table "billing_address" */
export enum Billing_Address_Select_Column_Billing_Address_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** input type for updating data in table "billing_address" */
export type Billing_Address_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  contact_name?: InputMaybe<Scalars['String']['input']>;
  contact_number?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  street_number?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
  zipcode?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type Billing_Address_Stddev_Fields = {
  __typename?: 'billing_address_stddev_fields';
  street_number?: Maybe<Scalars['Float']['output']>;
  zipcode?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "billing_address" */
export type Billing_Address_Stddev_Order_By = {
  street_number?: InputMaybe<Order_By>;
  zipcode?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Billing_Address_Stddev_Pop_Fields = {
  __typename?: 'billing_address_stddev_pop_fields';
  street_number?: Maybe<Scalars['Float']['output']>;
  zipcode?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "billing_address" */
export type Billing_Address_Stddev_Pop_Order_By = {
  street_number?: InputMaybe<Order_By>;
  zipcode?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Billing_Address_Stddev_Samp_Fields = {
  __typename?: 'billing_address_stddev_samp_fields';
  street_number?: Maybe<Scalars['Float']['output']>;
  zipcode?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "billing_address" */
export type Billing_Address_Stddev_Samp_Order_By = {
  street_number?: InputMaybe<Order_By>;
  zipcode?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "billing_address" */
export type Billing_Address_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Billing_Address_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Billing_Address_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  contact_name?: InputMaybe<Scalars['String']['input']>;
  contact_number?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  street_number?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
  zipcode?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type Billing_Address_Sum_Fields = {
  __typename?: 'billing_address_sum_fields';
  street_number?: Maybe<Scalars['Int']['output']>;
  zipcode?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "billing_address" */
export type Billing_Address_Sum_Order_By = {
  street_number?: InputMaybe<Order_By>;
  zipcode?: InputMaybe<Order_By>;
};

/** update columns of table "billing_address" */
export enum Billing_Address_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  City = 'city',
  /** column name */
  ContactName = 'contact_name',
  /** column name */
  ContactNumber = 'contact_number',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  State = 'state',
  /** column name */
  Street = 'street',
  /** column name */
  StreetNumber = 'street_number',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Zipcode = 'zipcode'
}

export type Billing_Address_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Billing_Address_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Billing_Address_Set_Input>;
  /** filter the rows which have to be updated */
  where: Billing_Address_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Billing_Address_Var_Pop_Fields = {
  __typename?: 'billing_address_var_pop_fields';
  street_number?: Maybe<Scalars['Float']['output']>;
  zipcode?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "billing_address" */
export type Billing_Address_Var_Pop_Order_By = {
  street_number?: InputMaybe<Order_By>;
  zipcode?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Billing_Address_Var_Samp_Fields = {
  __typename?: 'billing_address_var_samp_fields';
  street_number?: Maybe<Scalars['Float']['output']>;
  zipcode?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "billing_address" */
export type Billing_Address_Var_Samp_Order_By = {
  street_number?: InputMaybe<Order_By>;
  zipcode?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Billing_Address_Variance_Fields = {
  __typename?: 'billing_address_variance_fields';
  street_number?: Maybe<Scalars['Float']['output']>;
  zipcode?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "billing_address" */
export type Billing_Address_Variance_Order_By = {
  street_number?: InputMaybe<Order_By>;
  zipcode?: InputMaybe<Order_By>;
};

/** columns and relationships of "buyer" */
export type Buyer = {
  __typename?: 'buyer';
  active?: Maybe<Scalars['Boolean']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id: Scalars['uuid']['output'];
  iugu_account_id?: Maybe<Scalars['String']['output']>;
  iugu_invoice_Id?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  plan?: Maybe<Plan>;
  plan_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  /** An object relationship */
  user?: Maybe<User>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** aggregated selection of "buyer" */
export type Buyer_Aggregate = {
  __typename?: 'buyer_aggregate';
  aggregate?: Maybe<Buyer_Aggregate_Fields>;
  nodes: Array<Buyer>;
};

export type Buyer_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Buyer_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Buyer_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Buyer_Aggregate_Bool_Exp_Count>;
};

export type Buyer_Aggregate_Bool_Exp_Bool_And = {
  arguments: Buyer_Select_Column_Buyer_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Buyer_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Buyer_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Buyer_Select_Column_Buyer_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Buyer_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Buyer_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Buyer_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Buyer_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "buyer" */
export type Buyer_Aggregate_Fields = {
  __typename?: 'buyer_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Buyer_Max_Fields>;
  min?: Maybe<Buyer_Min_Fields>;
};


/** aggregate fields of "buyer" */
export type Buyer_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Buyer_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "buyer" */
export type Buyer_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Buyer_Max_Order_By>;
  min?: InputMaybe<Buyer_Min_Order_By>;
};

/** input type for inserting array relation for remote table "buyer" */
export type Buyer_Arr_Rel_Insert_Input = {
  data: Array<Buyer_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Buyer_On_Conflict>;
};

/** Boolean expression to filter rows from the table "buyer". All fields are combined with a logical 'AND'. */
export type Buyer_Bool_Exp = {
  _and?: InputMaybe<Array<Buyer_Bool_Exp>>;
  _not?: InputMaybe<Buyer_Bool_Exp>;
  _or?: InputMaybe<Array<Buyer_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  iugu_account_id?: InputMaybe<String_Comparison_Exp>;
  iugu_invoice_Id?: InputMaybe<String_Comparison_Exp>;
  plan?: InputMaybe<Plan_Bool_Exp>;
  plan_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "buyer" */
export enum Buyer_Constraint {
  /** unique or primary key constraint on columns "id" */
  BuyerPkey = 'buyer_pkey'
}

/** input type for inserting data into table "buyer" */
export type Buyer_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  iugu_account_id?: InputMaybe<Scalars['String']['input']>;
  iugu_invoice_Id?: InputMaybe<Scalars['String']['input']>;
  plan?: InputMaybe<Plan_Obj_Rel_Insert_Input>;
  plan_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Buyer_Max_Fields = {
  __typename?: 'buyer_max_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  iugu_account_id?: Maybe<Scalars['String']['output']>;
  iugu_invoice_Id?: Maybe<Scalars['String']['output']>;
  plan_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "buyer" */
export type Buyer_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  iugu_account_id?: InputMaybe<Order_By>;
  iugu_invoice_Id?: InputMaybe<Order_By>;
  plan_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Buyer_Min_Fields = {
  __typename?: 'buyer_min_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  iugu_account_id?: Maybe<Scalars['String']['output']>;
  iugu_invoice_Id?: Maybe<Scalars['String']['output']>;
  plan_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "buyer" */
export type Buyer_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  iugu_account_id?: InputMaybe<Order_By>;
  iugu_invoice_Id?: InputMaybe<Order_By>;
  plan_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "buyer" */
export type Buyer_Mutation_Response = {
  __typename?: 'buyer_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Buyer>;
};

/** on_conflict condition type for table "buyer" */
export type Buyer_On_Conflict = {
  constraint: Buyer_Constraint;
  update_columns?: Array<Buyer_Update_Column>;
  where?: InputMaybe<Buyer_Bool_Exp>;
};

/** Ordering options when selecting data from "buyer". */
export type Buyer_Order_By = {
  active?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  iugu_account_id?: InputMaybe<Order_By>;
  iugu_invoice_Id?: InputMaybe<Order_By>;
  plan?: InputMaybe<Plan_Order_By>;
  plan_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: buyer */
export type Buyer_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "buyer" */
export enum Buyer_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IuguAccountId = 'iugu_account_id',
  /** column name */
  IuguInvoiceId = 'iugu_invoice_Id',
  /** column name */
  PlanId = 'plan_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** select "buyer_aggregate_bool_exp_bool_and_arguments_columns" columns of table "buyer" */
export enum Buyer_Select_Column_Buyer_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** select "buyer_aggregate_bool_exp_bool_or_arguments_columns" columns of table "buyer" */
export enum Buyer_Select_Column_Buyer_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** input type for updating data in table "buyer" */
export type Buyer_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  iugu_account_id?: InputMaybe<Scalars['String']['input']>;
  iugu_invoice_Id?: InputMaybe<Scalars['String']['input']>;
  plan_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "buyer" */
export type Buyer_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Buyer_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Buyer_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  iugu_account_id?: InputMaybe<Scalars['String']['input']>;
  iugu_invoice_Id?: InputMaybe<Scalars['String']['input']>;
  plan_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "buyer" */
export enum Buyer_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IuguAccountId = 'iugu_account_id',
  /** column name */
  IuguInvoiceId = 'iugu_invoice_Id',
  /** column name */
  PlanId = 'plan_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Buyer_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Buyer_Set_Input>;
  /** filter the rows which have to be updated */
  where: Buyer_Bool_Exp;
};

/** columns and relationships of "config_oed" */
export type Config_Oed = {
  __typename?: 'config_oed';
  active?: Maybe<Scalars['Boolean']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id: Scalars['uuid']['output'];
  /** An array relationship */
  oeds: Array<Oed>;
  /** An aggregate relationship */
  oeds_aggregate: Oed_Aggregate;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};


/** columns and relationships of "config_oed" */
export type Config_OedOedsArgs = {
  distinct_on?: InputMaybe<Array<Oed_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Oed_Order_By>>;
  where?: InputMaybe<Oed_Bool_Exp>;
};


/** columns and relationships of "config_oed" */
export type Config_OedOeds_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Oed_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Oed_Order_By>>;
  where?: InputMaybe<Oed_Bool_Exp>;
};

/** aggregated selection of "config_oed" */
export type Config_Oed_Aggregate = {
  __typename?: 'config_oed_aggregate';
  aggregate?: Maybe<Config_Oed_Aggregate_Fields>;
  nodes: Array<Config_Oed>;
};

/** aggregate fields of "config_oed" */
export type Config_Oed_Aggregate_Fields = {
  __typename?: 'config_oed_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Config_Oed_Max_Fields>;
  min?: Maybe<Config_Oed_Min_Fields>;
};


/** aggregate fields of "config_oed" */
export type Config_Oed_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Config_Oed_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "config_oed". All fields are combined with a logical 'AND'. */
export type Config_Oed_Bool_Exp = {
  _and?: InputMaybe<Array<Config_Oed_Bool_Exp>>;
  _not?: InputMaybe<Config_Oed_Bool_Exp>;
  _or?: InputMaybe<Array<Config_Oed_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  oeds?: InputMaybe<Oed_Bool_Exp>;
  oeds_aggregate?: InputMaybe<Oed_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "config_oed" */
export enum Config_Oed_Constraint {
  /** unique or primary key constraint on columns "id" */
  ConfigOedPkey = 'config_oed_pkey'
}

/** input type for inserting data into table "config_oed" */
export type Config_Oed_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  oeds?: InputMaybe<Oed_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type Config_Oed_Max_Fields = {
  __typename?: 'config_oed_max_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregate min on columns */
export type Config_Oed_Min_Fields = {
  __typename?: 'config_oed_min_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** response of any mutation on the table "config_oed" */
export type Config_Oed_Mutation_Response = {
  __typename?: 'config_oed_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Config_Oed>;
};

/** input type for inserting object relation for remote table "config_oed" */
export type Config_Oed_Obj_Rel_Insert_Input = {
  data: Config_Oed_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Config_Oed_On_Conflict>;
};

/** on_conflict condition type for table "config_oed" */
export type Config_Oed_On_Conflict = {
  constraint: Config_Oed_Constraint;
  update_columns?: Array<Config_Oed_Update_Column>;
  where?: InputMaybe<Config_Oed_Bool_Exp>;
};

/** Ordering options when selecting data from "config_oed". */
export type Config_Oed_Order_By = {
  active?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  oeds_aggregate?: InputMaybe<Oed_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: config_oed */
export type Config_Oed_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "config_oed" */
export enum Config_Oed_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "config_oed" */
export type Config_Oed_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** Streaming cursor of the table "config_oed" */
export type Config_Oed_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Config_Oed_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Config_Oed_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** update columns of table "config_oed" */
export enum Config_Oed_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Config_Oed_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Config_Oed_Set_Input>;
  /** filter the rows which have to be updated */
  where: Config_Oed_Bool_Exp;
};

/** columns and relationships of "config_plan" */
export type Config_Plan = {
  __typename?: 'config_plan';
  active?: Maybe<Scalars['Boolean']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id: Scalars['uuid']['output'];
  /** An array relationship */
  plans: Array<Plan>;
  /** An aggregate relationship */
  plans_aggregate: Plan_Aggregate;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};


/** columns and relationships of "config_plan" */
export type Config_PlanPlansArgs = {
  distinct_on?: InputMaybe<Array<Plan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Plan_Order_By>>;
  where?: InputMaybe<Plan_Bool_Exp>;
};


/** columns and relationships of "config_plan" */
export type Config_PlanPlans_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Plan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Plan_Order_By>>;
  where?: InputMaybe<Plan_Bool_Exp>;
};

/** aggregated selection of "config_plan" */
export type Config_Plan_Aggregate = {
  __typename?: 'config_plan_aggregate';
  aggregate?: Maybe<Config_Plan_Aggregate_Fields>;
  nodes: Array<Config_Plan>;
};

/** aggregate fields of "config_plan" */
export type Config_Plan_Aggregate_Fields = {
  __typename?: 'config_plan_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Config_Plan_Max_Fields>;
  min?: Maybe<Config_Plan_Min_Fields>;
};


/** aggregate fields of "config_plan" */
export type Config_Plan_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Config_Plan_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "config_plan". All fields are combined with a logical 'AND'. */
export type Config_Plan_Bool_Exp = {
  _and?: InputMaybe<Array<Config_Plan_Bool_Exp>>;
  _not?: InputMaybe<Config_Plan_Bool_Exp>;
  _or?: InputMaybe<Array<Config_Plan_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  plans?: InputMaybe<Plan_Bool_Exp>;
  plans_aggregate?: InputMaybe<Plan_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "config_plan" */
export enum Config_Plan_Constraint {
  /** unique or primary key constraint on columns "id" */
  ConfigPlanPkey = 'config_plan_pkey'
}

/** input type for inserting data into table "config_plan" */
export type Config_Plan_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  plans?: InputMaybe<Plan_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type Config_Plan_Max_Fields = {
  __typename?: 'config_plan_max_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregate min on columns */
export type Config_Plan_Min_Fields = {
  __typename?: 'config_plan_min_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** response of any mutation on the table "config_plan" */
export type Config_Plan_Mutation_Response = {
  __typename?: 'config_plan_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Config_Plan>;
};

/** input type for inserting object relation for remote table "config_plan" */
export type Config_Plan_Obj_Rel_Insert_Input = {
  data: Config_Plan_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Config_Plan_On_Conflict>;
};

/** on_conflict condition type for table "config_plan" */
export type Config_Plan_On_Conflict = {
  constraint: Config_Plan_Constraint;
  update_columns?: Array<Config_Plan_Update_Column>;
  where?: InputMaybe<Config_Plan_Bool_Exp>;
};

/** Ordering options when selecting data from "config_plan". */
export type Config_Plan_Order_By = {
  active?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  plans_aggregate?: InputMaybe<Plan_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: config_plan */
export type Config_Plan_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "config_plan" */
export enum Config_Plan_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "config_plan" */
export type Config_Plan_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** Streaming cursor of the table "config_plan" */
export type Config_Plan_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Config_Plan_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Config_Plan_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** update columns of table "config_plan" */
export enum Config_Plan_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Config_Plan_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Config_Plan_Set_Input>;
  /** filter the rows which have to be updated */
  where: Config_Plan_Bool_Exp;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** columns and relationships of "event" */
export type Event = {
  __typename?: 'event';
  active?: Maybe<Scalars['Boolean']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  event_type?: Maybe<Status_Oed_Enum>;
  id: Scalars['uuid']['output'];
  oed_id?: Maybe<Scalars['uuid']['output']>;
  oed_type?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  status_oed?: Maybe<Status_Oed>;
  strapi_oed_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  team?: Maybe<Team>;
  team_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  /** An object relationship */
  user?: Maybe<User>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** aggregated selection of "event" */
export type Event_Aggregate = {
  __typename?: 'event_aggregate';
  aggregate?: Maybe<Event_Aggregate_Fields>;
  nodes: Array<Event>;
};

export type Event_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Event_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Event_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Event_Aggregate_Bool_Exp_Count>;
};

export type Event_Aggregate_Bool_Exp_Bool_And = {
  arguments: Event_Select_Column_Event_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Event_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Event_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Event_Select_Column_Event_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Event_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Event_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Event_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Event_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "event" */
export type Event_Aggregate_Fields = {
  __typename?: 'event_aggregate_fields';
  avg?: Maybe<Event_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Event_Max_Fields>;
  min?: Maybe<Event_Min_Fields>;
  stddev?: Maybe<Event_Stddev_Fields>;
  stddev_pop?: Maybe<Event_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Event_Stddev_Samp_Fields>;
  sum?: Maybe<Event_Sum_Fields>;
  var_pop?: Maybe<Event_Var_Pop_Fields>;
  var_samp?: Maybe<Event_Var_Samp_Fields>;
  variance?: Maybe<Event_Variance_Fields>;
};


/** aggregate fields of "event" */
export type Event_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Event_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "event" */
export type Event_Aggregate_Order_By = {
  avg?: InputMaybe<Event_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Event_Max_Order_By>;
  min?: InputMaybe<Event_Min_Order_By>;
  stddev?: InputMaybe<Event_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Event_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Event_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Event_Sum_Order_By>;
  var_pop?: InputMaybe<Event_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Event_Var_Samp_Order_By>;
  variance?: InputMaybe<Event_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "event" */
export type Event_Arr_Rel_Insert_Input = {
  data: Array<Event_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Event_On_Conflict>;
};

/** aggregate avg on columns */
export type Event_Avg_Fields = {
  __typename?: 'event_avg_fields';
  strapi_oed_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "event" */
export type Event_Avg_Order_By = {
  strapi_oed_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "event". All fields are combined with a logical 'AND'. */
export type Event_Bool_Exp = {
  _and?: InputMaybe<Array<Event_Bool_Exp>>;
  _not?: InputMaybe<Event_Bool_Exp>;
  _or?: InputMaybe<Array<Event_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  event_type?: InputMaybe<Status_Oed_Enum_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  oed_id?: InputMaybe<Uuid_Comparison_Exp>;
  oed_type?: InputMaybe<String_Comparison_Exp>;
  status_oed?: InputMaybe<Status_Oed_Bool_Exp>;
  strapi_oed_id?: InputMaybe<Int_Comparison_Exp>;
  team?: InputMaybe<Team_Bool_Exp>;
  team_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "event" */
export enum Event_Constraint {
  /** unique or primary key constraint on columns "id" */
  EventsPkey = 'events_pkey'
}

/** input type for incrementing numeric columns in table "event" */
export type Event_Inc_Input = {
  strapi_oed_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "event" */
export type Event_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  event_type?: InputMaybe<Status_Oed_Enum>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  oed_id?: InputMaybe<Scalars['uuid']['input']>;
  oed_type?: InputMaybe<Scalars['String']['input']>;
  status_oed?: InputMaybe<Status_Oed_Obj_Rel_Insert_Input>;
  strapi_oed_id?: InputMaybe<Scalars['Int']['input']>;
  team?: InputMaybe<Team_Obj_Rel_Insert_Input>;
  team_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Event_Max_Fields = {
  __typename?: 'event_max_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  oed_id?: Maybe<Scalars['uuid']['output']>;
  oed_type?: Maybe<Scalars['String']['output']>;
  strapi_oed_id?: Maybe<Scalars['Int']['output']>;
  team_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "event" */
export type Event_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  oed_id?: InputMaybe<Order_By>;
  oed_type?: InputMaybe<Order_By>;
  strapi_oed_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Event_Min_Fields = {
  __typename?: 'event_min_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  oed_id?: Maybe<Scalars['uuid']['output']>;
  oed_type?: Maybe<Scalars['String']['output']>;
  strapi_oed_id?: Maybe<Scalars['Int']['output']>;
  team_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "event" */
export type Event_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  oed_id?: InputMaybe<Order_By>;
  oed_type?: InputMaybe<Order_By>;
  strapi_oed_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "event" */
export type Event_Mutation_Response = {
  __typename?: 'event_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Event>;
};

/** input type for inserting object relation for remote table "event" */
export type Event_Obj_Rel_Insert_Input = {
  data: Event_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Event_On_Conflict>;
};

/** on_conflict condition type for table "event" */
export type Event_On_Conflict = {
  constraint: Event_Constraint;
  update_columns?: Array<Event_Update_Column>;
  where?: InputMaybe<Event_Bool_Exp>;
};

/** Ordering options when selecting data from "event". */
export type Event_Order_By = {
  active?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  event_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  oed_id?: InputMaybe<Order_By>;
  oed_type?: InputMaybe<Order_By>;
  status_oed?: InputMaybe<Status_Oed_Order_By>;
  strapi_oed_id?: InputMaybe<Order_By>;
  team?: InputMaybe<Team_Order_By>;
  team_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: event */
export type Event_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "event" */
export enum Event_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EventType = 'event_type',
  /** column name */
  Id = 'id',
  /** column name */
  OedId = 'oed_id',
  /** column name */
  OedType = 'oed_type',
  /** column name */
  StrapiOedId = 'strapi_oed_id',
  /** column name */
  TeamId = 'team_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** select "event_aggregate_bool_exp_bool_and_arguments_columns" columns of table "event" */
export enum Event_Select_Column_Event_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** select "event_aggregate_bool_exp_bool_or_arguments_columns" columns of table "event" */
export enum Event_Select_Column_Event_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** input type for updating data in table "event" */
export type Event_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  event_type?: InputMaybe<Status_Oed_Enum>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  oed_id?: InputMaybe<Scalars['uuid']['input']>;
  oed_type?: InputMaybe<Scalars['String']['input']>;
  strapi_oed_id?: InputMaybe<Scalars['Int']['input']>;
  team_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate stddev on columns */
export type Event_Stddev_Fields = {
  __typename?: 'event_stddev_fields';
  strapi_oed_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "event" */
export type Event_Stddev_Order_By = {
  strapi_oed_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Event_Stddev_Pop_Fields = {
  __typename?: 'event_stddev_pop_fields';
  strapi_oed_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "event" */
export type Event_Stddev_Pop_Order_By = {
  strapi_oed_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Event_Stddev_Samp_Fields = {
  __typename?: 'event_stddev_samp_fields';
  strapi_oed_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "event" */
export type Event_Stddev_Samp_Order_By = {
  strapi_oed_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "event" */
export type Event_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Event_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Event_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  event_type?: InputMaybe<Status_Oed_Enum>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  oed_id?: InputMaybe<Scalars['uuid']['input']>;
  oed_type?: InputMaybe<Scalars['String']['input']>;
  strapi_oed_id?: InputMaybe<Scalars['Int']['input']>;
  team_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate sum on columns */
export type Event_Sum_Fields = {
  __typename?: 'event_sum_fields';
  strapi_oed_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "event" */
export type Event_Sum_Order_By = {
  strapi_oed_id?: InputMaybe<Order_By>;
};

/** update columns of table "event" */
export enum Event_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EventType = 'event_type',
  /** column name */
  Id = 'id',
  /** column name */
  OedId = 'oed_id',
  /** column name */
  OedType = 'oed_type',
  /** column name */
  StrapiOedId = 'strapi_oed_id',
  /** column name */
  TeamId = 'team_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Event_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Event_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Event_Set_Input>;
  /** filter the rows which have to be updated */
  where: Event_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Event_Var_Pop_Fields = {
  __typename?: 'event_var_pop_fields';
  strapi_oed_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "event" */
export type Event_Var_Pop_Order_By = {
  strapi_oed_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Event_Var_Samp_Fields = {
  __typename?: 'event_var_samp_fields';
  strapi_oed_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "event" */
export type Event_Var_Samp_Order_By = {
  strapi_oed_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Event_Variance_Fields = {
  __typename?: 'event_variance_fields';
  strapi_oed_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "event" */
export type Event_Variance_Order_By = {
  strapi_oed_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "exclusive_oed" */
export type Exclusive_Oed = {
  __typename?: 'exclusive_oed';
  active?: Maybe<Scalars['Boolean']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id: Scalars['uuid']['output'];
  metadata?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  role_oed_exclusives: Array<Role_Oed_Exclusive>;
  /** An aggregate relationship */
  role_oed_exclusives_aggregate: Role_Oed_Exclusive_Aggregate;
  strapi_id?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};


/** columns and relationships of "exclusive_oed" */
export type Exclusive_OedRole_Oed_ExclusivesArgs = {
  distinct_on?: InputMaybe<Array<Role_Oed_Exclusive_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Role_Oed_Exclusive_Order_By>>;
  where?: InputMaybe<Role_Oed_Exclusive_Bool_Exp>;
};


/** columns and relationships of "exclusive_oed" */
export type Exclusive_OedRole_Oed_Exclusives_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Role_Oed_Exclusive_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Role_Oed_Exclusive_Order_By>>;
  where?: InputMaybe<Role_Oed_Exclusive_Bool_Exp>;
};

/** aggregated selection of "exclusive_oed" */
export type Exclusive_Oed_Aggregate = {
  __typename?: 'exclusive_oed_aggregate';
  aggregate?: Maybe<Exclusive_Oed_Aggregate_Fields>;
  nodes: Array<Exclusive_Oed>;
};

/** aggregate fields of "exclusive_oed" */
export type Exclusive_Oed_Aggregate_Fields = {
  __typename?: 'exclusive_oed_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Exclusive_Oed_Max_Fields>;
  min?: Maybe<Exclusive_Oed_Min_Fields>;
};


/** aggregate fields of "exclusive_oed" */
export type Exclusive_Oed_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Exclusive_Oed_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "exclusive_oed". All fields are combined with a logical 'AND'. */
export type Exclusive_Oed_Bool_Exp = {
  _and?: InputMaybe<Array<Exclusive_Oed_Bool_Exp>>;
  _not?: InputMaybe<Exclusive_Oed_Bool_Exp>;
  _or?: InputMaybe<Array<Exclusive_Oed_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  metadata?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  role_oed_exclusives?: InputMaybe<Role_Oed_Exclusive_Bool_Exp>;
  role_oed_exclusives_aggregate?: InputMaybe<Role_Oed_Exclusive_Aggregate_Bool_Exp>;
  strapi_id?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "exclusive_oed" */
export enum Exclusive_Oed_Constraint {
  /** unique or primary key constraint on columns "id" */
  ExclusiveOedsIdIdx = 'exclusive_oeds_id_idx',
  /** unique or primary key constraint on columns "id" */
  ExclusiveOedsUnique = 'exclusive_oeds_unique'
}

/** input type for inserting data into table "exclusive_oed" */
export type Exclusive_Oed_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  metadata?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  role_oed_exclusives?: InputMaybe<Role_Oed_Exclusive_Arr_Rel_Insert_Input>;
  strapi_id?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type Exclusive_Oed_Max_Fields = {
  __typename?: 'exclusive_oed_max_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  metadata?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  strapi_id?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregate min on columns */
export type Exclusive_Oed_Min_Fields = {
  __typename?: 'exclusive_oed_min_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  metadata?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  strapi_id?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** response of any mutation on the table "exclusive_oed" */
export type Exclusive_Oed_Mutation_Response = {
  __typename?: 'exclusive_oed_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Exclusive_Oed>;
};

/** input type for inserting object relation for remote table "exclusive_oed" */
export type Exclusive_Oed_Obj_Rel_Insert_Input = {
  data: Exclusive_Oed_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Exclusive_Oed_On_Conflict>;
};

/** on_conflict condition type for table "exclusive_oed" */
export type Exclusive_Oed_On_Conflict = {
  constraint: Exclusive_Oed_Constraint;
  update_columns?: Array<Exclusive_Oed_Update_Column>;
  where?: InputMaybe<Exclusive_Oed_Bool_Exp>;
};

/** Ordering options when selecting data from "exclusive_oed". */
export type Exclusive_Oed_Order_By = {
  active?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  role_oed_exclusives_aggregate?: InputMaybe<Role_Oed_Exclusive_Aggregate_Order_By>;
  strapi_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** select columns of table "exclusive_oed" */
export enum Exclusive_Oed_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Name = 'name',
  /** column name */
  StrapiId = 'strapi_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "exclusive_oed" */
export type Exclusive_Oed_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  metadata?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  strapi_id?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** Streaming cursor of the table "exclusive_oed" */
export type Exclusive_Oed_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Exclusive_Oed_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Exclusive_Oed_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  metadata?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  strapi_id?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** update columns of table "exclusive_oed" */
export enum Exclusive_Oed_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Name = 'name',
  /** column name */
  StrapiId = 'strapi_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Exclusive_Oed_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Exclusive_Oed_Set_Input>;
  /** filter the rows which have to be updated */
  where: Exclusive_Oed_Bool_Exp;
};

export type Jsonb_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: InputMaybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']['input']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']['input']>;
  _eq?: InputMaybe<Scalars['jsonb']['input']>;
  _gt?: InputMaybe<Scalars['jsonb']['input']>;
  _gte?: InputMaybe<Scalars['jsonb']['input']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']['input']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']['input']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']['input']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['jsonb']['input']>;
  _lte?: InputMaybe<Scalars['jsonb']['input']>;
  _neq?: InputMaybe<Scalars['jsonb']['input']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']['input']>>;
};

/** columns and relationships of "library" */
export type Library = {
  __typename?: 'library';
  active?: Maybe<Scalars['Boolean']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id: Scalars['uuid']['output'];
  /** An object relationship */
  oed: Oed;
  oed_id: Scalars['uuid']['output'];
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  /** An object relationship */
  user: User;
  user_id: Scalars['uuid']['output'];
};

/** aggregated selection of "library" */
export type Library_Aggregate = {
  __typename?: 'library_aggregate';
  aggregate?: Maybe<Library_Aggregate_Fields>;
  nodes: Array<Library>;
};

export type Library_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Library_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Library_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Library_Aggregate_Bool_Exp_Count>;
};

export type Library_Aggregate_Bool_Exp_Bool_And = {
  arguments: Library_Select_Column_Library_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Library_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Library_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Library_Select_Column_Library_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Library_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Library_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Library_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Library_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "library" */
export type Library_Aggregate_Fields = {
  __typename?: 'library_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Library_Max_Fields>;
  min?: Maybe<Library_Min_Fields>;
};


/** aggregate fields of "library" */
export type Library_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Library_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "library" */
export type Library_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Library_Max_Order_By>;
  min?: InputMaybe<Library_Min_Order_By>;
};

/** input type for inserting array relation for remote table "library" */
export type Library_Arr_Rel_Insert_Input = {
  data: Array<Library_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Library_On_Conflict>;
};

/** Boolean expression to filter rows from the table "library". All fields are combined with a logical 'AND'. */
export type Library_Bool_Exp = {
  _and?: InputMaybe<Array<Library_Bool_Exp>>;
  _not?: InputMaybe<Library_Bool_Exp>;
  _or?: InputMaybe<Array<Library_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  oed?: InputMaybe<Oed_Bool_Exp>;
  oed_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "library" */
export enum Library_Constraint {
  /** unique or primary key constraint on columns "id" */
  LibraryPk = 'library_pk'
}

/** input type for inserting data into table "library" */
export type Library_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  oed?: InputMaybe<Oed_Obj_Rel_Insert_Input>;
  oed_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Library_Max_Fields = {
  __typename?: 'library_max_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  oed_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "library" */
export type Library_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  oed_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Library_Min_Fields = {
  __typename?: 'library_min_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  oed_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "library" */
export type Library_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  oed_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "library" */
export type Library_Mutation_Response = {
  __typename?: 'library_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Library>;
};

/** on_conflict condition type for table "library" */
export type Library_On_Conflict = {
  constraint: Library_Constraint;
  update_columns?: Array<Library_Update_Column>;
  where?: InputMaybe<Library_Bool_Exp>;
};

/** Ordering options when selecting data from "library". */
export type Library_Order_By = {
  active?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  oed?: InputMaybe<Oed_Order_By>;
  oed_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: library */
export type Library_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "library" */
export enum Library_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  OedId = 'oed_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** select "library_aggregate_bool_exp_bool_and_arguments_columns" columns of table "library" */
export enum Library_Select_Column_Library_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** select "library_aggregate_bool_exp_bool_or_arguments_columns" columns of table "library" */
export enum Library_Select_Column_Library_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** input type for updating data in table "library" */
export type Library_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  oed_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "library" */
export type Library_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Library_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Library_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  oed_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "library" */
export enum Library_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  OedId = 'oed_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Library_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Library_Set_Input>;
  /** filter the rows which have to be updated */
  where: Library_Bool_Exp;
};

/** columns and relationships of "login" */
export type Login = {
  __typename?: 'login';
  active?: Maybe<Scalars['Boolean']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['uuid']['output'];
  strapi_user_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  /** An array relationship */
  users: Array<User>;
  /** An aggregate relationship */
  users_aggregate: User_Aggregate;
};


/** columns and relationships of "login" */
export type LoginUsersArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


/** columns and relationships of "login" */
export type LoginUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};

/** aggregated selection of "login" */
export type Login_Aggregate = {
  __typename?: 'login_aggregate';
  aggregate?: Maybe<Login_Aggregate_Fields>;
  nodes: Array<Login>;
};

/** aggregate fields of "login" */
export type Login_Aggregate_Fields = {
  __typename?: 'login_aggregate_fields';
  avg?: Maybe<Login_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Login_Max_Fields>;
  min?: Maybe<Login_Min_Fields>;
  stddev?: Maybe<Login_Stddev_Fields>;
  stddev_pop?: Maybe<Login_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Login_Stddev_Samp_Fields>;
  sum?: Maybe<Login_Sum_Fields>;
  var_pop?: Maybe<Login_Var_Pop_Fields>;
  var_samp?: Maybe<Login_Var_Samp_Fields>;
  variance?: Maybe<Login_Variance_Fields>;
};


/** aggregate fields of "login" */
export type Login_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Login_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Login_Avg_Fields = {
  __typename?: 'login_avg_fields';
  strapi_user_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "login". All fields are combined with a logical 'AND'. */
export type Login_Bool_Exp = {
  _and?: InputMaybe<Array<Login_Bool_Exp>>;
  _not?: InputMaybe<Login_Bool_Exp>;
  _or?: InputMaybe<Array<Login_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  strapi_user_id?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  users?: InputMaybe<User_Bool_Exp>;
  users_aggregate?: InputMaybe<User_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "login" */
export enum Login_Constraint {
  /** unique or primary key constraint on columns "id" */
  LoginPkey = 'login_pkey'
}

/** input type for incrementing numeric columns in table "login" */
export type Login_Inc_Input = {
  strapi_user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "login" */
export type Login_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  strapi_user_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  users?: InputMaybe<User_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Login_Max_Fields = {
  __typename?: 'login_max_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  strapi_user_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregate min on columns */
export type Login_Min_Fields = {
  __typename?: 'login_min_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  strapi_user_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** response of any mutation on the table "login" */
export type Login_Mutation_Response = {
  __typename?: 'login_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Login>;
};

/** input type for inserting object relation for remote table "login" */
export type Login_Obj_Rel_Insert_Input = {
  data: Login_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Login_On_Conflict>;
};

/** on_conflict condition type for table "login" */
export type Login_On_Conflict = {
  constraint: Login_Constraint;
  update_columns?: Array<Login_Update_Column>;
  where?: InputMaybe<Login_Bool_Exp>;
};

/** Ordering options when selecting data from "login". */
export type Login_Order_By = {
  active?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  strapi_user_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  users_aggregate?: InputMaybe<User_Aggregate_Order_By>;
};

/** primary key columns input for table: login */
export type Login_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "login" */
export enum Login_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  StrapiUserId = 'strapi_user_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "login" */
export type Login_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  strapi_user_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate stddev on columns */
export type Login_Stddev_Fields = {
  __typename?: 'login_stddev_fields';
  strapi_user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Login_Stddev_Pop_Fields = {
  __typename?: 'login_stddev_pop_fields';
  strapi_user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Login_Stddev_Samp_Fields = {
  __typename?: 'login_stddev_samp_fields';
  strapi_user_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "login" */
export type Login_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Login_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Login_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  strapi_user_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate sum on columns */
export type Login_Sum_Fields = {
  __typename?: 'login_sum_fields';
  strapi_user_id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "login" */
export enum Login_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  StrapiUserId = 'strapi_user_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Login_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Login_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Login_Set_Input>;
  /** filter the rows which have to be updated */
  where: Login_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Login_Var_Pop_Fields = {
  __typename?: 'login_var_pop_fields';
  strapi_user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Login_Var_Samp_Fields = {
  __typename?: 'login_var_samp_fields';
  strapi_user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Login_Variance_Fields = {
  __typename?: 'login_variance_fields';
  strapi_user_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "manager_teams" */
export type Manager_Teams = {
  __typename?: 'manager_teams';
  active?: Maybe<Scalars['Boolean']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id: Scalars['uuid']['output'];
  /** An object relationship */
  team?: Maybe<Team>;
  team_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  /** An object relationship */
  user?: Maybe<User>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** aggregated selection of "manager_teams" */
export type Manager_Teams_Aggregate = {
  __typename?: 'manager_teams_aggregate';
  aggregate?: Maybe<Manager_Teams_Aggregate_Fields>;
  nodes: Array<Manager_Teams>;
};

export type Manager_Teams_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Manager_Teams_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Manager_Teams_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Manager_Teams_Aggregate_Bool_Exp_Count>;
};

export type Manager_Teams_Aggregate_Bool_Exp_Bool_And = {
  arguments: Manager_Teams_Select_Column_Manager_Teams_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Manager_Teams_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Manager_Teams_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Manager_Teams_Select_Column_Manager_Teams_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Manager_Teams_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Manager_Teams_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Manager_Teams_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Manager_Teams_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "manager_teams" */
export type Manager_Teams_Aggregate_Fields = {
  __typename?: 'manager_teams_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Manager_Teams_Max_Fields>;
  min?: Maybe<Manager_Teams_Min_Fields>;
};


/** aggregate fields of "manager_teams" */
export type Manager_Teams_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Manager_Teams_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "manager_teams" */
export type Manager_Teams_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Manager_Teams_Max_Order_By>;
  min?: InputMaybe<Manager_Teams_Min_Order_By>;
};

/** input type for inserting array relation for remote table "manager_teams" */
export type Manager_Teams_Arr_Rel_Insert_Input = {
  data: Array<Manager_Teams_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Manager_Teams_On_Conflict>;
};

/** Boolean expression to filter rows from the table "manager_teams". All fields are combined with a logical 'AND'. */
export type Manager_Teams_Bool_Exp = {
  _and?: InputMaybe<Array<Manager_Teams_Bool_Exp>>;
  _not?: InputMaybe<Manager_Teams_Bool_Exp>;
  _or?: InputMaybe<Array<Manager_Teams_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  team?: InputMaybe<Team_Bool_Exp>;
  team_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "manager_teams" */
export enum Manager_Teams_Constraint {
  /** unique or primary key constraint on columns "id" */
  ManagerUserTeamsPk = 'manager_user_teams_pk'
}

/** input type for inserting data into table "manager_teams" */
export type Manager_Teams_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  team?: InputMaybe<Team_Obj_Rel_Insert_Input>;
  team_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Manager_Teams_Max_Fields = {
  __typename?: 'manager_teams_max_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  team_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "manager_teams" */
export type Manager_Teams_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Manager_Teams_Min_Fields = {
  __typename?: 'manager_teams_min_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  team_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "manager_teams" */
export type Manager_Teams_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "manager_teams" */
export type Manager_Teams_Mutation_Response = {
  __typename?: 'manager_teams_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Manager_Teams>;
};

/** on_conflict condition type for table "manager_teams" */
export type Manager_Teams_On_Conflict = {
  constraint: Manager_Teams_Constraint;
  update_columns?: Array<Manager_Teams_Update_Column>;
  where?: InputMaybe<Manager_Teams_Bool_Exp>;
};

/** Ordering options when selecting data from "manager_teams". */
export type Manager_Teams_Order_By = {
  active?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  team?: InputMaybe<Team_Order_By>;
  team_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: manager_teams */
export type Manager_Teams_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "manager_teams" */
export enum Manager_Teams_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  TeamId = 'team_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** select "manager_teams_aggregate_bool_exp_bool_and_arguments_columns" columns of table "manager_teams" */
export enum Manager_Teams_Select_Column_Manager_Teams_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** select "manager_teams_aggregate_bool_exp_bool_or_arguments_columns" columns of table "manager_teams" */
export enum Manager_Teams_Select_Column_Manager_Teams_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** input type for updating data in table "manager_teams" */
export type Manager_Teams_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  team_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "manager_teams" */
export type Manager_Teams_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Manager_Teams_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Manager_Teams_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  team_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "manager_teams" */
export enum Manager_Teams_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  TeamId = 'team_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Manager_Teams_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Manager_Teams_Set_Input>;
  /** filter the rows which have to be updated */
  where: Manager_Teams_Bool_Exp;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** delete data from the table: "address" */
  delete_address?: Maybe<Address_Mutation_Response>;
  /** delete single row from the table: "address" */
  delete_address_by_pk?: Maybe<Address>;
  /** delete data from the table: "billing_address" */
  delete_billing_address?: Maybe<Billing_Address_Mutation_Response>;
  /** delete single row from the table: "billing_address" */
  delete_billing_address_by_pk?: Maybe<Billing_Address>;
  /** delete data from the table: "buyer" */
  delete_buyer?: Maybe<Buyer_Mutation_Response>;
  /** delete single row from the table: "buyer" */
  delete_buyer_by_pk?: Maybe<Buyer>;
  /** delete data from the table: "config_oed" */
  delete_config_oed?: Maybe<Config_Oed_Mutation_Response>;
  /** delete single row from the table: "config_oed" */
  delete_config_oed_by_pk?: Maybe<Config_Oed>;
  /** delete data from the table: "config_plan" */
  delete_config_plan?: Maybe<Config_Plan_Mutation_Response>;
  /** delete single row from the table: "config_plan" */
  delete_config_plan_by_pk?: Maybe<Config_Plan>;
  /** delete data from the table: "event" */
  delete_event?: Maybe<Event_Mutation_Response>;
  /** delete single row from the table: "event" */
  delete_event_by_pk?: Maybe<Event>;
  /** delete data from the table: "exclusive_oed" */
  delete_exclusive_oed?: Maybe<Exclusive_Oed_Mutation_Response>;
  /** delete data from the table: "library" */
  delete_library?: Maybe<Library_Mutation_Response>;
  /** delete single row from the table: "library" */
  delete_library_by_pk?: Maybe<Library>;
  /** delete data from the table: "login" */
  delete_login?: Maybe<Login_Mutation_Response>;
  /** delete single row from the table: "login" */
  delete_login_by_pk?: Maybe<Login>;
  /** delete data from the table: "manager_teams" */
  delete_manager_teams?: Maybe<Manager_Teams_Mutation_Response>;
  /** delete single row from the table: "manager_teams" */
  delete_manager_teams_by_pk?: Maybe<Manager_Teams>;
  /** delete data from the table: "oed" */
  delete_oed?: Maybe<Oed_Mutation_Response>;
  /** delete single row from the table: "oed" */
  delete_oed_by_pk?: Maybe<Oed>;
  /** delete data from the table: "oed_template" */
  delete_oed_template?: Maybe<Oed_Template_Mutation_Response>;
  /** delete data from the table: "plan" */
  delete_plan?: Maybe<Plan_Mutation_Response>;
  /** delete single row from the table: "plan" */
  delete_plan_by_pk?: Maybe<Plan>;
  /** delete data from the table: "plan_oed" */
  delete_plan_oed?: Maybe<Plan_Oed_Mutation_Response>;
  /** delete single row from the table: "plan_oed" */
  delete_plan_oed_by_pk?: Maybe<Plan_Oed>;
  /** delete data from the table: "report" */
  delete_report?: Maybe<Report_Mutation_Response>;
  /** delete single row from the table: "report" */
  delete_report_by_pk?: Maybe<Report>;
  /** delete data from the table: "role" */
  delete_role?: Maybe<Role_Mutation_Response>;
  /** delete single row from the table: "role" */
  delete_role_by_pk?: Maybe<Role>;
  /** delete data from the table: "role_default_metadata" */
  delete_role_default_metadata?: Maybe<Role_Default_Metadata_Mutation_Response>;
  /** delete single row from the table: "role_default_metadata" */
  delete_role_default_metadata_by_pk?: Maybe<Role_Default_Metadata>;
  /** delete data from the table: "role_oed_exclusive" */
  delete_role_oed_exclusive?: Maybe<Role_Oed_Exclusive_Mutation_Response>;
  /** delete data from the table: "status_oed" */
  delete_status_oed?: Maybe<Status_Oed_Mutation_Response>;
  /** delete single row from the table: "status_oed" */
  delete_status_oed_by_pk?: Maybe<Status_Oed>;
  /** delete data from the table: "status_oed_thumbnail" */
  delete_status_oed_thumbnail?: Maybe<Status_Oed_Thumbnail_Mutation_Response>;
  /** delete single row from the table: "status_oed_thumbnail" */
  delete_status_oed_thumbnail_by_pk?: Maybe<Status_Oed_Thumbnail>;
  /** delete data from the table: "storage" */
  delete_storage?: Maybe<Storage_Mutation_Response>;
  /** delete single row from the table: "storage" */
  delete_storage_by_pk?: Maybe<Storage>;
  /** delete data from the table: "storage_oed" */
  delete_storage_oed?: Maybe<Storage_Oed_Mutation_Response>;
  /** delete single row from the table: "storage_oed" */
  delete_storage_oed_by_pk?: Maybe<Storage_Oed>;
  /** delete data from the table: "team" */
  delete_team?: Maybe<Team_Mutation_Response>;
  /** delete single row from the table: "team" */
  delete_team_by_pk?: Maybe<Team>;
  /** delete data from the table: "temp_user_oed" */
  delete_temp_user_oed?: Maybe<Temp_User_Oed_Mutation_Response>;
  /** delete data from the table: "user" */
  delete_user?: Maybe<User_Mutation_Response>;
  /** delete single row from the table: "user" */
  delete_user_by_pk?: Maybe<User>;
  /** delete data from the table: "user_role" */
  delete_user_role?: Maybe<User_Role_Mutation_Response>;
  /** delete single row from the table: "user_role" */
  delete_user_role_by_pk?: Maybe<User_Role>;
  /** delete data from the table: "user_wallet" */
  delete_user_wallet?: Maybe<User_Wallet_Mutation_Response>;
  /** delete single row from the table: "user_wallet" */
  delete_user_wallet_by_pk?: Maybe<User_Wallet>;
  /** delete data from the table: "users_teams" */
  delete_users_teams?: Maybe<Users_Teams_Mutation_Response>;
  /** delete single row from the table: "users_teams" */
  delete_users_teams_by_pk?: Maybe<Users_Teams>;
  /** insert data into the table: "address" */
  insert_address?: Maybe<Address_Mutation_Response>;
  /** insert a single row into the table: "address" */
  insert_address_one?: Maybe<Address>;
  /** insert data into the table: "billing_address" */
  insert_billing_address?: Maybe<Billing_Address_Mutation_Response>;
  /** insert a single row into the table: "billing_address" */
  insert_billing_address_one?: Maybe<Billing_Address>;
  /** insert data into the table: "buyer" */
  insert_buyer?: Maybe<Buyer_Mutation_Response>;
  /** insert a single row into the table: "buyer" */
  insert_buyer_one?: Maybe<Buyer>;
  /** insert data into the table: "config_oed" */
  insert_config_oed?: Maybe<Config_Oed_Mutation_Response>;
  /** insert a single row into the table: "config_oed" */
  insert_config_oed_one?: Maybe<Config_Oed>;
  /** insert data into the table: "config_plan" */
  insert_config_plan?: Maybe<Config_Plan_Mutation_Response>;
  /** insert a single row into the table: "config_plan" */
  insert_config_plan_one?: Maybe<Config_Plan>;
  /** insert data into the table: "event" */
  insert_event?: Maybe<Event_Mutation_Response>;
  /** insert a single row into the table: "event" */
  insert_event_one?: Maybe<Event>;
  /** insert data into the table: "exclusive_oed" */
  insert_exclusive_oed?: Maybe<Exclusive_Oed_Mutation_Response>;
  /** insert a single row into the table: "exclusive_oed" */
  insert_exclusive_oed_one?: Maybe<Exclusive_Oed>;
  /** insert data into the table: "library" */
  insert_library?: Maybe<Library_Mutation_Response>;
  /** insert a single row into the table: "library" */
  insert_library_one?: Maybe<Library>;
  /** insert data into the table: "login" */
  insert_login?: Maybe<Login_Mutation_Response>;
  /** insert a single row into the table: "login" */
  insert_login_one?: Maybe<Login>;
  /** insert data into the table: "manager_teams" */
  insert_manager_teams?: Maybe<Manager_Teams_Mutation_Response>;
  /** insert a single row into the table: "manager_teams" */
  insert_manager_teams_one?: Maybe<Manager_Teams>;
  /** insert data into the table: "oed" */
  insert_oed?: Maybe<Oed_Mutation_Response>;
  /** insert a single row into the table: "oed" */
  insert_oed_one?: Maybe<Oed>;
  /** insert data into the table: "oed_template" */
  insert_oed_template?: Maybe<Oed_Template_Mutation_Response>;
  /** insert a single row into the table: "oed_template" */
  insert_oed_template_one?: Maybe<Oed_Template>;
  /** insert data into the table: "plan" */
  insert_plan?: Maybe<Plan_Mutation_Response>;
  /** insert data into the table: "plan_oed" */
  insert_plan_oed?: Maybe<Plan_Oed_Mutation_Response>;
  /** insert a single row into the table: "plan_oed" */
  insert_plan_oed_one?: Maybe<Plan_Oed>;
  /** insert a single row into the table: "plan" */
  insert_plan_one?: Maybe<Plan>;
  /** insert data into the table: "report" */
  insert_report?: Maybe<Report_Mutation_Response>;
  /** insert a single row into the table: "report" */
  insert_report_one?: Maybe<Report>;
  /** insert data into the table: "role" */
  insert_role?: Maybe<Role_Mutation_Response>;
  /** insert data into the table: "role_default_metadata" */
  insert_role_default_metadata?: Maybe<Role_Default_Metadata_Mutation_Response>;
  /** insert a single row into the table: "role_default_metadata" */
  insert_role_default_metadata_one?: Maybe<Role_Default_Metadata>;
  /** insert data into the table: "role_oed_exclusive" */
  insert_role_oed_exclusive?: Maybe<Role_Oed_Exclusive_Mutation_Response>;
  /** insert a single row into the table: "role_oed_exclusive" */
  insert_role_oed_exclusive_one?: Maybe<Role_Oed_Exclusive>;
  /** insert a single row into the table: "role" */
  insert_role_one?: Maybe<Role>;
  /** insert data into the table: "status_oed" */
  insert_status_oed?: Maybe<Status_Oed_Mutation_Response>;
  /** insert a single row into the table: "status_oed" */
  insert_status_oed_one?: Maybe<Status_Oed>;
  /** insert data into the table: "status_oed_thumbnail" */
  insert_status_oed_thumbnail?: Maybe<Status_Oed_Thumbnail_Mutation_Response>;
  /** insert a single row into the table: "status_oed_thumbnail" */
  insert_status_oed_thumbnail_one?: Maybe<Status_Oed_Thumbnail>;
  /** insert data into the table: "storage" */
  insert_storage?: Maybe<Storage_Mutation_Response>;
  /** insert data into the table: "storage_oed" */
  insert_storage_oed?: Maybe<Storage_Oed_Mutation_Response>;
  /** insert a single row into the table: "storage_oed" */
  insert_storage_oed_one?: Maybe<Storage_Oed>;
  /** insert a single row into the table: "storage" */
  insert_storage_one?: Maybe<Storage>;
  /** insert data into the table: "team" */
  insert_team?: Maybe<Team_Mutation_Response>;
  /** insert a single row into the table: "team" */
  insert_team_one?: Maybe<Team>;
  /** insert data into the table: "temp_user_oed" */
  insert_temp_user_oed?: Maybe<Temp_User_Oed_Mutation_Response>;
  /** insert a single row into the table: "temp_user_oed" */
  insert_temp_user_oed_one?: Maybe<Temp_User_Oed>;
  /** insert data into the table: "user" */
  insert_user?: Maybe<User_Mutation_Response>;
  /** insert a single row into the table: "user" */
  insert_user_one?: Maybe<User>;
  /** insert data into the table: "user_role" */
  insert_user_role?: Maybe<User_Role_Mutation_Response>;
  /** insert a single row into the table: "user_role" */
  insert_user_role_one?: Maybe<User_Role>;
  /** insert data into the table: "user_wallet" */
  insert_user_wallet?: Maybe<User_Wallet_Mutation_Response>;
  /** insert a single row into the table: "user_wallet" */
  insert_user_wallet_one?: Maybe<User_Wallet>;
  /** insert data into the table: "users_teams" */
  insert_users_teams?: Maybe<Users_Teams_Mutation_Response>;
  /** insert a single row into the table: "users_teams" */
  insert_users_teams_one?: Maybe<Users_Teams>;
  /** update data of the table: "address" */
  update_address?: Maybe<Address_Mutation_Response>;
  /** update single row of the table: "address" */
  update_address_by_pk?: Maybe<Address>;
  /** update multiples rows of table: "address" */
  update_address_many?: Maybe<Array<Maybe<Address_Mutation_Response>>>;
  /** update data of the table: "billing_address" */
  update_billing_address?: Maybe<Billing_Address_Mutation_Response>;
  /** update single row of the table: "billing_address" */
  update_billing_address_by_pk?: Maybe<Billing_Address>;
  /** update multiples rows of table: "billing_address" */
  update_billing_address_many?: Maybe<Array<Maybe<Billing_Address_Mutation_Response>>>;
  /** update data of the table: "buyer" */
  update_buyer?: Maybe<Buyer_Mutation_Response>;
  /** update single row of the table: "buyer" */
  update_buyer_by_pk?: Maybe<Buyer>;
  /** update multiples rows of table: "buyer" */
  update_buyer_many?: Maybe<Array<Maybe<Buyer_Mutation_Response>>>;
  /** update data of the table: "config_oed" */
  update_config_oed?: Maybe<Config_Oed_Mutation_Response>;
  /** update single row of the table: "config_oed" */
  update_config_oed_by_pk?: Maybe<Config_Oed>;
  /** update multiples rows of table: "config_oed" */
  update_config_oed_many?: Maybe<Array<Maybe<Config_Oed_Mutation_Response>>>;
  /** update data of the table: "config_plan" */
  update_config_plan?: Maybe<Config_Plan_Mutation_Response>;
  /** update single row of the table: "config_plan" */
  update_config_plan_by_pk?: Maybe<Config_Plan>;
  /** update multiples rows of table: "config_plan" */
  update_config_plan_many?: Maybe<Array<Maybe<Config_Plan_Mutation_Response>>>;
  /** update data of the table: "event" */
  update_event?: Maybe<Event_Mutation_Response>;
  /** update single row of the table: "event" */
  update_event_by_pk?: Maybe<Event>;
  /** update multiples rows of table: "event" */
  update_event_many?: Maybe<Array<Maybe<Event_Mutation_Response>>>;
  /** update data of the table: "exclusive_oed" */
  update_exclusive_oed?: Maybe<Exclusive_Oed_Mutation_Response>;
  /** update multiples rows of table: "exclusive_oed" */
  update_exclusive_oed_many?: Maybe<Array<Maybe<Exclusive_Oed_Mutation_Response>>>;
  /** update data of the table: "library" */
  update_library?: Maybe<Library_Mutation_Response>;
  /** update single row of the table: "library" */
  update_library_by_pk?: Maybe<Library>;
  /** update multiples rows of table: "library" */
  update_library_many?: Maybe<Array<Maybe<Library_Mutation_Response>>>;
  /** update data of the table: "login" */
  update_login?: Maybe<Login_Mutation_Response>;
  /** update single row of the table: "login" */
  update_login_by_pk?: Maybe<Login>;
  /** update multiples rows of table: "login" */
  update_login_many?: Maybe<Array<Maybe<Login_Mutation_Response>>>;
  /** update data of the table: "manager_teams" */
  update_manager_teams?: Maybe<Manager_Teams_Mutation_Response>;
  /** update single row of the table: "manager_teams" */
  update_manager_teams_by_pk?: Maybe<Manager_Teams>;
  /** update multiples rows of table: "manager_teams" */
  update_manager_teams_many?: Maybe<Array<Maybe<Manager_Teams_Mutation_Response>>>;
  /** update data of the table: "oed" */
  update_oed?: Maybe<Oed_Mutation_Response>;
  /** update single row of the table: "oed" */
  update_oed_by_pk?: Maybe<Oed>;
  /** update multiples rows of table: "oed" */
  update_oed_many?: Maybe<Array<Maybe<Oed_Mutation_Response>>>;
  /** update data of the table: "oed_template" */
  update_oed_template?: Maybe<Oed_Template_Mutation_Response>;
  /** update multiples rows of table: "oed_template" */
  update_oed_template_many?: Maybe<Array<Maybe<Oed_Template_Mutation_Response>>>;
  /** update data of the table: "plan" */
  update_plan?: Maybe<Plan_Mutation_Response>;
  /** update single row of the table: "plan" */
  update_plan_by_pk?: Maybe<Plan>;
  /** update multiples rows of table: "plan" */
  update_plan_many?: Maybe<Array<Maybe<Plan_Mutation_Response>>>;
  /** update data of the table: "plan_oed" */
  update_plan_oed?: Maybe<Plan_Oed_Mutation_Response>;
  /** update single row of the table: "plan_oed" */
  update_plan_oed_by_pk?: Maybe<Plan_Oed>;
  /** update multiples rows of table: "plan_oed" */
  update_plan_oed_many?: Maybe<Array<Maybe<Plan_Oed_Mutation_Response>>>;
  /** update data of the table: "report" */
  update_report?: Maybe<Report_Mutation_Response>;
  /** update single row of the table: "report" */
  update_report_by_pk?: Maybe<Report>;
  /** update multiples rows of table: "report" */
  update_report_many?: Maybe<Array<Maybe<Report_Mutation_Response>>>;
  /** update data of the table: "role" */
  update_role?: Maybe<Role_Mutation_Response>;
  /** update single row of the table: "role" */
  update_role_by_pk?: Maybe<Role>;
  /** update data of the table: "role_default_metadata" */
  update_role_default_metadata?: Maybe<Role_Default_Metadata_Mutation_Response>;
  /** update single row of the table: "role_default_metadata" */
  update_role_default_metadata_by_pk?: Maybe<Role_Default_Metadata>;
  /** update multiples rows of table: "role_default_metadata" */
  update_role_default_metadata_many?: Maybe<Array<Maybe<Role_Default_Metadata_Mutation_Response>>>;
  /** update multiples rows of table: "role" */
  update_role_many?: Maybe<Array<Maybe<Role_Mutation_Response>>>;
  /** update data of the table: "role_oed_exclusive" */
  update_role_oed_exclusive?: Maybe<Role_Oed_Exclusive_Mutation_Response>;
  /** update multiples rows of table: "role_oed_exclusive" */
  update_role_oed_exclusive_many?: Maybe<Array<Maybe<Role_Oed_Exclusive_Mutation_Response>>>;
  /** update data of the table: "status_oed" */
  update_status_oed?: Maybe<Status_Oed_Mutation_Response>;
  /** update single row of the table: "status_oed" */
  update_status_oed_by_pk?: Maybe<Status_Oed>;
  /** update multiples rows of table: "status_oed" */
  update_status_oed_many?: Maybe<Array<Maybe<Status_Oed_Mutation_Response>>>;
  /** update data of the table: "status_oed_thumbnail" */
  update_status_oed_thumbnail?: Maybe<Status_Oed_Thumbnail_Mutation_Response>;
  /** update single row of the table: "status_oed_thumbnail" */
  update_status_oed_thumbnail_by_pk?: Maybe<Status_Oed_Thumbnail>;
  /** update multiples rows of table: "status_oed_thumbnail" */
  update_status_oed_thumbnail_many?: Maybe<Array<Maybe<Status_Oed_Thumbnail_Mutation_Response>>>;
  /** update data of the table: "storage" */
  update_storage?: Maybe<Storage_Mutation_Response>;
  /** update single row of the table: "storage" */
  update_storage_by_pk?: Maybe<Storage>;
  /** update multiples rows of table: "storage" */
  update_storage_many?: Maybe<Array<Maybe<Storage_Mutation_Response>>>;
  /** update data of the table: "storage_oed" */
  update_storage_oed?: Maybe<Storage_Oed_Mutation_Response>;
  /** update single row of the table: "storage_oed" */
  update_storage_oed_by_pk?: Maybe<Storage_Oed>;
  /** update multiples rows of table: "storage_oed" */
  update_storage_oed_many?: Maybe<Array<Maybe<Storage_Oed_Mutation_Response>>>;
  /** update data of the table: "team" */
  update_team?: Maybe<Team_Mutation_Response>;
  /** update single row of the table: "team" */
  update_team_by_pk?: Maybe<Team>;
  /** update multiples rows of table: "team" */
  update_team_many?: Maybe<Array<Maybe<Team_Mutation_Response>>>;
  /** update data of the table: "temp_user_oed" */
  update_temp_user_oed?: Maybe<Temp_User_Oed_Mutation_Response>;
  /** update multiples rows of table: "temp_user_oed" */
  update_temp_user_oed_many?: Maybe<Array<Maybe<Temp_User_Oed_Mutation_Response>>>;
  /** update data of the table: "user" */
  update_user?: Maybe<User_Mutation_Response>;
  /** update single row of the table: "user" */
  update_user_by_pk?: Maybe<User>;
  /** update multiples rows of table: "user" */
  update_user_many?: Maybe<Array<Maybe<User_Mutation_Response>>>;
  /** update data of the table: "user_role" */
  update_user_role?: Maybe<User_Role_Mutation_Response>;
  /** update single row of the table: "user_role" */
  update_user_role_by_pk?: Maybe<User_Role>;
  /** update multiples rows of table: "user_role" */
  update_user_role_many?: Maybe<Array<Maybe<User_Role_Mutation_Response>>>;
  /** update data of the table: "user_wallet" */
  update_user_wallet?: Maybe<User_Wallet_Mutation_Response>;
  /** update single row of the table: "user_wallet" */
  update_user_wallet_by_pk?: Maybe<User_Wallet>;
  /** update multiples rows of table: "user_wallet" */
  update_user_wallet_many?: Maybe<Array<Maybe<User_Wallet_Mutation_Response>>>;
  /** update data of the table: "users_teams" */
  update_users_teams?: Maybe<Users_Teams_Mutation_Response>;
  /** update single row of the table: "users_teams" */
  update_users_teams_by_pk?: Maybe<Users_Teams>;
  /** update multiples rows of table: "users_teams" */
  update_users_teams_many?: Maybe<Array<Maybe<Users_Teams_Mutation_Response>>>;
};


/** mutation root */
export type Mutation_RootDelete_AddressArgs = {
  where: Address_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Address_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Billing_AddressArgs = {
  where: Billing_Address_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Billing_Address_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_BuyerArgs = {
  where: Buyer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Buyer_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Config_OedArgs = {
  where: Config_Oed_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Config_Oed_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Config_PlanArgs = {
  where: Config_Plan_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Config_Plan_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_EventArgs = {
  where: Event_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Event_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Exclusive_OedArgs = {
  where: Exclusive_Oed_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_LibraryArgs = {
  where: Library_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Library_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_LoginArgs = {
  where: Login_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Login_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Manager_TeamsArgs = {
  where: Manager_Teams_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Manager_Teams_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_OedArgs = {
  where: Oed_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Oed_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Oed_TemplateArgs = {
  where: Oed_Template_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_PlanArgs = {
  where: Plan_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Plan_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Plan_OedArgs = {
  where: Plan_Oed_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Plan_Oed_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_ReportArgs = {
  where: Report_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Report_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_RoleArgs = {
  where: Role_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Role_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Role_Default_MetadataArgs = {
  where: Role_Default_Metadata_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Role_Default_Metadata_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Role_Oed_ExclusiveArgs = {
  where: Role_Oed_Exclusive_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Status_OedArgs = {
  where: Status_Oed_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Status_Oed_By_PkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Status_Oed_ThumbnailArgs = {
  where: Status_Oed_Thumbnail_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Status_Oed_Thumbnail_By_PkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_StorageArgs = {
  where: Storage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Storage_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Storage_OedArgs = {
  where: Storage_Oed_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Storage_Oed_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_TeamArgs = {
  where: Team_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Team_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Temp_User_OedArgs = {
  where: Temp_User_Oed_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_UserArgs = {
  where: User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_User_RoleArgs = {
  where: User_Role_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Role_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_User_WalletArgs = {
  where: User_Wallet_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Wallet_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Users_TeamsArgs = {
  where: Users_Teams_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Users_Teams_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootInsert_AddressArgs = {
  objects: Array<Address_Insert_Input>;
  on_conflict?: InputMaybe<Address_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Address_OneArgs = {
  object: Address_Insert_Input;
  on_conflict?: InputMaybe<Address_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Billing_AddressArgs = {
  objects: Array<Billing_Address_Insert_Input>;
  on_conflict?: InputMaybe<Billing_Address_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Billing_Address_OneArgs = {
  object: Billing_Address_Insert_Input;
  on_conflict?: InputMaybe<Billing_Address_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_BuyerArgs = {
  objects: Array<Buyer_Insert_Input>;
  on_conflict?: InputMaybe<Buyer_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Buyer_OneArgs = {
  object: Buyer_Insert_Input;
  on_conflict?: InputMaybe<Buyer_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Config_OedArgs = {
  objects: Array<Config_Oed_Insert_Input>;
  on_conflict?: InputMaybe<Config_Oed_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Config_Oed_OneArgs = {
  object: Config_Oed_Insert_Input;
  on_conflict?: InputMaybe<Config_Oed_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Config_PlanArgs = {
  objects: Array<Config_Plan_Insert_Input>;
  on_conflict?: InputMaybe<Config_Plan_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Config_Plan_OneArgs = {
  object: Config_Plan_Insert_Input;
  on_conflict?: InputMaybe<Config_Plan_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EventArgs = {
  objects: Array<Event_Insert_Input>;
  on_conflict?: InputMaybe<Event_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_OneArgs = {
  object: Event_Insert_Input;
  on_conflict?: InputMaybe<Event_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Exclusive_OedArgs = {
  objects: Array<Exclusive_Oed_Insert_Input>;
  on_conflict?: InputMaybe<Exclusive_Oed_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Exclusive_Oed_OneArgs = {
  object: Exclusive_Oed_Insert_Input;
  on_conflict?: InputMaybe<Exclusive_Oed_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_LibraryArgs = {
  objects: Array<Library_Insert_Input>;
  on_conflict?: InputMaybe<Library_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Library_OneArgs = {
  object: Library_Insert_Input;
  on_conflict?: InputMaybe<Library_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_LoginArgs = {
  objects: Array<Login_Insert_Input>;
  on_conflict?: InputMaybe<Login_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Login_OneArgs = {
  object: Login_Insert_Input;
  on_conflict?: InputMaybe<Login_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Manager_TeamsArgs = {
  objects: Array<Manager_Teams_Insert_Input>;
  on_conflict?: InputMaybe<Manager_Teams_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Manager_Teams_OneArgs = {
  object: Manager_Teams_Insert_Input;
  on_conflict?: InputMaybe<Manager_Teams_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_OedArgs = {
  objects: Array<Oed_Insert_Input>;
  on_conflict?: InputMaybe<Oed_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Oed_OneArgs = {
  object: Oed_Insert_Input;
  on_conflict?: InputMaybe<Oed_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Oed_TemplateArgs = {
  objects: Array<Oed_Template_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Oed_Template_OneArgs = {
  object: Oed_Template_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_PlanArgs = {
  objects: Array<Plan_Insert_Input>;
  on_conflict?: InputMaybe<Plan_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Plan_OedArgs = {
  objects: Array<Plan_Oed_Insert_Input>;
  on_conflict?: InputMaybe<Plan_Oed_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Plan_Oed_OneArgs = {
  object: Plan_Oed_Insert_Input;
  on_conflict?: InputMaybe<Plan_Oed_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Plan_OneArgs = {
  object: Plan_Insert_Input;
  on_conflict?: InputMaybe<Plan_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ReportArgs = {
  objects: Array<Report_Insert_Input>;
  on_conflict?: InputMaybe<Report_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Report_OneArgs = {
  object: Report_Insert_Input;
  on_conflict?: InputMaybe<Report_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_RoleArgs = {
  objects: Array<Role_Insert_Input>;
  on_conflict?: InputMaybe<Role_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Role_Default_MetadataArgs = {
  objects: Array<Role_Default_Metadata_Insert_Input>;
  on_conflict?: InputMaybe<Role_Default_Metadata_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Role_Default_Metadata_OneArgs = {
  object: Role_Default_Metadata_Insert_Input;
  on_conflict?: InputMaybe<Role_Default_Metadata_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Role_Oed_ExclusiveArgs = {
  objects: Array<Role_Oed_Exclusive_Insert_Input>;
  on_conflict?: InputMaybe<Role_Oed_Exclusive_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Role_Oed_Exclusive_OneArgs = {
  object: Role_Oed_Exclusive_Insert_Input;
  on_conflict?: InputMaybe<Role_Oed_Exclusive_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Role_OneArgs = {
  object: Role_Insert_Input;
  on_conflict?: InputMaybe<Role_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Status_OedArgs = {
  objects: Array<Status_Oed_Insert_Input>;
  on_conflict?: InputMaybe<Status_Oed_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Status_Oed_OneArgs = {
  object: Status_Oed_Insert_Input;
  on_conflict?: InputMaybe<Status_Oed_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Status_Oed_ThumbnailArgs = {
  objects: Array<Status_Oed_Thumbnail_Insert_Input>;
  on_conflict?: InputMaybe<Status_Oed_Thumbnail_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Status_Oed_Thumbnail_OneArgs = {
  object: Status_Oed_Thumbnail_Insert_Input;
  on_conflict?: InputMaybe<Status_Oed_Thumbnail_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_StorageArgs = {
  objects: Array<Storage_Insert_Input>;
  on_conflict?: InputMaybe<Storage_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Storage_OedArgs = {
  objects: Array<Storage_Oed_Insert_Input>;
  on_conflict?: InputMaybe<Storage_Oed_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Storage_Oed_OneArgs = {
  object: Storage_Oed_Insert_Input;
  on_conflict?: InputMaybe<Storage_Oed_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Storage_OneArgs = {
  object: Storage_Insert_Input;
  on_conflict?: InputMaybe<Storage_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TeamArgs = {
  objects: Array<Team_Insert_Input>;
  on_conflict?: InputMaybe<Team_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Team_OneArgs = {
  object: Team_Insert_Input;
  on_conflict?: InputMaybe<Team_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Temp_User_OedArgs = {
  objects: Array<Temp_User_Oed_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Temp_User_Oed_OneArgs = {
  object: Temp_User_Oed_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_UserArgs = {
  objects: Array<User_Insert_Input>;
  on_conflict?: InputMaybe<User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_OneArgs = {
  object: User_Insert_Input;
  on_conflict?: InputMaybe<User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_RoleArgs = {
  objects: Array<User_Role_Insert_Input>;
  on_conflict?: InputMaybe<User_Role_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Role_OneArgs = {
  object: User_Role_Insert_Input;
  on_conflict?: InputMaybe<User_Role_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_WalletArgs = {
  objects: Array<User_Wallet_Insert_Input>;
  on_conflict?: InputMaybe<User_Wallet_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Wallet_OneArgs = {
  object: User_Wallet_Insert_Input;
  on_conflict?: InputMaybe<User_Wallet_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_TeamsArgs = {
  objects: Array<Users_Teams_Insert_Input>;
  on_conflict?: InputMaybe<Users_Teams_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_Teams_OneArgs = {
  object: Users_Teams_Insert_Input;
  on_conflict?: InputMaybe<Users_Teams_On_Conflict>;
};


/** mutation root */
export type Mutation_RootUpdate_AddressArgs = {
  _inc?: InputMaybe<Address_Inc_Input>;
  _set?: InputMaybe<Address_Set_Input>;
  where: Address_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Address_By_PkArgs = {
  _inc?: InputMaybe<Address_Inc_Input>;
  _set?: InputMaybe<Address_Set_Input>;
  pk_columns: Address_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Address_ManyArgs = {
  updates: Array<Address_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Billing_AddressArgs = {
  _inc?: InputMaybe<Billing_Address_Inc_Input>;
  _set?: InputMaybe<Billing_Address_Set_Input>;
  where: Billing_Address_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Billing_Address_By_PkArgs = {
  _inc?: InputMaybe<Billing_Address_Inc_Input>;
  _set?: InputMaybe<Billing_Address_Set_Input>;
  pk_columns: Billing_Address_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Billing_Address_ManyArgs = {
  updates: Array<Billing_Address_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_BuyerArgs = {
  _set?: InputMaybe<Buyer_Set_Input>;
  where: Buyer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Buyer_By_PkArgs = {
  _set?: InputMaybe<Buyer_Set_Input>;
  pk_columns: Buyer_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Buyer_ManyArgs = {
  updates: Array<Buyer_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Config_OedArgs = {
  _set?: InputMaybe<Config_Oed_Set_Input>;
  where: Config_Oed_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Config_Oed_By_PkArgs = {
  _set?: InputMaybe<Config_Oed_Set_Input>;
  pk_columns: Config_Oed_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Config_Oed_ManyArgs = {
  updates: Array<Config_Oed_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Config_PlanArgs = {
  _set?: InputMaybe<Config_Plan_Set_Input>;
  where: Config_Plan_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Config_Plan_By_PkArgs = {
  _set?: InputMaybe<Config_Plan_Set_Input>;
  pk_columns: Config_Plan_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Config_Plan_ManyArgs = {
  updates: Array<Config_Plan_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_EventArgs = {
  _inc?: InputMaybe<Event_Inc_Input>;
  _set?: InputMaybe<Event_Set_Input>;
  where: Event_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Event_By_PkArgs = {
  _inc?: InputMaybe<Event_Inc_Input>;
  _set?: InputMaybe<Event_Set_Input>;
  pk_columns: Event_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Event_ManyArgs = {
  updates: Array<Event_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Exclusive_OedArgs = {
  _set?: InputMaybe<Exclusive_Oed_Set_Input>;
  where: Exclusive_Oed_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Exclusive_Oed_ManyArgs = {
  updates: Array<Exclusive_Oed_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_LibraryArgs = {
  _set?: InputMaybe<Library_Set_Input>;
  where: Library_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Library_By_PkArgs = {
  _set?: InputMaybe<Library_Set_Input>;
  pk_columns: Library_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Library_ManyArgs = {
  updates: Array<Library_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_LoginArgs = {
  _inc?: InputMaybe<Login_Inc_Input>;
  _set?: InputMaybe<Login_Set_Input>;
  where: Login_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Login_By_PkArgs = {
  _inc?: InputMaybe<Login_Inc_Input>;
  _set?: InputMaybe<Login_Set_Input>;
  pk_columns: Login_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Login_ManyArgs = {
  updates: Array<Login_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Manager_TeamsArgs = {
  _set?: InputMaybe<Manager_Teams_Set_Input>;
  where: Manager_Teams_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Manager_Teams_By_PkArgs = {
  _set?: InputMaybe<Manager_Teams_Set_Input>;
  pk_columns: Manager_Teams_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Manager_Teams_ManyArgs = {
  updates: Array<Manager_Teams_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_OedArgs = {
  _inc?: InputMaybe<Oed_Inc_Input>;
  _set?: InputMaybe<Oed_Set_Input>;
  where: Oed_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Oed_By_PkArgs = {
  _inc?: InputMaybe<Oed_Inc_Input>;
  _set?: InputMaybe<Oed_Set_Input>;
  pk_columns: Oed_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Oed_ManyArgs = {
  updates: Array<Oed_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Oed_TemplateArgs = {
  _set?: InputMaybe<Oed_Template_Set_Input>;
  where: Oed_Template_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Oed_Template_ManyArgs = {
  updates: Array<Oed_Template_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_PlanArgs = {
  _inc?: InputMaybe<Plan_Inc_Input>;
  _set?: InputMaybe<Plan_Set_Input>;
  where: Plan_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Plan_By_PkArgs = {
  _inc?: InputMaybe<Plan_Inc_Input>;
  _set?: InputMaybe<Plan_Set_Input>;
  pk_columns: Plan_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Plan_ManyArgs = {
  updates: Array<Plan_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Plan_OedArgs = {
  _set?: InputMaybe<Plan_Oed_Set_Input>;
  where: Plan_Oed_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Plan_Oed_By_PkArgs = {
  _set?: InputMaybe<Plan_Oed_Set_Input>;
  pk_columns: Plan_Oed_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Plan_Oed_ManyArgs = {
  updates: Array<Plan_Oed_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ReportArgs = {
  _append?: InputMaybe<Report_Append_Input>;
  _delete_at_path?: InputMaybe<Report_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Report_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Report_Delete_Key_Input>;
  _prepend?: InputMaybe<Report_Prepend_Input>;
  _set?: InputMaybe<Report_Set_Input>;
  where: Report_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Report_By_PkArgs = {
  _append?: InputMaybe<Report_Append_Input>;
  _delete_at_path?: InputMaybe<Report_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Report_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Report_Delete_Key_Input>;
  _prepend?: InputMaybe<Report_Prepend_Input>;
  _set?: InputMaybe<Report_Set_Input>;
  pk_columns: Report_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Report_ManyArgs = {
  updates: Array<Report_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_RoleArgs = {
  _append?: InputMaybe<Role_Append_Input>;
  _delete_at_path?: InputMaybe<Role_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Role_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Role_Delete_Key_Input>;
  _prepend?: InputMaybe<Role_Prepend_Input>;
  _set?: InputMaybe<Role_Set_Input>;
  where: Role_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Role_By_PkArgs = {
  _append?: InputMaybe<Role_Append_Input>;
  _delete_at_path?: InputMaybe<Role_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Role_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Role_Delete_Key_Input>;
  _prepend?: InputMaybe<Role_Prepend_Input>;
  _set?: InputMaybe<Role_Set_Input>;
  pk_columns: Role_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Role_Default_MetadataArgs = {
  _set?: InputMaybe<Role_Default_Metadata_Set_Input>;
  where: Role_Default_Metadata_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Role_Default_Metadata_By_PkArgs = {
  _set?: InputMaybe<Role_Default_Metadata_Set_Input>;
  pk_columns: Role_Default_Metadata_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Role_Default_Metadata_ManyArgs = {
  updates: Array<Role_Default_Metadata_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Role_ManyArgs = {
  updates: Array<Role_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Role_Oed_ExclusiveArgs = {
  _set?: InputMaybe<Role_Oed_Exclusive_Set_Input>;
  where: Role_Oed_Exclusive_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Role_Oed_Exclusive_ManyArgs = {
  updates: Array<Role_Oed_Exclusive_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Status_OedArgs = {
  _set?: InputMaybe<Status_Oed_Set_Input>;
  where: Status_Oed_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Status_Oed_By_PkArgs = {
  _set?: InputMaybe<Status_Oed_Set_Input>;
  pk_columns: Status_Oed_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Status_Oed_ManyArgs = {
  updates: Array<Status_Oed_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Status_Oed_ThumbnailArgs = {
  _set?: InputMaybe<Status_Oed_Thumbnail_Set_Input>;
  where: Status_Oed_Thumbnail_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Status_Oed_Thumbnail_By_PkArgs = {
  _set?: InputMaybe<Status_Oed_Thumbnail_Set_Input>;
  pk_columns: Status_Oed_Thumbnail_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Status_Oed_Thumbnail_ManyArgs = {
  updates: Array<Status_Oed_Thumbnail_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_StorageArgs = {
  _set?: InputMaybe<Storage_Set_Input>;
  where: Storage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Storage_By_PkArgs = {
  _set?: InputMaybe<Storage_Set_Input>;
  pk_columns: Storage_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Storage_ManyArgs = {
  updates: Array<Storage_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Storage_OedArgs = {
  _inc?: InputMaybe<Storage_Oed_Inc_Input>;
  _set?: InputMaybe<Storage_Oed_Set_Input>;
  where: Storage_Oed_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Storage_Oed_By_PkArgs = {
  _inc?: InputMaybe<Storage_Oed_Inc_Input>;
  _set?: InputMaybe<Storage_Oed_Set_Input>;
  pk_columns: Storage_Oed_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Storage_Oed_ManyArgs = {
  updates: Array<Storage_Oed_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_TeamArgs = {
  _inc?: InputMaybe<Team_Inc_Input>;
  _set?: InputMaybe<Team_Set_Input>;
  where: Team_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Team_By_PkArgs = {
  _inc?: InputMaybe<Team_Inc_Input>;
  _set?: InputMaybe<Team_Set_Input>;
  pk_columns: Team_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Team_ManyArgs = {
  updates: Array<Team_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Temp_User_OedArgs = {
  _set?: InputMaybe<Temp_User_Oed_Set_Input>;
  where: Temp_User_Oed_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Temp_User_Oed_ManyArgs = {
  updates: Array<Temp_User_Oed_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_UserArgs = {
  _set?: InputMaybe<User_Set_Input>;
  where: User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_By_PkArgs = {
  _set?: InputMaybe<User_Set_Input>;
  pk_columns: User_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_ManyArgs = {
  updates: Array<User_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_RoleArgs = {
  _set?: InputMaybe<User_Role_Set_Input>;
  where: User_Role_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Role_By_PkArgs = {
  _set?: InputMaybe<User_Role_Set_Input>;
  pk_columns: User_Role_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Role_ManyArgs = {
  updates: Array<User_Role_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_WalletArgs = {
  _set?: InputMaybe<User_Wallet_Set_Input>;
  where: User_Wallet_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Wallet_By_PkArgs = {
  _set?: InputMaybe<User_Wallet_Set_Input>;
  pk_columns: User_Wallet_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Wallet_ManyArgs = {
  updates: Array<User_Wallet_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Users_TeamsArgs = {
  _set?: InputMaybe<Users_Teams_Set_Input>;
  where: Users_Teams_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_Teams_By_PkArgs = {
  _set?: InputMaybe<Users_Teams_Set_Input>;
  pk_columns: Users_Teams_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Users_Teams_ManyArgs = {
  updates: Array<Users_Teams_Updates>;
};

/** columns and relationships of "oed" */
export type Oed = {
  __typename?: 'oed';
  active?: Maybe<Scalars['Boolean']['output']>;
  /** An object relationship */
  config_oed?: Maybe<Config_Oed>;
  config_oed_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id: Scalars['uuid']['output'];
  /** An array relationship */
  libraries: Array<Library>;
  /** An aggregate relationship */
  libraries_aggregate: Library_Aggregate;
  locked?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  plan_oeds: Array<Plan_Oed>;
  /** An aggregate relationship */
  plan_oeds_aggregate: Plan_Oed_Aggregate;
  /** An object relationship */
  status_oed_thumbnail?: Maybe<Status_Oed_Thumbnail>;
  strapi_id?: Maybe<Scalars['Int']['output']>;
  thumbnail_status?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};


/** columns and relationships of "oed" */
export type OedLibrariesArgs = {
  distinct_on?: InputMaybe<Array<Library_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Library_Order_By>>;
  where?: InputMaybe<Library_Bool_Exp>;
};


/** columns and relationships of "oed" */
export type OedLibraries_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Library_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Library_Order_By>>;
  where?: InputMaybe<Library_Bool_Exp>;
};


/** columns and relationships of "oed" */
export type OedPlan_OedsArgs = {
  distinct_on?: InputMaybe<Array<Plan_Oed_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Plan_Oed_Order_By>>;
  where?: InputMaybe<Plan_Oed_Bool_Exp>;
};


/** columns and relationships of "oed" */
export type OedPlan_Oeds_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Plan_Oed_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Plan_Oed_Order_By>>;
  where?: InputMaybe<Plan_Oed_Bool_Exp>;
};

/** aggregated selection of "oed" */
export type Oed_Aggregate = {
  __typename?: 'oed_aggregate';
  aggregate?: Maybe<Oed_Aggregate_Fields>;
  nodes: Array<Oed>;
};

export type Oed_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Oed_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Oed_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Oed_Aggregate_Bool_Exp_Count>;
};

export type Oed_Aggregate_Bool_Exp_Bool_And = {
  arguments: Oed_Select_Column_Oed_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Oed_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Oed_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Oed_Select_Column_Oed_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Oed_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Oed_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Oed_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Oed_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "oed" */
export type Oed_Aggregate_Fields = {
  __typename?: 'oed_aggregate_fields';
  avg?: Maybe<Oed_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Oed_Max_Fields>;
  min?: Maybe<Oed_Min_Fields>;
  stddev?: Maybe<Oed_Stddev_Fields>;
  stddev_pop?: Maybe<Oed_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Oed_Stddev_Samp_Fields>;
  sum?: Maybe<Oed_Sum_Fields>;
  var_pop?: Maybe<Oed_Var_Pop_Fields>;
  var_samp?: Maybe<Oed_Var_Samp_Fields>;
  variance?: Maybe<Oed_Variance_Fields>;
};


/** aggregate fields of "oed" */
export type Oed_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Oed_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "oed" */
export type Oed_Aggregate_Order_By = {
  avg?: InputMaybe<Oed_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Oed_Max_Order_By>;
  min?: InputMaybe<Oed_Min_Order_By>;
  stddev?: InputMaybe<Oed_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Oed_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Oed_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Oed_Sum_Order_By>;
  var_pop?: InputMaybe<Oed_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Oed_Var_Samp_Order_By>;
  variance?: InputMaybe<Oed_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "oed" */
export type Oed_Arr_Rel_Insert_Input = {
  data: Array<Oed_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Oed_On_Conflict>;
};

/** aggregate avg on columns */
export type Oed_Avg_Fields = {
  __typename?: 'oed_avg_fields';
  strapi_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "oed" */
export type Oed_Avg_Order_By = {
  strapi_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "oed". All fields are combined with a logical 'AND'. */
export type Oed_Bool_Exp = {
  _and?: InputMaybe<Array<Oed_Bool_Exp>>;
  _not?: InputMaybe<Oed_Bool_Exp>;
  _or?: InputMaybe<Array<Oed_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  config_oed?: InputMaybe<Config_Oed_Bool_Exp>;
  config_oed_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  libraries?: InputMaybe<Library_Bool_Exp>;
  libraries_aggregate?: InputMaybe<Library_Aggregate_Bool_Exp>;
  locked?: InputMaybe<Boolean_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  plan_oeds?: InputMaybe<Plan_Oed_Bool_Exp>;
  plan_oeds_aggregate?: InputMaybe<Plan_Oed_Aggregate_Bool_Exp>;
  status_oed_thumbnail?: InputMaybe<Status_Oed_Thumbnail_Bool_Exp>;
  strapi_id?: InputMaybe<Int_Comparison_Exp>;
  thumbnail_status?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  url?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "oed" */
export enum Oed_Constraint {
  /** unique or primary key constraint on columns "id" */
  OedPkey = 'oed_pkey',
  /** unique or primary key constraint on columns "type", "strapi_id" */
  OedStrapiIdTypeKey = 'oed_strapi_id_type_key'
}

/** input type for incrementing numeric columns in table "oed" */
export type Oed_Inc_Input = {
  strapi_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "oed" */
export type Oed_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  config_oed?: InputMaybe<Config_Oed_Obj_Rel_Insert_Input>;
  config_oed_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  libraries?: InputMaybe<Library_Arr_Rel_Insert_Input>;
  locked?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  plan_oeds?: InputMaybe<Plan_Oed_Arr_Rel_Insert_Input>;
  status_oed_thumbnail?: InputMaybe<Status_Oed_Thumbnail_Obj_Rel_Insert_Input>;
  strapi_id?: InputMaybe<Scalars['Int']['input']>;
  thumbnail_status?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Oed_Max_Fields = {
  __typename?: 'oed_max_fields';
  config_oed_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  strapi_id?: Maybe<Scalars['Int']['output']>;
  thumbnail_status?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "oed" */
export type Oed_Max_Order_By = {
  config_oed_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  strapi_id?: InputMaybe<Order_By>;
  thumbnail_status?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Oed_Min_Fields = {
  __typename?: 'oed_min_fields';
  config_oed_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  strapi_id?: Maybe<Scalars['Int']['output']>;
  thumbnail_status?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "oed" */
export type Oed_Min_Order_By = {
  config_oed_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  strapi_id?: InputMaybe<Order_By>;
  thumbnail_status?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "oed" */
export type Oed_Mutation_Response = {
  __typename?: 'oed_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Oed>;
};

/** input type for inserting object relation for remote table "oed" */
export type Oed_Obj_Rel_Insert_Input = {
  data: Oed_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Oed_On_Conflict>;
};

/** on_conflict condition type for table "oed" */
export type Oed_On_Conflict = {
  constraint: Oed_Constraint;
  update_columns?: Array<Oed_Update_Column>;
  where?: InputMaybe<Oed_Bool_Exp>;
};

/** Ordering options when selecting data from "oed". */
export type Oed_Order_By = {
  active?: InputMaybe<Order_By>;
  config_oed?: InputMaybe<Config_Oed_Order_By>;
  config_oed_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  libraries_aggregate?: InputMaybe<Library_Aggregate_Order_By>;
  locked?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  plan_oeds_aggregate?: InputMaybe<Plan_Oed_Aggregate_Order_By>;
  status_oed_thumbnail?: InputMaybe<Status_Oed_Thumbnail_Order_By>;
  strapi_id?: InputMaybe<Order_By>;
  thumbnail_status?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** primary key columns input for table: oed */
export type Oed_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "oed" */
export enum Oed_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  ConfigOedId = 'config_oed_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Locked = 'locked',
  /** column name */
  Name = 'name',
  /** column name */
  StrapiId = 'strapi_id',
  /** column name */
  ThumbnailStatus = 'thumbnail_status',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url'
}

/** select "oed_aggregate_bool_exp_bool_and_arguments_columns" columns of table "oed" */
export enum Oed_Select_Column_Oed_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Active = 'active',
  /** column name */
  Locked = 'locked'
}

/** select "oed_aggregate_bool_exp_bool_or_arguments_columns" columns of table "oed" */
export enum Oed_Select_Column_Oed_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Active = 'active',
  /** column name */
  Locked = 'locked'
}

/** input type for updating data in table "oed" */
export type Oed_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  config_oed_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  locked?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  strapi_id?: InputMaybe<Scalars['Int']['input']>;
  thumbnail_status?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Oed_Stddev_Fields = {
  __typename?: 'oed_stddev_fields';
  strapi_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "oed" */
export type Oed_Stddev_Order_By = {
  strapi_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Oed_Stddev_Pop_Fields = {
  __typename?: 'oed_stddev_pop_fields';
  strapi_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "oed" */
export type Oed_Stddev_Pop_Order_By = {
  strapi_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Oed_Stddev_Samp_Fields = {
  __typename?: 'oed_stddev_samp_fields';
  strapi_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "oed" */
export type Oed_Stddev_Samp_Order_By = {
  strapi_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "oed" */
export type Oed_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Oed_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Oed_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  config_oed_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  locked?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  strapi_id?: InputMaybe<Scalars['Int']['input']>;
  thumbnail_status?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Oed_Sum_Fields = {
  __typename?: 'oed_sum_fields';
  strapi_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "oed" */
export type Oed_Sum_Order_By = {
  strapi_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "oed_template" */
export type Oed_Template = {
  __typename?: 'oed_template';
  active?: Maybe<Scalars['Boolean']['output']>;
  alias?: Maybe<Scalars['String']['output']>;
  api_strapi_id?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id: Scalars['uuid']['output'];
  metadata?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregated selection of "oed_template" */
export type Oed_Template_Aggregate = {
  __typename?: 'oed_template_aggregate';
  aggregate?: Maybe<Oed_Template_Aggregate_Fields>;
  nodes: Array<Oed_Template>;
};

/** aggregate fields of "oed_template" */
export type Oed_Template_Aggregate_Fields = {
  __typename?: 'oed_template_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Oed_Template_Max_Fields>;
  min?: Maybe<Oed_Template_Min_Fields>;
};


/** aggregate fields of "oed_template" */
export type Oed_Template_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Oed_Template_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "oed_template". All fields are combined with a logical 'AND'. */
export type Oed_Template_Bool_Exp = {
  _and?: InputMaybe<Array<Oed_Template_Bool_Exp>>;
  _not?: InputMaybe<Oed_Template_Bool_Exp>;
  _or?: InputMaybe<Array<Oed_Template_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  alias?: InputMaybe<String_Comparison_Exp>;
  api_strapi_id?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  metadata?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** input type for inserting data into table "oed_template" */
export type Oed_Template_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  alias?: InputMaybe<Scalars['String']['input']>;
  api_strapi_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  metadata?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type Oed_Template_Max_Fields = {
  __typename?: 'oed_template_max_fields';
  alias?: Maybe<Scalars['String']['output']>;
  api_strapi_id?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  metadata?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregate min on columns */
export type Oed_Template_Min_Fields = {
  __typename?: 'oed_template_min_fields';
  alias?: Maybe<Scalars['String']['output']>;
  api_strapi_id?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  metadata?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** response of any mutation on the table "oed_template" */
export type Oed_Template_Mutation_Response = {
  __typename?: 'oed_template_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Oed_Template>;
};

/** Ordering options when selecting data from "oed_template". */
export type Oed_Template_Order_By = {
  active?: InputMaybe<Order_By>;
  alias?: InputMaybe<Order_By>;
  api_strapi_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** select columns of table "oed_template" */
export enum Oed_Template_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  Alias = 'alias',
  /** column name */
  ApiStrapiId = 'api_strapi_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Name = 'name',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "oed_template" */
export type Oed_Template_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  alias?: InputMaybe<Scalars['String']['input']>;
  api_strapi_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  metadata?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** Streaming cursor of the table "oed_template" */
export type Oed_Template_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Oed_Template_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Oed_Template_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  alias?: InputMaybe<Scalars['String']['input']>;
  api_strapi_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  metadata?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

export type Oed_Template_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Oed_Template_Set_Input>;
  /** filter the rows which have to be updated */
  where: Oed_Template_Bool_Exp;
};

/** update columns of table "oed" */
export enum Oed_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  ConfigOedId = 'config_oed_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Locked = 'locked',
  /** column name */
  Name = 'name',
  /** column name */
  StrapiId = 'strapi_id',
  /** column name */
  ThumbnailStatus = 'thumbnail_status',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url'
}

export type Oed_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Oed_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Oed_Set_Input>;
  /** filter the rows which have to be updated */
  where: Oed_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Oed_Var_Pop_Fields = {
  __typename?: 'oed_var_pop_fields';
  strapi_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "oed" */
export type Oed_Var_Pop_Order_By = {
  strapi_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Oed_Var_Samp_Fields = {
  __typename?: 'oed_var_samp_fields';
  strapi_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "oed" */
export type Oed_Var_Samp_Order_By = {
  strapi_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Oed_Variance_Fields = {
  __typename?: 'oed_variance_fields';
  strapi_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "oed" */
export type Oed_Variance_Order_By = {
  strapi_id?: InputMaybe<Order_By>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "plan" */
export type Plan = {
  __typename?: 'plan';
  active?: Maybe<Scalars['Boolean']['output']>;
  amount?: Maybe<Scalars['Int']['output']>;
  /** An array relationship */
  buyers: Array<Buyer>;
  /** An aggregate relationship */
  buyers_aggregate: Buyer_Aggregate;
  /** An object relationship */
  config_plan?: Maybe<Config_Plan>;
  config_plan_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  exports?: Maybe<Scalars['Int']['output']>;
  id: Scalars['uuid']['output'];
  limit?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  oed_id?: Maybe<Scalars['uuid']['output']>;
  /** An array relationship */
  plan_oeds: Array<Plan_Oed>;
  /** An aggregate relationship */
  plan_oeds_aggregate: Plan_Oed_Aggregate;
  price?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  views?: Maybe<Scalars['Int']['output']>;
};


/** columns and relationships of "plan" */
export type PlanBuyersArgs = {
  distinct_on?: InputMaybe<Array<Buyer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Buyer_Order_By>>;
  where?: InputMaybe<Buyer_Bool_Exp>;
};


/** columns and relationships of "plan" */
export type PlanBuyers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Buyer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Buyer_Order_By>>;
  where?: InputMaybe<Buyer_Bool_Exp>;
};


/** columns and relationships of "plan" */
export type PlanPlan_OedsArgs = {
  distinct_on?: InputMaybe<Array<Plan_Oed_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Plan_Oed_Order_By>>;
  where?: InputMaybe<Plan_Oed_Bool_Exp>;
};


/** columns and relationships of "plan" */
export type PlanPlan_Oeds_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Plan_Oed_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Plan_Oed_Order_By>>;
  where?: InputMaybe<Plan_Oed_Bool_Exp>;
};

/** aggregated selection of "plan" */
export type Plan_Aggregate = {
  __typename?: 'plan_aggregate';
  aggregate?: Maybe<Plan_Aggregate_Fields>;
  nodes: Array<Plan>;
};

export type Plan_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Plan_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Plan_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Plan_Aggregate_Bool_Exp_Count>;
};

export type Plan_Aggregate_Bool_Exp_Bool_And = {
  arguments: Plan_Select_Column_Plan_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Plan_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Plan_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Plan_Select_Column_Plan_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Plan_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Plan_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Plan_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Plan_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "plan" */
export type Plan_Aggregate_Fields = {
  __typename?: 'plan_aggregate_fields';
  avg?: Maybe<Plan_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Plan_Max_Fields>;
  min?: Maybe<Plan_Min_Fields>;
  stddev?: Maybe<Plan_Stddev_Fields>;
  stddev_pop?: Maybe<Plan_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Plan_Stddev_Samp_Fields>;
  sum?: Maybe<Plan_Sum_Fields>;
  var_pop?: Maybe<Plan_Var_Pop_Fields>;
  var_samp?: Maybe<Plan_Var_Samp_Fields>;
  variance?: Maybe<Plan_Variance_Fields>;
};


/** aggregate fields of "plan" */
export type Plan_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Plan_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "plan" */
export type Plan_Aggregate_Order_By = {
  avg?: InputMaybe<Plan_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Plan_Max_Order_By>;
  min?: InputMaybe<Plan_Min_Order_By>;
  stddev?: InputMaybe<Plan_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Plan_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Plan_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Plan_Sum_Order_By>;
  var_pop?: InputMaybe<Plan_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Plan_Var_Samp_Order_By>;
  variance?: InputMaybe<Plan_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "plan" */
export type Plan_Arr_Rel_Insert_Input = {
  data: Array<Plan_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Plan_On_Conflict>;
};

/** aggregate avg on columns */
export type Plan_Avg_Fields = {
  __typename?: 'plan_avg_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  exports?: Maybe<Scalars['Float']['output']>;
  limit?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  views?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "plan" */
export type Plan_Avg_Order_By = {
  amount?: InputMaybe<Order_By>;
  exports?: InputMaybe<Order_By>;
  limit?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  views?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "plan". All fields are combined with a logical 'AND'. */
export type Plan_Bool_Exp = {
  _and?: InputMaybe<Array<Plan_Bool_Exp>>;
  _not?: InputMaybe<Plan_Bool_Exp>;
  _or?: InputMaybe<Array<Plan_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  amount?: InputMaybe<Int_Comparison_Exp>;
  buyers?: InputMaybe<Buyer_Bool_Exp>;
  buyers_aggregate?: InputMaybe<Buyer_Aggregate_Bool_Exp>;
  config_plan?: InputMaybe<Config_Plan_Bool_Exp>;
  config_plan_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  exports?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  limit?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  oed_id?: InputMaybe<Uuid_Comparison_Exp>;
  plan_oeds?: InputMaybe<Plan_Oed_Bool_Exp>;
  plan_oeds_aggregate?: InputMaybe<Plan_Oed_Aggregate_Bool_Exp>;
  price?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  views?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "plan" */
export enum Plan_Constraint {
  /** unique or primary key constraint on columns "id" */
  PlanPkey = 'plan_pkey'
}

/** input type for incrementing numeric columns in table "plan" */
export type Plan_Inc_Input = {
  amount?: InputMaybe<Scalars['Int']['input']>;
  exports?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  price?: InputMaybe<Scalars['Int']['input']>;
  views?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "plan" */
export type Plan_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  amount?: InputMaybe<Scalars['Int']['input']>;
  buyers?: InputMaybe<Buyer_Arr_Rel_Insert_Input>;
  config_plan?: InputMaybe<Config_Plan_Obj_Rel_Insert_Input>;
  config_plan_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  exports?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  oed_id?: InputMaybe<Scalars['uuid']['input']>;
  plan_oeds?: InputMaybe<Plan_Oed_Arr_Rel_Insert_Input>;
  price?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  views?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type Plan_Max_Fields = {
  __typename?: 'plan_max_fields';
  amount?: Maybe<Scalars['Int']['output']>;
  config_plan_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  exports?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  oed_id?: Maybe<Scalars['uuid']['output']>;
  price?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  views?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "plan" */
export type Plan_Max_Order_By = {
  amount?: InputMaybe<Order_By>;
  config_plan_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  exports?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  limit?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  oed_id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  views?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Plan_Min_Fields = {
  __typename?: 'plan_min_fields';
  amount?: Maybe<Scalars['Int']['output']>;
  config_plan_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  exports?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  oed_id?: Maybe<Scalars['uuid']['output']>;
  price?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  views?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "plan" */
export type Plan_Min_Order_By = {
  amount?: InputMaybe<Order_By>;
  config_plan_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  exports?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  limit?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  oed_id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  views?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "plan" */
export type Plan_Mutation_Response = {
  __typename?: 'plan_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Plan>;
};

/** input type for inserting object relation for remote table "plan" */
export type Plan_Obj_Rel_Insert_Input = {
  data: Plan_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Plan_On_Conflict>;
};

/** columns and relationships of "plan_oed" */
export type Plan_Oed = {
  __typename?: 'plan_oed';
  active?: Maybe<Scalars['Boolean']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id: Scalars['uuid']['output'];
  /** An object relationship */
  oed?: Maybe<Oed>;
  oed_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  plan?: Maybe<Plan>;
  plan_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregated selection of "plan_oed" */
export type Plan_Oed_Aggregate = {
  __typename?: 'plan_oed_aggregate';
  aggregate?: Maybe<Plan_Oed_Aggregate_Fields>;
  nodes: Array<Plan_Oed>;
};

export type Plan_Oed_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Plan_Oed_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Plan_Oed_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Plan_Oed_Aggregate_Bool_Exp_Count>;
};

export type Plan_Oed_Aggregate_Bool_Exp_Bool_And = {
  arguments: Plan_Oed_Select_Column_Plan_Oed_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Plan_Oed_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Plan_Oed_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Plan_Oed_Select_Column_Plan_Oed_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Plan_Oed_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Plan_Oed_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Plan_Oed_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Plan_Oed_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "plan_oed" */
export type Plan_Oed_Aggregate_Fields = {
  __typename?: 'plan_oed_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Plan_Oed_Max_Fields>;
  min?: Maybe<Plan_Oed_Min_Fields>;
};


/** aggregate fields of "plan_oed" */
export type Plan_Oed_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Plan_Oed_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "plan_oed" */
export type Plan_Oed_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Plan_Oed_Max_Order_By>;
  min?: InputMaybe<Plan_Oed_Min_Order_By>;
};

/** input type for inserting array relation for remote table "plan_oed" */
export type Plan_Oed_Arr_Rel_Insert_Input = {
  data: Array<Plan_Oed_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Plan_Oed_On_Conflict>;
};

/** Boolean expression to filter rows from the table "plan_oed". All fields are combined with a logical 'AND'. */
export type Plan_Oed_Bool_Exp = {
  _and?: InputMaybe<Array<Plan_Oed_Bool_Exp>>;
  _not?: InputMaybe<Plan_Oed_Bool_Exp>;
  _or?: InputMaybe<Array<Plan_Oed_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  oed?: InputMaybe<Oed_Bool_Exp>;
  oed_id?: InputMaybe<Uuid_Comparison_Exp>;
  plan?: InputMaybe<Plan_Bool_Exp>;
  plan_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "plan_oed" */
export enum Plan_Oed_Constraint {
  /** unique or primary key constraint on columns "id" */
  PlanOedPkey = 'plan_oed_pkey'
}

/** input type for inserting data into table "plan_oed" */
export type Plan_Oed_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  oed?: InputMaybe<Oed_Obj_Rel_Insert_Input>;
  oed_id?: InputMaybe<Scalars['uuid']['input']>;
  plan?: InputMaybe<Plan_Obj_Rel_Insert_Input>;
  plan_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type Plan_Oed_Max_Fields = {
  __typename?: 'plan_oed_max_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  oed_id?: Maybe<Scalars['uuid']['output']>;
  plan_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** order by max() on columns of table "plan_oed" */
export type Plan_Oed_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  oed_id?: InputMaybe<Order_By>;
  plan_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Plan_Oed_Min_Fields = {
  __typename?: 'plan_oed_min_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  oed_id?: Maybe<Scalars['uuid']['output']>;
  plan_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** order by min() on columns of table "plan_oed" */
export type Plan_Oed_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  oed_id?: InputMaybe<Order_By>;
  plan_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "plan_oed" */
export type Plan_Oed_Mutation_Response = {
  __typename?: 'plan_oed_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Plan_Oed>;
};

/** on_conflict condition type for table "plan_oed" */
export type Plan_Oed_On_Conflict = {
  constraint: Plan_Oed_Constraint;
  update_columns?: Array<Plan_Oed_Update_Column>;
  where?: InputMaybe<Plan_Oed_Bool_Exp>;
};

/** Ordering options when selecting data from "plan_oed". */
export type Plan_Oed_Order_By = {
  active?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  oed?: InputMaybe<Oed_Order_By>;
  oed_id?: InputMaybe<Order_By>;
  plan?: InputMaybe<Plan_Order_By>;
  plan_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: plan_oed */
export type Plan_Oed_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "plan_oed" */
export enum Plan_Oed_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  OedId = 'oed_id',
  /** column name */
  PlanId = 'plan_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "plan_oed_aggregate_bool_exp_bool_and_arguments_columns" columns of table "plan_oed" */
export enum Plan_Oed_Select_Column_Plan_Oed_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** select "plan_oed_aggregate_bool_exp_bool_or_arguments_columns" columns of table "plan_oed" */
export enum Plan_Oed_Select_Column_Plan_Oed_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** input type for updating data in table "plan_oed" */
export type Plan_Oed_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  oed_id?: InputMaybe<Scalars['uuid']['input']>;
  plan_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** Streaming cursor of the table "plan_oed" */
export type Plan_Oed_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Plan_Oed_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Plan_Oed_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  oed_id?: InputMaybe<Scalars['uuid']['input']>;
  plan_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** update columns of table "plan_oed" */
export enum Plan_Oed_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  OedId = 'oed_id',
  /** column name */
  PlanId = 'plan_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Plan_Oed_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Plan_Oed_Set_Input>;
  /** filter the rows which have to be updated */
  where: Plan_Oed_Bool_Exp;
};

/** on_conflict condition type for table "plan" */
export type Plan_On_Conflict = {
  constraint: Plan_Constraint;
  update_columns?: Array<Plan_Update_Column>;
  where?: InputMaybe<Plan_Bool_Exp>;
};

/** Ordering options when selecting data from "plan". */
export type Plan_Order_By = {
  active?: InputMaybe<Order_By>;
  amount?: InputMaybe<Order_By>;
  buyers_aggregate?: InputMaybe<Buyer_Aggregate_Order_By>;
  config_plan?: InputMaybe<Config_Plan_Order_By>;
  config_plan_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  exports?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  limit?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  oed_id?: InputMaybe<Order_By>;
  plan_oeds_aggregate?: InputMaybe<Plan_Oed_Aggregate_Order_By>;
  price?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  views?: InputMaybe<Order_By>;
};

/** primary key columns input for table: plan */
export type Plan_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "plan" */
export enum Plan_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  Amount = 'amount',
  /** column name */
  ConfigPlanId = 'config_plan_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Exports = 'exports',
  /** column name */
  Id = 'id',
  /** column name */
  Limit = 'limit',
  /** column name */
  Name = 'name',
  /** column name */
  OedId = 'oed_id',
  /** column name */
  Price = 'price',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Views = 'views'
}

/** select "plan_aggregate_bool_exp_bool_and_arguments_columns" columns of table "plan" */
export enum Plan_Select_Column_Plan_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** select "plan_aggregate_bool_exp_bool_or_arguments_columns" columns of table "plan" */
export enum Plan_Select_Column_Plan_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** input type for updating data in table "plan" */
export type Plan_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  amount?: InputMaybe<Scalars['Int']['input']>;
  config_plan_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  exports?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  oed_id?: InputMaybe<Scalars['uuid']['input']>;
  price?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  views?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type Plan_Stddev_Fields = {
  __typename?: 'plan_stddev_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  exports?: Maybe<Scalars['Float']['output']>;
  limit?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  views?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "plan" */
export type Plan_Stddev_Order_By = {
  amount?: InputMaybe<Order_By>;
  exports?: InputMaybe<Order_By>;
  limit?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  views?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Plan_Stddev_Pop_Fields = {
  __typename?: 'plan_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  exports?: Maybe<Scalars['Float']['output']>;
  limit?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  views?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "plan" */
export type Plan_Stddev_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
  exports?: InputMaybe<Order_By>;
  limit?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  views?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Plan_Stddev_Samp_Fields = {
  __typename?: 'plan_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  exports?: Maybe<Scalars['Float']['output']>;
  limit?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  views?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "plan" */
export type Plan_Stddev_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
  exports?: InputMaybe<Order_By>;
  limit?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  views?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "plan" */
export type Plan_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Plan_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Plan_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  amount?: InputMaybe<Scalars['Int']['input']>;
  config_plan_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  exports?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  oed_id?: InputMaybe<Scalars['uuid']['input']>;
  price?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  views?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type Plan_Sum_Fields = {
  __typename?: 'plan_sum_fields';
  amount?: Maybe<Scalars['Int']['output']>;
  exports?: Maybe<Scalars['Int']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  price?: Maybe<Scalars['Int']['output']>;
  views?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "plan" */
export type Plan_Sum_Order_By = {
  amount?: InputMaybe<Order_By>;
  exports?: InputMaybe<Order_By>;
  limit?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  views?: InputMaybe<Order_By>;
};

/** update columns of table "plan" */
export enum Plan_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  Amount = 'amount',
  /** column name */
  ConfigPlanId = 'config_plan_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Exports = 'exports',
  /** column name */
  Id = 'id',
  /** column name */
  Limit = 'limit',
  /** column name */
  Name = 'name',
  /** column name */
  OedId = 'oed_id',
  /** column name */
  Price = 'price',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Views = 'views'
}

export type Plan_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Plan_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Plan_Set_Input>;
  /** filter the rows which have to be updated */
  where: Plan_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Plan_Var_Pop_Fields = {
  __typename?: 'plan_var_pop_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  exports?: Maybe<Scalars['Float']['output']>;
  limit?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  views?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "plan" */
export type Plan_Var_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
  exports?: InputMaybe<Order_By>;
  limit?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  views?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Plan_Var_Samp_Fields = {
  __typename?: 'plan_var_samp_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  exports?: Maybe<Scalars['Float']['output']>;
  limit?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  views?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "plan" */
export type Plan_Var_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
  exports?: InputMaybe<Order_By>;
  limit?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  views?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Plan_Variance_Fields = {
  __typename?: 'plan_variance_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  exports?: Maybe<Scalars['Float']['output']>;
  limit?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  views?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "plan" */
export type Plan_Variance_Order_By = {
  amount?: InputMaybe<Order_By>;
  exports?: InputMaybe<Order_By>;
  limit?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  views?: InputMaybe<Order_By>;
};

export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "address" */
  address: Array<Address>;
  /** fetch aggregated fields from the table: "address" */
  address_aggregate: Address_Aggregate;
  /** fetch data from the table: "address" using primary key columns */
  address_by_pk?: Maybe<Address>;
  /** fetch data from the table: "billing_address" */
  billing_address: Array<Billing_Address>;
  /** fetch aggregated fields from the table: "billing_address" */
  billing_address_aggregate: Billing_Address_Aggregate;
  /** fetch data from the table: "billing_address" using primary key columns */
  billing_address_by_pk?: Maybe<Billing_Address>;
  /** fetch data from the table: "buyer" */
  buyer: Array<Buyer>;
  /** fetch aggregated fields from the table: "buyer" */
  buyer_aggregate: Buyer_Aggregate;
  /** fetch data from the table: "buyer" using primary key columns */
  buyer_by_pk?: Maybe<Buyer>;
  /** fetch data from the table: "config_oed" */
  config_oed: Array<Config_Oed>;
  /** fetch aggregated fields from the table: "config_oed" */
  config_oed_aggregate: Config_Oed_Aggregate;
  /** fetch data from the table: "config_oed" using primary key columns */
  config_oed_by_pk?: Maybe<Config_Oed>;
  /** fetch data from the table: "config_plan" */
  config_plan: Array<Config_Plan>;
  /** fetch aggregated fields from the table: "config_plan" */
  config_plan_aggregate: Config_Plan_Aggregate;
  /** fetch data from the table: "config_plan" using primary key columns */
  config_plan_by_pk?: Maybe<Config_Plan>;
  /** fetch data from the table: "event" */
  event: Array<Event>;
  /** fetch aggregated fields from the table: "event" */
  event_aggregate: Event_Aggregate;
  /** fetch data from the table: "event" using primary key columns */
  event_by_pk?: Maybe<Event>;
  /** fetch data from the table: "exclusive_oed" */
  exclusive_oed: Array<Exclusive_Oed>;
  /** fetch aggregated fields from the table: "exclusive_oed" */
  exclusive_oed_aggregate: Exclusive_Oed_Aggregate;
  /** fetch data from the table: "library" */
  library: Array<Library>;
  /** fetch aggregated fields from the table: "library" */
  library_aggregate: Library_Aggregate;
  /** fetch data from the table: "library" using primary key columns */
  library_by_pk?: Maybe<Library>;
  /** fetch data from the table: "login" */
  login: Array<Login>;
  /** fetch aggregated fields from the table: "login" */
  login_aggregate: Login_Aggregate;
  /** fetch data from the table: "login" using primary key columns */
  login_by_pk?: Maybe<Login>;
  /** An array relationship */
  manager_teams: Array<Manager_Teams>;
  /** An aggregate relationship */
  manager_teams_aggregate: Manager_Teams_Aggregate;
  /** fetch data from the table: "manager_teams" using primary key columns */
  manager_teams_by_pk?: Maybe<Manager_Teams>;
  /** fetch data from the table: "oed" */
  oed: Array<Oed>;
  /** fetch aggregated fields from the table: "oed" */
  oed_aggregate: Oed_Aggregate;
  /** fetch data from the table: "oed" using primary key columns */
  oed_by_pk?: Maybe<Oed>;
  /** fetch data from the table: "oed_template" */
  oed_template: Array<Oed_Template>;
  /** fetch aggregated fields from the table: "oed_template" */
  oed_template_aggregate: Oed_Template_Aggregate;
  /** fetch data from the table: "plan" */
  plan: Array<Plan>;
  /** fetch aggregated fields from the table: "plan" */
  plan_aggregate: Plan_Aggregate;
  /** fetch data from the table: "plan" using primary key columns */
  plan_by_pk?: Maybe<Plan>;
  /** fetch data from the table: "plan_oed" */
  plan_oed: Array<Plan_Oed>;
  /** fetch aggregated fields from the table: "plan_oed" */
  plan_oed_aggregate: Plan_Oed_Aggregate;
  /** fetch data from the table: "plan_oed" using primary key columns */
  plan_oed_by_pk?: Maybe<Plan_Oed>;
  /** fetch data from the table: "report" */
  report: Array<Report>;
  /** fetch aggregated fields from the table: "report" */
  report_aggregate: Report_Aggregate;
  /** fetch data from the table: "report" using primary key columns */
  report_by_pk?: Maybe<Report>;
  /** fetch data from the table: "role" */
  role: Array<Role>;
  /** fetch aggregated fields from the table: "role" */
  role_aggregate: Role_Aggregate;
  /** fetch data from the table: "role" using primary key columns */
  role_by_pk?: Maybe<Role>;
  /** An array relationship */
  role_default_metadata: Array<Role_Default_Metadata>;
  /** An aggregate relationship */
  role_default_metadata_aggregate: Role_Default_Metadata_Aggregate;
  /** fetch data from the table: "role_default_metadata" using primary key columns */
  role_default_metadata_by_pk?: Maybe<Role_Default_Metadata>;
  /** fetch data from the table: "role_oed_exclusive" */
  role_oed_exclusive: Array<Role_Oed_Exclusive>;
  /** fetch aggregated fields from the table: "role_oed_exclusive" */
  role_oed_exclusive_aggregate: Role_Oed_Exclusive_Aggregate;
  /** fetch data from the table: "status_oed" */
  status_oed: Array<Status_Oed>;
  /** fetch aggregated fields from the table: "status_oed" */
  status_oed_aggregate: Status_Oed_Aggregate;
  /** fetch data from the table: "status_oed" using primary key columns */
  status_oed_by_pk?: Maybe<Status_Oed>;
  /** fetch data from the table: "status_oed_thumbnail" */
  status_oed_thumbnail: Array<Status_Oed_Thumbnail>;
  /** fetch aggregated fields from the table: "status_oed_thumbnail" */
  status_oed_thumbnail_aggregate: Status_Oed_Thumbnail_Aggregate;
  /** fetch data from the table: "status_oed_thumbnail" using primary key columns */
  status_oed_thumbnail_by_pk?: Maybe<Status_Oed_Thumbnail>;
  /** fetch data from the table: "storage" */
  storage: Array<Storage>;
  /** fetch aggregated fields from the table: "storage" */
  storage_aggregate: Storage_Aggregate;
  /** fetch data from the table: "storage" using primary key columns */
  storage_by_pk?: Maybe<Storage>;
  /** fetch data from the table: "storage_oed" */
  storage_oed: Array<Storage_Oed>;
  /** fetch aggregated fields from the table: "storage_oed" */
  storage_oed_aggregate: Storage_Oed_Aggregate;
  /** fetch data from the table: "storage_oed" using primary key columns */
  storage_oed_by_pk?: Maybe<Storage_Oed>;
  /** fetch data from the table: "team" */
  team: Array<Team>;
  /** fetch aggregated fields from the table: "team" */
  team_aggregate: Team_Aggregate;
  /** fetch data from the table: "team" using primary key columns */
  team_by_pk?: Maybe<Team>;
  /** fetch data from the table: "temp_user_oed" */
  temp_user_oed: Array<Temp_User_Oed>;
  /** fetch aggregated fields from the table: "temp_user_oed" */
  temp_user_oed_aggregate: Temp_User_Oed_Aggregate;
  /** fetch data from the table: "user" */
  user: Array<User>;
  /** fetch aggregated fields from the table: "user" */
  user_aggregate: User_Aggregate;
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<User>;
  /** fetch data from the table: "user_role" */
  user_role: Array<User_Role>;
  /** fetch aggregated fields from the table: "user_role" */
  user_role_aggregate: User_Role_Aggregate;
  /** fetch data from the table: "user_role" using primary key columns */
  user_role_by_pk?: Maybe<User_Role>;
  /** fetch data from the table: "user_wallet" */
  user_wallet: Array<User_Wallet>;
  /** fetch aggregated fields from the table: "user_wallet" */
  user_wallet_aggregate: User_Wallet_Aggregate;
  /** fetch data from the table: "user_wallet" using primary key columns */
  user_wallet_by_pk?: Maybe<User_Wallet>;
  /** An array relationship */
  users_teams: Array<Users_Teams>;
  /** An aggregate relationship */
  users_teams_aggregate: Users_Teams_Aggregate;
  /** fetch data from the table: "users_teams" using primary key columns */
  users_teams_by_pk?: Maybe<Users_Teams>;
};


export type Query_RootAddressArgs = {
  distinct_on?: InputMaybe<Array<Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Address_Order_By>>;
  where?: InputMaybe<Address_Bool_Exp>;
};


export type Query_RootAddress_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Address_Order_By>>;
  where?: InputMaybe<Address_Bool_Exp>;
};


export type Query_RootAddress_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootBilling_AddressArgs = {
  distinct_on?: InputMaybe<Array<Billing_Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Billing_Address_Order_By>>;
  where?: InputMaybe<Billing_Address_Bool_Exp>;
};


export type Query_RootBilling_Address_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Billing_Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Billing_Address_Order_By>>;
  where?: InputMaybe<Billing_Address_Bool_Exp>;
};


export type Query_RootBilling_Address_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootBuyerArgs = {
  distinct_on?: InputMaybe<Array<Buyer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Buyer_Order_By>>;
  where?: InputMaybe<Buyer_Bool_Exp>;
};


export type Query_RootBuyer_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Buyer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Buyer_Order_By>>;
  where?: InputMaybe<Buyer_Bool_Exp>;
};


export type Query_RootBuyer_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootConfig_OedArgs = {
  distinct_on?: InputMaybe<Array<Config_Oed_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Config_Oed_Order_By>>;
  where?: InputMaybe<Config_Oed_Bool_Exp>;
};


export type Query_RootConfig_Oed_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Config_Oed_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Config_Oed_Order_By>>;
  where?: InputMaybe<Config_Oed_Bool_Exp>;
};


export type Query_RootConfig_Oed_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootConfig_PlanArgs = {
  distinct_on?: InputMaybe<Array<Config_Plan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Config_Plan_Order_By>>;
  where?: InputMaybe<Config_Plan_Bool_Exp>;
};


export type Query_RootConfig_Plan_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Config_Plan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Config_Plan_Order_By>>;
  where?: InputMaybe<Config_Plan_Bool_Exp>;
};


export type Query_RootConfig_Plan_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootEventArgs = {
  distinct_on?: InputMaybe<Array<Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Event_Order_By>>;
  where?: InputMaybe<Event_Bool_Exp>;
};


export type Query_RootEvent_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Event_Order_By>>;
  where?: InputMaybe<Event_Bool_Exp>;
};


export type Query_RootEvent_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootExclusive_OedArgs = {
  distinct_on?: InputMaybe<Array<Exclusive_Oed_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Exclusive_Oed_Order_By>>;
  where?: InputMaybe<Exclusive_Oed_Bool_Exp>;
};


export type Query_RootExclusive_Oed_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Exclusive_Oed_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Exclusive_Oed_Order_By>>;
  where?: InputMaybe<Exclusive_Oed_Bool_Exp>;
};


export type Query_RootLibraryArgs = {
  distinct_on?: InputMaybe<Array<Library_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Library_Order_By>>;
  where?: InputMaybe<Library_Bool_Exp>;
};


export type Query_RootLibrary_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Library_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Library_Order_By>>;
  where?: InputMaybe<Library_Bool_Exp>;
};


export type Query_RootLibrary_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootLoginArgs = {
  distinct_on?: InputMaybe<Array<Login_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Login_Order_By>>;
  where?: InputMaybe<Login_Bool_Exp>;
};


export type Query_RootLogin_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Login_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Login_Order_By>>;
  where?: InputMaybe<Login_Bool_Exp>;
};


export type Query_RootLogin_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootManager_TeamsArgs = {
  distinct_on?: InputMaybe<Array<Manager_Teams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Manager_Teams_Order_By>>;
  where?: InputMaybe<Manager_Teams_Bool_Exp>;
};


export type Query_RootManager_Teams_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Manager_Teams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Manager_Teams_Order_By>>;
  where?: InputMaybe<Manager_Teams_Bool_Exp>;
};


export type Query_RootManager_Teams_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootOedArgs = {
  distinct_on?: InputMaybe<Array<Oed_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Oed_Order_By>>;
  where?: InputMaybe<Oed_Bool_Exp>;
};


export type Query_RootOed_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Oed_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Oed_Order_By>>;
  where?: InputMaybe<Oed_Bool_Exp>;
};


export type Query_RootOed_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootOed_TemplateArgs = {
  distinct_on?: InputMaybe<Array<Oed_Template_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Oed_Template_Order_By>>;
  where?: InputMaybe<Oed_Template_Bool_Exp>;
};


export type Query_RootOed_Template_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Oed_Template_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Oed_Template_Order_By>>;
  where?: InputMaybe<Oed_Template_Bool_Exp>;
};


export type Query_RootPlanArgs = {
  distinct_on?: InputMaybe<Array<Plan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Plan_Order_By>>;
  where?: InputMaybe<Plan_Bool_Exp>;
};


export type Query_RootPlan_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Plan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Plan_Order_By>>;
  where?: InputMaybe<Plan_Bool_Exp>;
};


export type Query_RootPlan_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootPlan_OedArgs = {
  distinct_on?: InputMaybe<Array<Plan_Oed_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Plan_Oed_Order_By>>;
  where?: InputMaybe<Plan_Oed_Bool_Exp>;
};


export type Query_RootPlan_Oed_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Plan_Oed_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Plan_Oed_Order_By>>;
  where?: InputMaybe<Plan_Oed_Bool_Exp>;
};


export type Query_RootPlan_Oed_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootReportArgs = {
  distinct_on?: InputMaybe<Array<Report_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Report_Order_By>>;
  where?: InputMaybe<Report_Bool_Exp>;
};


export type Query_RootReport_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Report_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Report_Order_By>>;
  where?: InputMaybe<Report_Bool_Exp>;
};


export type Query_RootReport_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootRoleArgs = {
  distinct_on?: InputMaybe<Array<Role_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Role_Order_By>>;
  where?: InputMaybe<Role_Bool_Exp>;
};


export type Query_RootRole_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Role_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Role_Order_By>>;
  where?: InputMaybe<Role_Bool_Exp>;
};


export type Query_RootRole_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootRole_Default_MetadataArgs = {
  distinct_on?: InputMaybe<Array<Role_Default_Metadata_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Role_Default_Metadata_Order_By>>;
  where?: InputMaybe<Role_Default_Metadata_Bool_Exp>;
};


export type Query_RootRole_Default_Metadata_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Role_Default_Metadata_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Role_Default_Metadata_Order_By>>;
  where?: InputMaybe<Role_Default_Metadata_Bool_Exp>;
};


export type Query_RootRole_Default_Metadata_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootRole_Oed_ExclusiveArgs = {
  distinct_on?: InputMaybe<Array<Role_Oed_Exclusive_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Role_Oed_Exclusive_Order_By>>;
  where?: InputMaybe<Role_Oed_Exclusive_Bool_Exp>;
};


export type Query_RootRole_Oed_Exclusive_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Role_Oed_Exclusive_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Role_Oed_Exclusive_Order_By>>;
  where?: InputMaybe<Role_Oed_Exclusive_Bool_Exp>;
};


export type Query_RootStatus_OedArgs = {
  distinct_on?: InputMaybe<Array<Status_Oed_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Status_Oed_Order_By>>;
  where?: InputMaybe<Status_Oed_Bool_Exp>;
};


export type Query_RootStatus_Oed_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Status_Oed_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Status_Oed_Order_By>>;
  where?: InputMaybe<Status_Oed_Bool_Exp>;
};


export type Query_RootStatus_Oed_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Query_RootStatus_Oed_ThumbnailArgs = {
  distinct_on?: InputMaybe<Array<Status_Oed_Thumbnail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Status_Oed_Thumbnail_Order_By>>;
  where?: InputMaybe<Status_Oed_Thumbnail_Bool_Exp>;
};


export type Query_RootStatus_Oed_Thumbnail_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Status_Oed_Thumbnail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Status_Oed_Thumbnail_Order_By>>;
  where?: InputMaybe<Status_Oed_Thumbnail_Bool_Exp>;
};


export type Query_RootStatus_Oed_Thumbnail_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Query_RootStorageArgs = {
  distinct_on?: InputMaybe<Array<Storage_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Storage_Order_By>>;
  where?: InputMaybe<Storage_Bool_Exp>;
};


export type Query_RootStorage_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Storage_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Storage_Order_By>>;
  where?: InputMaybe<Storage_Bool_Exp>;
};


export type Query_RootStorage_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootStorage_OedArgs = {
  distinct_on?: InputMaybe<Array<Storage_Oed_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Storage_Oed_Order_By>>;
  where?: InputMaybe<Storage_Oed_Bool_Exp>;
};


export type Query_RootStorage_Oed_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Storage_Oed_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Storage_Oed_Order_By>>;
  where?: InputMaybe<Storage_Oed_Bool_Exp>;
};


export type Query_RootStorage_Oed_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootTeamArgs = {
  distinct_on?: InputMaybe<Array<Team_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Team_Order_By>>;
  where?: InputMaybe<Team_Bool_Exp>;
};


export type Query_RootTeam_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Team_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Team_Order_By>>;
  where?: InputMaybe<Team_Bool_Exp>;
};


export type Query_RootTeam_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootTemp_User_OedArgs = {
  distinct_on?: InputMaybe<Array<Temp_User_Oed_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Temp_User_Oed_Order_By>>;
  where?: InputMaybe<Temp_User_Oed_Bool_Exp>;
};


export type Query_RootTemp_User_Oed_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Temp_User_Oed_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Temp_User_Oed_Order_By>>;
  where?: InputMaybe<Temp_User_Oed_Bool_Exp>;
};


export type Query_RootUserArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type Query_RootUser_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type Query_RootUser_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootUser_RoleArgs = {
  distinct_on?: InputMaybe<Array<User_Role_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Role_Order_By>>;
  where?: InputMaybe<User_Role_Bool_Exp>;
};


export type Query_RootUser_Role_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Role_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Role_Order_By>>;
  where?: InputMaybe<User_Role_Bool_Exp>;
};


export type Query_RootUser_Role_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootUser_WalletArgs = {
  distinct_on?: InputMaybe<Array<User_Wallet_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Wallet_Order_By>>;
  where?: InputMaybe<User_Wallet_Bool_Exp>;
};


export type Query_RootUser_Wallet_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Wallet_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Wallet_Order_By>>;
  where?: InputMaybe<User_Wallet_Bool_Exp>;
};


export type Query_RootUser_Wallet_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootUsers_TeamsArgs = {
  distinct_on?: InputMaybe<Array<Users_Teams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Teams_Order_By>>;
  where?: InputMaybe<Users_Teams_Bool_Exp>;
};


export type Query_RootUsers_Teams_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Teams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Teams_Order_By>>;
  where?: InputMaybe<Users_Teams_Bool_Exp>;
};


export type Query_RootUsers_Teams_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** columns and relationships of "report" */
export type Report = {
  __typename?: 'report';
  active?: Maybe<Scalars['Boolean']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  data?: Maybe<Scalars['jsonb']['output']>;
  id: Scalars['uuid']['output'];
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};


/** columns and relationships of "report" */
export type ReportDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "report" */
export type Report_Aggregate = {
  __typename?: 'report_aggregate';
  aggregate?: Maybe<Report_Aggregate_Fields>;
  nodes: Array<Report>;
};

/** aggregate fields of "report" */
export type Report_Aggregate_Fields = {
  __typename?: 'report_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Report_Max_Fields>;
  min?: Maybe<Report_Min_Fields>;
};


/** aggregate fields of "report" */
export type Report_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Report_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Report_Append_Input = {
  data?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "report". All fields are combined with a logical 'AND'. */
export type Report_Bool_Exp = {
  _and?: InputMaybe<Array<Report_Bool_Exp>>;
  _not?: InputMaybe<Report_Bool_Exp>;
  _or?: InputMaybe<Array<Report_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  data?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "report" */
export enum Report_Constraint {
  /** unique or primary key constraint on columns "id" */
  ReportPkey = 'report_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Report_Delete_At_Path_Input = {
  data?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Report_Delete_Elem_Input = {
  data?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Report_Delete_Key_Input = {
  data?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "report" */
export type Report_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  data?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Report_Max_Fields = {
  __typename?: 'report_max_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type Report_Min_Fields = {
  __typename?: 'report_min_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "report" */
export type Report_Mutation_Response = {
  __typename?: 'report_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Report>;
};

/** on_conflict condition type for table "report" */
export type Report_On_Conflict = {
  constraint: Report_Constraint;
  update_columns?: Array<Report_Update_Column>;
  where?: InputMaybe<Report_Bool_Exp>;
};

/** Ordering options when selecting data from "report". */
export type Report_Order_By = {
  active?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  data?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: report */
export type Report_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Report_Prepend_Input = {
  data?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "report" */
export enum Report_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "report" */
export type Report_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  data?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "report" */
export type Report_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Report_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Report_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  data?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "report" */
export enum Report_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Report_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Report_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Report_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Report_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Report_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Report_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Report_Set_Input>;
  /** filter the rows which have to be updated */
  where: Report_Bool_Exp;
};

/** columns and relationships of "role" */
export type Role = {
  __typename?: 'role';
  active?: Maybe<Scalars['Boolean']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id: Scalars['uuid']['output'];
  name?: Maybe<Scalars['String']['output']>;
  permission?: Maybe<Scalars['jsonb']['output']>;
  /** An array relationship */
  role_default_metadata: Array<Role_Default_Metadata>;
  /** An aggregate relationship */
  role_default_metadata_aggregate: Role_Default_Metadata_Aggregate;
  /** An array relationship */
  role_oed_exclusives: Array<Role_Oed_Exclusive>;
  /** An aggregate relationship */
  role_oed_exclusives_aggregate: Role_Oed_Exclusive_Aggregate;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  /** An array relationship */
  user_roles: Array<User_Role>;
  /** An aggregate relationship */
  user_roles_aggregate: User_Role_Aggregate;
  /** An array relationship */
  users_teams: Array<Users_Teams>;
  /** An aggregate relationship */
  users_teams_aggregate: Users_Teams_Aggregate;
};


/** columns and relationships of "role" */
export type RolePermissionArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "role" */
export type RoleRole_Default_MetadataArgs = {
  distinct_on?: InputMaybe<Array<Role_Default_Metadata_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Role_Default_Metadata_Order_By>>;
  where?: InputMaybe<Role_Default_Metadata_Bool_Exp>;
};


/** columns and relationships of "role" */
export type RoleRole_Default_Metadata_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Role_Default_Metadata_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Role_Default_Metadata_Order_By>>;
  where?: InputMaybe<Role_Default_Metadata_Bool_Exp>;
};


/** columns and relationships of "role" */
export type RoleRole_Oed_ExclusivesArgs = {
  distinct_on?: InputMaybe<Array<Role_Oed_Exclusive_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Role_Oed_Exclusive_Order_By>>;
  where?: InputMaybe<Role_Oed_Exclusive_Bool_Exp>;
};


/** columns and relationships of "role" */
export type RoleRole_Oed_Exclusives_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Role_Oed_Exclusive_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Role_Oed_Exclusive_Order_By>>;
  where?: InputMaybe<Role_Oed_Exclusive_Bool_Exp>;
};


/** columns and relationships of "role" */
export type RoleUser_RolesArgs = {
  distinct_on?: InputMaybe<Array<User_Role_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Role_Order_By>>;
  where?: InputMaybe<User_Role_Bool_Exp>;
};


/** columns and relationships of "role" */
export type RoleUser_Roles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Role_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Role_Order_By>>;
  where?: InputMaybe<User_Role_Bool_Exp>;
};


/** columns and relationships of "role" */
export type RoleUsers_TeamsArgs = {
  distinct_on?: InputMaybe<Array<Users_Teams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Teams_Order_By>>;
  where?: InputMaybe<Users_Teams_Bool_Exp>;
};


/** columns and relationships of "role" */
export type RoleUsers_Teams_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Teams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Teams_Order_By>>;
  where?: InputMaybe<Users_Teams_Bool_Exp>;
};

/** aggregated selection of "role" */
export type Role_Aggregate = {
  __typename?: 'role_aggregate';
  aggregate?: Maybe<Role_Aggregate_Fields>;
  nodes: Array<Role>;
};

/** aggregate fields of "role" */
export type Role_Aggregate_Fields = {
  __typename?: 'role_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Role_Max_Fields>;
  min?: Maybe<Role_Min_Fields>;
};


/** aggregate fields of "role" */
export type Role_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Role_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Role_Append_Input = {
  permission?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "role". All fields are combined with a logical 'AND'. */
export type Role_Bool_Exp = {
  _and?: InputMaybe<Array<Role_Bool_Exp>>;
  _not?: InputMaybe<Role_Bool_Exp>;
  _or?: InputMaybe<Array<Role_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  permission?: InputMaybe<Jsonb_Comparison_Exp>;
  role_default_metadata?: InputMaybe<Role_Default_Metadata_Bool_Exp>;
  role_default_metadata_aggregate?: InputMaybe<Role_Default_Metadata_Aggregate_Bool_Exp>;
  role_oed_exclusives?: InputMaybe<Role_Oed_Exclusive_Bool_Exp>;
  role_oed_exclusives_aggregate?: InputMaybe<Role_Oed_Exclusive_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user_roles?: InputMaybe<User_Role_Bool_Exp>;
  user_roles_aggregate?: InputMaybe<User_Role_Aggregate_Bool_Exp>;
  users_teams?: InputMaybe<Users_Teams_Bool_Exp>;
  users_teams_aggregate?: InputMaybe<Users_Teams_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "role" */
export enum Role_Constraint {
  /** unique or primary key constraint on columns "id" */
  RolesPkey = 'roles_pkey'
}

/** columns and relationships of "role_default_metadata" */
export type Role_Default_Metadata = {
  __typename?: 'role_default_metadata';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  metadatas: Array<Scalars['String']['output']>;
  required_metadatas: Array<Scalars['String']['output']>;
  /** An object relationship */
  role: Role;
  role_id: Scalars['uuid']['output'];
  updated_at: Scalars['timestamptz']['output'];
};

/** aggregated selection of "role_default_metadata" */
export type Role_Default_Metadata_Aggregate = {
  __typename?: 'role_default_metadata_aggregate';
  aggregate?: Maybe<Role_Default_Metadata_Aggregate_Fields>;
  nodes: Array<Role_Default_Metadata>;
};

export type Role_Default_Metadata_Aggregate_Bool_Exp = {
  count?: InputMaybe<Role_Default_Metadata_Aggregate_Bool_Exp_Count>;
};

export type Role_Default_Metadata_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Role_Default_Metadata_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Role_Default_Metadata_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "role_default_metadata" */
export type Role_Default_Metadata_Aggregate_Fields = {
  __typename?: 'role_default_metadata_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Role_Default_Metadata_Max_Fields>;
  min?: Maybe<Role_Default_Metadata_Min_Fields>;
};


/** aggregate fields of "role_default_metadata" */
export type Role_Default_Metadata_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Role_Default_Metadata_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "role_default_metadata" */
export type Role_Default_Metadata_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Role_Default_Metadata_Max_Order_By>;
  min?: InputMaybe<Role_Default_Metadata_Min_Order_By>;
};

/** input type for inserting array relation for remote table "role_default_metadata" */
export type Role_Default_Metadata_Arr_Rel_Insert_Input = {
  data: Array<Role_Default_Metadata_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Role_Default_Metadata_On_Conflict>;
};

/** Boolean expression to filter rows from the table "role_default_metadata". All fields are combined with a logical 'AND'. */
export type Role_Default_Metadata_Bool_Exp = {
  _and?: InputMaybe<Array<Role_Default_Metadata_Bool_Exp>>;
  _not?: InputMaybe<Role_Default_Metadata_Bool_Exp>;
  _or?: InputMaybe<Array<Role_Default_Metadata_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  metadatas?: InputMaybe<String_Array_Comparison_Exp>;
  required_metadatas?: InputMaybe<String_Array_Comparison_Exp>;
  role?: InputMaybe<Role_Bool_Exp>;
  role_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "role_default_metadata" */
export enum Role_Default_Metadata_Constraint {
  /** unique or primary key constraint on columns "id" */
  RoleDefaultMetadataPkey = 'role_default_metadata_pkey',
  /** unique or primary key constraint on columns "role_id" */
  RoleDefaultMetadataRoleIdKey = 'role_default_metadata_role_id_key'
}

/** input type for inserting data into table "role_default_metadata" */
export type Role_Default_Metadata_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  metadatas?: InputMaybe<Array<Scalars['String']['input']>>;
  required_metadatas?: InputMaybe<Array<Scalars['String']['input']>>;
  role?: InputMaybe<Role_Obj_Rel_Insert_Input>;
  role_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Role_Default_Metadata_Max_Fields = {
  __typename?: 'role_default_metadata_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  metadatas?: Maybe<Array<Scalars['String']['output']>>;
  required_metadatas?: Maybe<Array<Scalars['String']['output']>>;
  role_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "role_default_metadata" */
export type Role_Default_Metadata_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  metadatas?: InputMaybe<Order_By>;
  required_metadatas?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Role_Default_Metadata_Min_Fields = {
  __typename?: 'role_default_metadata_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  metadatas?: Maybe<Array<Scalars['String']['output']>>;
  required_metadatas?: Maybe<Array<Scalars['String']['output']>>;
  role_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "role_default_metadata" */
export type Role_Default_Metadata_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  metadatas?: InputMaybe<Order_By>;
  required_metadatas?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "role_default_metadata" */
export type Role_Default_Metadata_Mutation_Response = {
  __typename?: 'role_default_metadata_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Role_Default_Metadata>;
};

/** on_conflict condition type for table "role_default_metadata" */
export type Role_Default_Metadata_On_Conflict = {
  constraint: Role_Default_Metadata_Constraint;
  update_columns?: Array<Role_Default_Metadata_Update_Column>;
  where?: InputMaybe<Role_Default_Metadata_Bool_Exp>;
};

/** Ordering options when selecting data from "role_default_metadata". */
export type Role_Default_Metadata_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  metadatas?: InputMaybe<Order_By>;
  required_metadatas?: InputMaybe<Order_By>;
  role?: InputMaybe<Role_Order_By>;
  role_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: role_default_metadata */
export type Role_Default_Metadata_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "role_default_metadata" */
export enum Role_Default_Metadata_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Metadatas = 'metadatas',
  /** column name */
  RequiredMetadatas = 'required_metadatas',
  /** column name */
  RoleId = 'role_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "role_default_metadata" */
export type Role_Default_Metadata_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  metadatas?: InputMaybe<Array<Scalars['String']['input']>>;
  required_metadatas?: InputMaybe<Array<Scalars['String']['input']>>;
  role_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "role_default_metadata" */
export type Role_Default_Metadata_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Role_Default_Metadata_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Role_Default_Metadata_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  metadatas?: InputMaybe<Array<Scalars['String']['input']>>;
  required_metadatas?: InputMaybe<Array<Scalars['String']['input']>>;
  role_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "role_default_metadata" */
export enum Role_Default_Metadata_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Metadatas = 'metadatas',
  /** column name */
  RequiredMetadatas = 'required_metadatas',
  /** column name */
  RoleId = 'role_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Role_Default_Metadata_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Role_Default_Metadata_Set_Input>;
  /** filter the rows which have to be updated */
  where: Role_Default_Metadata_Bool_Exp;
};

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Role_Delete_At_Path_Input = {
  permission?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Role_Delete_Elem_Input = {
  permission?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Role_Delete_Key_Input = {
  permission?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "role" */
export type Role_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  permission?: InputMaybe<Scalars['jsonb']['input']>;
  role_default_metadata?: InputMaybe<Role_Default_Metadata_Arr_Rel_Insert_Input>;
  role_oed_exclusives?: InputMaybe<Role_Oed_Exclusive_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_roles?: InputMaybe<User_Role_Arr_Rel_Insert_Input>;
  users_teams?: InputMaybe<Users_Teams_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Role_Max_Fields = {
  __typename?: 'role_max_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregate min on columns */
export type Role_Min_Fields = {
  __typename?: 'role_min_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** response of any mutation on the table "role" */
export type Role_Mutation_Response = {
  __typename?: 'role_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Role>;
};

/** input type for inserting object relation for remote table "role" */
export type Role_Obj_Rel_Insert_Input = {
  data: Role_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Role_On_Conflict>;
};

/** columns and relationships of "role_oed_exclusive" */
export type Role_Oed_Exclusive = {
  __typename?: 'role_oed_exclusive';
  active?: Maybe<Scalars['Boolean']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  /** An object relationship */
  exclusive_oed?: Maybe<Exclusive_Oed>;
  exclusive_oed_id?: Maybe<Scalars['uuid']['output']>;
  id: Scalars['uuid']['output'];
  /** An object relationship */
  role?: Maybe<Role>;
  role_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregated selection of "role_oed_exclusive" */
export type Role_Oed_Exclusive_Aggregate = {
  __typename?: 'role_oed_exclusive_aggregate';
  aggregate?: Maybe<Role_Oed_Exclusive_Aggregate_Fields>;
  nodes: Array<Role_Oed_Exclusive>;
};

export type Role_Oed_Exclusive_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Role_Oed_Exclusive_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Role_Oed_Exclusive_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Role_Oed_Exclusive_Aggregate_Bool_Exp_Count>;
};

export type Role_Oed_Exclusive_Aggregate_Bool_Exp_Bool_And = {
  arguments: Role_Oed_Exclusive_Select_Column_Role_Oed_Exclusive_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Role_Oed_Exclusive_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Role_Oed_Exclusive_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Role_Oed_Exclusive_Select_Column_Role_Oed_Exclusive_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Role_Oed_Exclusive_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Role_Oed_Exclusive_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Role_Oed_Exclusive_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Role_Oed_Exclusive_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "role_oed_exclusive" */
export type Role_Oed_Exclusive_Aggregate_Fields = {
  __typename?: 'role_oed_exclusive_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Role_Oed_Exclusive_Max_Fields>;
  min?: Maybe<Role_Oed_Exclusive_Min_Fields>;
};


/** aggregate fields of "role_oed_exclusive" */
export type Role_Oed_Exclusive_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Role_Oed_Exclusive_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "role_oed_exclusive" */
export type Role_Oed_Exclusive_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Role_Oed_Exclusive_Max_Order_By>;
  min?: InputMaybe<Role_Oed_Exclusive_Min_Order_By>;
};

/** input type for inserting array relation for remote table "role_oed_exclusive" */
export type Role_Oed_Exclusive_Arr_Rel_Insert_Input = {
  data: Array<Role_Oed_Exclusive_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Role_Oed_Exclusive_On_Conflict>;
};

/** Boolean expression to filter rows from the table "role_oed_exclusive". All fields are combined with a logical 'AND'. */
export type Role_Oed_Exclusive_Bool_Exp = {
  _and?: InputMaybe<Array<Role_Oed_Exclusive_Bool_Exp>>;
  _not?: InputMaybe<Role_Oed_Exclusive_Bool_Exp>;
  _or?: InputMaybe<Array<Role_Oed_Exclusive_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  exclusive_oed?: InputMaybe<Exclusive_Oed_Bool_Exp>;
  exclusive_oed_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  role?: InputMaybe<Role_Bool_Exp>;
  role_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "role_oed_exclusive" */
export enum Role_Oed_Exclusive_Constraint {
  /** unique or primary key constraint on columns "id" */
  RoleTypeOedExclusiveIdIdx = 'role_type_oed_exclusive_id_idx',
  /** unique or primary key constraint on columns "id" */
  RoleTypeOedExclusiveUnique = 'role_type_oed_exclusive_unique'
}

/** input type for inserting data into table "role_oed_exclusive" */
export type Role_Oed_Exclusive_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  exclusive_oed?: InputMaybe<Exclusive_Oed_Obj_Rel_Insert_Input>;
  exclusive_oed_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  role?: InputMaybe<Role_Obj_Rel_Insert_Input>;
  role_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type Role_Oed_Exclusive_Max_Fields = {
  __typename?: 'role_oed_exclusive_max_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  exclusive_oed_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  role_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** order by max() on columns of table "role_oed_exclusive" */
export type Role_Oed_Exclusive_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  exclusive_oed_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Role_Oed_Exclusive_Min_Fields = {
  __typename?: 'role_oed_exclusive_min_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  exclusive_oed_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  role_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** order by min() on columns of table "role_oed_exclusive" */
export type Role_Oed_Exclusive_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  exclusive_oed_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "role_oed_exclusive" */
export type Role_Oed_Exclusive_Mutation_Response = {
  __typename?: 'role_oed_exclusive_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Role_Oed_Exclusive>;
};

/** on_conflict condition type for table "role_oed_exclusive" */
export type Role_Oed_Exclusive_On_Conflict = {
  constraint: Role_Oed_Exclusive_Constraint;
  update_columns?: Array<Role_Oed_Exclusive_Update_Column>;
  where?: InputMaybe<Role_Oed_Exclusive_Bool_Exp>;
};

/** Ordering options when selecting data from "role_oed_exclusive". */
export type Role_Oed_Exclusive_Order_By = {
  active?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  exclusive_oed?: InputMaybe<Exclusive_Oed_Order_By>;
  exclusive_oed_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  role?: InputMaybe<Role_Order_By>;
  role_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** select columns of table "role_oed_exclusive" */
export enum Role_Oed_Exclusive_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExclusiveOedId = 'exclusive_oed_id',
  /** column name */
  Id = 'id',
  /** column name */
  RoleId = 'role_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "role_oed_exclusive_aggregate_bool_exp_bool_and_arguments_columns" columns of table "role_oed_exclusive" */
export enum Role_Oed_Exclusive_Select_Column_Role_Oed_Exclusive_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** select "role_oed_exclusive_aggregate_bool_exp_bool_or_arguments_columns" columns of table "role_oed_exclusive" */
export enum Role_Oed_Exclusive_Select_Column_Role_Oed_Exclusive_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** input type for updating data in table "role_oed_exclusive" */
export type Role_Oed_Exclusive_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  exclusive_oed_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  role_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** Streaming cursor of the table "role_oed_exclusive" */
export type Role_Oed_Exclusive_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Role_Oed_Exclusive_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Role_Oed_Exclusive_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  exclusive_oed_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  role_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** update columns of table "role_oed_exclusive" */
export enum Role_Oed_Exclusive_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExclusiveOedId = 'exclusive_oed_id',
  /** column name */
  Id = 'id',
  /** column name */
  RoleId = 'role_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Role_Oed_Exclusive_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Role_Oed_Exclusive_Set_Input>;
  /** filter the rows which have to be updated */
  where: Role_Oed_Exclusive_Bool_Exp;
};

/** on_conflict condition type for table "role" */
export type Role_On_Conflict = {
  constraint: Role_Constraint;
  update_columns?: Array<Role_Update_Column>;
  where?: InputMaybe<Role_Bool_Exp>;
};

/** Ordering options when selecting data from "role". */
export type Role_Order_By = {
  active?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  permission?: InputMaybe<Order_By>;
  role_default_metadata_aggregate?: InputMaybe<Role_Default_Metadata_Aggregate_Order_By>;
  role_oed_exclusives_aggregate?: InputMaybe<Role_Oed_Exclusive_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_roles_aggregate?: InputMaybe<User_Role_Aggregate_Order_By>;
  users_teams_aggregate?: InputMaybe<Users_Teams_Aggregate_Order_By>;
};

/** primary key columns input for table: role */
export type Role_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Role_Prepend_Input = {
  permission?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "role" */
export enum Role_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Permission = 'permission',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "role" */
export type Role_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  permission?: InputMaybe<Scalars['jsonb']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** Streaming cursor of the table "role" */
export type Role_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Role_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Role_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  permission?: InputMaybe<Scalars['jsonb']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** update columns of table "role" */
export enum Role_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Permission = 'permission',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Role_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Role_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Role_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Role_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Role_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Role_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Role_Set_Input>;
  /** filter the rows which have to be updated */
  where: Role_Bool_Exp;
};

/** columns and relationships of "status_oed" */
export type Status_Oed = {
  __typename?: 'status_oed';
  /** An object relationship */
  event?: Maybe<Event>;
  /** An array relationship */
  events: Array<Event>;
  /** An aggregate relationship */
  events_aggregate: Event_Aggregate;
  value: Scalars['String']['output'];
};


/** columns and relationships of "status_oed" */
export type Status_OedEventsArgs = {
  distinct_on?: InputMaybe<Array<Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Event_Order_By>>;
  where?: InputMaybe<Event_Bool_Exp>;
};


/** columns and relationships of "status_oed" */
export type Status_OedEvents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Event_Order_By>>;
  where?: InputMaybe<Event_Bool_Exp>;
};

/** aggregated selection of "status_oed" */
export type Status_Oed_Aggregate = {
  __typename?: 'status_oed_aggregate';
  aggregate?: Maybe<Status_Oed_Aggregate_Fields>;
  nodes: Array<Status_Oed>;
};

/** aggregate fields of "status_oed" */
export type Status_Oed_Aggregate_Fields = {
  __typename?: 'status_oed_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Status_Oed_Max_Fields>;
  min?: Maybe<Status_Oed_Min_Fields>;
};


/** aggregate fields of "status_oed" */
export type Status_Oed_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Status_Oed_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "status_oed". All fields are combined with a logical 'AND'. */
export type Status_Oed_Bool_Exp = {
  _and?: InputMaybe<Array<Status_Oed_Bool_Exp>>;
  _not?: InputMaybe<Status_Oed_Bool_Exp>;
  _or?: InputMaybe<Array<Status_Oed_Bool_Exp>>;
  event?: InputMaybe<Event_Bool_Exp>;
  events?: InputMaybe<Event_Bool_Exp>;
  events_aggregate?: InputMaybe<Event_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "status_oed" */
export enum Status_Oed_Constraint {
  /** unique or primary key constraint on columns "value" */
  StatusOedPkey = 'status_oed_pkey',
  /** unique or primary key constraint on columns "value" */
  StatusOedUn = 'status_oed_un'
}

export enum Status_Oed_Enum {
  Create = 'create',
  DeletedFailed = 'deleted_failed',
  DeletedStarted = 'deleted_started',
  DeletedSucceed = 'deleted_succeed',
  Edit = 'edit',
  Export = 'export',
  ExportFailed = 'export_failed',
  ExportStarted = 'export_started',
  ExportSucceeded = 'export_succeeded',
  Preview = 'preview'
}

/** Boolean expression to compare columns of type "status_oed_enum". All fields are combined with logical 'AND'. */
export type Status_Oed_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Status_Oed_Enum>;
  _in?: InputMaybe<Array<Status_Oed_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Status_Oed_Enum>;
  _nin?: InputMaybe<Array<Status_Oed_Enum>>;
};

/** input type for inserting data into table "status_oed" */
export type Status_Oed_Insert_Input = {
  event?: InputMaybe<Event_Obj_Rel_Insert_Input>;
  events?: InputMaybe<Event_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Status_Oed_Max_Fields = {
  __typename?: 'status_oed_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Status_Oed_Min_Fields = {
  __typename?: 'status_oed_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "status_oed" */
export type Status_Oed_Mutation_Response = {
  __typename?: 'status_oed_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Status_Oed>;
};

/** input type for inserting object relation for remote table "status_oed" */
export type Status_Oed_Obj_Rel_Insert_Input = {
  data: Status_Oed_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Status_Oed_On_Conflict>;
};

/** on_conflict condition type for table "status_oed" */
export type Status_Oed_On_Conflict = {
  constraint: Status_Oed_Constraint;
  update_columns?: Array<Status_Oed_Update_Column>;
  where?: InputMaybe<Status_Oed_Bool_Exp>;
};

/** Ordering options when selecting data from "status_oed". */
export type Status_Oed_Order_By = {
  event?: InputMaybe<Event_Order_By>;
  events_aggregate?: InputMaybe<Event_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: status_oed */
export type Status_Oed_Pk_Columns_Input = {
  value: Scalars['String']['input'];
};

/** select columns of table "status_oed" */
export enum Status_Oed_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "status_oed" */
export type Status_Oed_Set_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "status_oed" */
export type Status_Oed_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Status_Oed_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Status_Oed_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "status_oed_thumbnail" */
export type Status_Oed_Thumbnail = {
  __typename?: 'status_oed_thumbnail';
  /** An array relationship */
  oeds: Array<Oed>;
  /** An aggregate relationship */
  oeds_aggregate: Oed_Aggregate;
  value: Scalars['String']['output'];
};


/** columns and relationships of "status_oed_thumbnail" */
export type Status_Oed_ThumbnailOedsArgs = {
  distinct_on?: InputMaybe<Array<Oed_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Oed_Order_By>>;
  where?: InputMaybe<Oed_Bool_Exp>;
};


/** columns and relationships of "status_oed_thumbnail" */
export type Status_Oed_ThumbnailOeds_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Oed_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Oed_Order_By>>;
  where?: InputMaybe<Oed_Bool_Exp>;
};

/** aggregated selection of "status_oed_thumbnail" */
export type Status_Oed_Thumbnail_Aggregate = {
  __typename?: 'status_oed_thumbnail_aggregate';
  aggregate?: Maybe<Status_Oed_Thumbnail_Aggregate_Fields>;
  nodes: Array<Status_Oed_Thumbnail>;
};

/** aggregate fields of "status_oed_thumbnail" */
export type Status_Oed_Thumbnail_Aggregate_Fields = {
  __typename?: 'status_oed_thumbnail_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Status_Oed_Thumbnail_Max_Fields>;
  min?: Maybe<Status_Oed_Thumbnail_Min_Fields>;
};


/** aggregate fields of "status_oed_thumbnail" */
export type Status_Oed_Thumbnail_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Status_Oed_Thumbnail_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "status_oed_thumbnail". All fields are combined with a logical 'AND'. */
export type Status_Oed_Thumbnail_Bool_Exp = {
  _and?: InputMaybe<Array<Status_Oed_Thumbnail_Bool_Exp>>;
  _not?: InputMaybe<Status_Oed_Thumbnail_Bool_Exp>;
  _or?: InputMaybe<Array<Status_Oed_Thumbnail_Bool_Exp>>;
  oeds?: InputMaybe<Oed_Bool_Exp>;
  oeds_aggregate?: InputMaybe<Oed_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "status_oed_thumbnail" */
export enum Status_Oed_Thumbnail_Constraint {
  /** unique or primary key constraint on columns "value" */
  StatusOedThumbnailPkey = 'status_oed_thumbnail_pkey'
}

/** input type for inserting data into table "status_oed_thumbnail" */
export type Status_Oed_Thumbnail_Insert_Input = {
  oeds?: InputMaybe<Oed_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Status_Oed_Thumbnail_Max_Fields = {
  __typename?: 'status_oed_thumbnail_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Status_Oed_Thumbnail_Min_Fields = {
  __typename?: 'status_oed_thumbnail_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "status_oed_thumbnail" */
export type Status_Oed_Thumbnail_Mutation_Response = {
  __typename?: 'status_oed_thumbnail_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Status_Oed_Thumbnail>;
};

/** input type for inserting object relation for remote table "status_oed_thumbnail" */
export type Status_Oed_Thumbnail_Obj_Rel_Insert_Input = {
  data: Status_Oed_Thumbnail_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Status_Oed_Thumbnail_On_Conflict>;
};

/** on_conflict condition type for table "status_oed_thumbnail" */
export type Status_Oed_Thumbnail_On_Conflict = {
  constraint: Status_Oed_Thumbnail_Constraint;
  update_columns?: Array<Status_Oed_Thumbnail_Update_Column>;
  where?: InputMaybe<Status_Oed_Thumbnail_Bool_Exp>;
};

/** Ordering options when selecting data from "status_oed_thumbnail". */
export type Status_Oed_Thumbnail_Order_By = {
  oeds_aggregate?: InputMaybe<Oed_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: status_oed_thumbnail */
export type Status_Oed_Thumbnail_Pk_Columns_Input = {
  value: Scalars['String']['input'];
};

/** select columns of table "status_oed_thumbnail" */
export enum Status_Oed_Thumbnail_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "status_oed_thumbnail" */
export type Status_Oed_Thumbnail_Set_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "status_oed_thumbnail" */
export type Status_Oed_Thumbnail_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Status_Oed_Thumbnail_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Status_Oed_Thumbnail_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "status_oed_thumbnail" */
export enum Status_Oed_Thumbnail_Update_Column {
  /** column name */
  Value = 'value'
}

export type Status_Oed_Thumbnail_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Status_Oed_Thumbnail_Set_Input>;
  /** filter the rows which have to be updated */
  where: Status_Oed_Thumbnail_Bool_Exp;
};

/** update columns of table "status_oed" */
export enum Status_Oed_Update_Column {
  /** column name */
  Value = 'value'
}

export type Status_Oed_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Status_Oed_Set_Input>;
  /** filter the rows which have to be updated */
  where: Status_Oed_Bool_Exp;
};

/** columns and relationships of "storage" */
export type Storage = {
  __typename?: 'storage';
  active?: Maybe<Scalars['Boolean']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id: Scalars['uuid']['output'];
  name?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  storage_oeds: Array<Storage_Oed>;
  /** An aggregate relationship */
  storage_oeds_aggregate: Storage_Oed_Aggregate;
  type?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  /** An object relationship */
  user?: Maybe<User>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};


/** columns and relationships of "storage" */
export type StorageStorage_OedsArgs = {
  distinct_on?: InputMaybe<Array<Storage_Oed_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Storage_Oed_Order_By>>;
  where?: InputMaybe<Storage_Oed_Bool_Exp>;
};


/** columns and relationships of "storage" */
export type StorageStorage_Oeds_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Storage_Oed_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Storage_Oed_Order_By>>;
  where?: InputMaybe<Storage_Oed_Bool_Exp>;
};

/** aggregated selection of "storage" */
export type Storage_Aggregate = {
  __typename?: 'storage_aggregate';
  aggregate?: Maybe<Storage_Aggregate_Fields>;
  nodes: Array<Storage>;
};

export type Storage_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Storage_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Storage_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Storage_Aggregate_Bool_Exp_Count>;
};

export type Storage_Aggregate_Bool_Exp_Bool_And = {
  arguments: Storage_Select_Column_Storage_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Storage_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Storage_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Storage_Select_Column_Storage_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Storage_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Storage_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Storage_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Storage_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "storage" */
export type Storage_Aggregate_Fields = {
  __typename?: 'storage_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Storage_Max_Fields>;
  min?: Maybe<Storage_Min_Fields>;
};


/** aggregate fields of "storage" */
export type Storage_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Storage_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "storage" */
export type Storage_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Storage_Max_Order_By>;
  min?: InputMaybe<Storage_Min_Order_By>;
};

/** input type for inserting array relation for remote table "storage" */
export type Storage_Arr_Rel_Insert_Input = {
  data: Array<Storage_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Storage_On_Conflict>;
};

/** Boolean expression to filter rows from the table "storage". All fields are combined with a logical 'AND'. */
export type Storage_Bool_Exp = {
  _and?: InputMaybe<Array<Storage_Bool_Exp>>;
  _not?: InputMaybe<Storage_Bool_Exp>;
  _or?: InputMaybe<Array<Storage_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  storage_oeds?: InputMaybe<Storage_Oed_Bool_Exp>;
  storage_oeds_aggregate?: InputMaybe<Storage_Oed_Aggregate_Bool_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "storage" */
export enum Storage_Constraint {
  /** unique or primary key constraint on columns "id" */
  StorageIdIdx = 'storage_id_idx',
  /** unique or primary key constraint on columns "id" */
  StoragePk = 'storage_pk'
}

/** input type for inserting data into table "storage" */
export type Storage_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  storage_oeds?: InputMaybe<Storage_Oed_Arr_Rel_Insert_Input>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Storage_Max_Fields = {
  __typename?: 'storage_max_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "storage" */
export type Storage_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Storage_Min_Fields = {
  __typename?: 'storage_min_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "storage" */
export type Storage_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "storage" */
export type Storage_Mutation_Response = {
  __typename?: 'storage_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Storage>;
};

/** input type for inserting object relation for remote table "storage" */
export type Storage_Obj_Rel_Insert_Input = {
  data: Storage_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Storage_On_Conflict>;
};

/** columns and relationships of "storage_oed" */
export type Storage_Oed = {
  __typename?: 'storage_oed';
  active?: Maybe<Scalars['Boolean']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id: Scalars['uuid']['output'];
  oed_id?: Maybe<Scalars['uuid']['output']>;
  oed_type?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  storage?: Maybe<Storage>;
  storage_id?: Maybe<Scalars['uuid']['output']>;
  strapi_oed_id?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregated selection of "storage_oed" */
export type Storage_Oed_Aggregate = {
  __typename?: 'storage_oed_aggregate';
  aggregate?: Maybe<Storage_Oed_Aggregate_Fields>;
  nodes: Array<Storage_Oed>;
};

export type Storage_Oed_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Storage_Oed_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Storage_Oed_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Storage_Oed_Aggregate_Bool_Exp_Count>;
};

export type Storage_Oed_Aggregate_Bool_Exp_Bool_And = {
  arguments: Storage_Oed_Select_Column_Storage_Oed_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Storage_Oed_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Storage_Oed_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Storage_Oed_Select_Column_Storage_Oed_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Storage_Oed_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Storage_Oed_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Storage_Oed_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Storage_Oed_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "storage_oed" */
export type Storage_Oed_Aggregate_Fields = {
  __typename?: 'storage_oed_aggregate_fields';
  avg?: Maybe<Storage_Oed_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Storage_Oed_Max_Fields>;
  min?: Maybe<Storage_Oed_Min_Fields>;
  stddev?: Maybe<Storage_Oed_Stddev_Fields>;
  stddev_pop?: Maybe<Storage_Oed_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Storage_Oed_Stddev_Samp_Fields>;
  sum?: Maybe<Storage_Oed_Sum_Fields>;
  var_pop?: Maybe<Storage_Oed_Var_Pop_Fields>;
  var_samp?: Maybe<Storage_Oed_Var_Samp_Fields>;
  variance?: Maybe<Storage_Oed_Variance_Fields>;
};


/** aggregate fields of "storage_oed" */
export type Storage_Oed_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Storage_Oed_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "storage_oed" */
export type Storage_Oed_Aggregate_Order_By = {
  avg?: InputMaybe<Storage_Oed_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Storage_Oed_Max_Order_By>;
  min?: InputMaybe<Storage_Oed_Min_Order_By>;
  stddev?: InputMaybe<Storage_Oed_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Storage_Oed_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Storage_Oed_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Storage_Oed_Sum_Order_By>;
  var_pop?: InputMaybe<Storage_Oed_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Storage_Oed_Var_Samp_Order_By>;
  variance?: InputMaybe<Storage_Oed_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "storage_oed" */
export type Storage_Oed_Arr_Rel_Insert_Input = {
  data: Array<Storage_Oed_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Storage_Oed_On_Conflict>;
};

/** aggregate avg on columns */
export type Storage_Oed_Avg_Fields = {
  __typename?: 'storage_oed_avg_fields';
  strapi_oed_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "storage_oed" */
export type Storage_Oed_Avg_Order_By = {
  strapi_oed_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "storage_oed". All fields are combined with a logical 'AND'. */
export type Storage_Oed_Bool_Exp = {
  _and?: InputMaybe<Array<Storage_Oed_Bool_Exp>>;
  _not?: InputMaybe<Storage_Oed_Bool_Exp>;
  _or?: InputMaybe<Array<Storage_Oed_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  oed_id?: InputMaybe<Uuid_Comparison_Exp>;
  oed_type?: InputMaybe<String_Comparison_Exp>;
  storage?: InputMaybe<Storage_Bool_Exp>;
  storage_id?: InputMaybe<Uuid_Comparison_Exp>;
  strapi_oed_id?: InputMaybe<Int_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "storage_oed" */
export enum Storage_Oed_Constraint {
  /** unique or primary key constraint on columns "id" */
  StorageOedPk = 'storage_oed_pk'
}

/** input type for incrementing numeric columns in table "storage_oed" */
export type Storage_Oed_Inc_Input = {
  strapi_oed_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "storage_oed" */
export type Storage_Oed_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  oed_id?: InputMaybe<Scalars['uuid']['input']>;
  oed_type?: InputMaybe<Scalars['String']['input']>;
  storage?: InputMaybe<Storage_Obj_Rel_Insert_Input>;
  storage_id?: InputMaybe<Scalars['uuid']['input']>;
  strapi_oed_id?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type Storage_Oed_Max_Fields = {
  __typename?: 'storage_oed_max_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  oed_id?: Maybe<Scalars['uuid']['output']>;
  oed_type?: Maybe<Scalars['String']['output']>;
  storage_id?: Maybe<Scalars['uuid']['output']>;
  strapi_oed_id?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** order by max() on columns of table "storage_oed" */
export type Storage_Oed_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  oed_id?: InputMaybe<Order_By>;
  oed_type?: InputMaybe<Order_By>;
  storage_id?: InputMaybe<Order_By>;
  strapi_oed_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Storage_Oed_Min_Fields = {
  __typename?: 'storage_oed_min_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  oed_id?: Maybe<Scalars['uuid']['output']>;
  oed_type?: Maybe<Scalars['String']['output']>;
  storage_id?: Maybe<Scalars['uuid']['output']>;
  strapi_oed_id?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** order by min() on columns of table "storage_oed" */
export type Storage_Oed_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  oed_id?: InputMaybe<Order_By>;
  oed_type?: InputMaybe<Order_By>;
  storage_id?: InputMaybe<Order_By>;
  strapi_oed_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "storage_oed" */
export type Storage_Oed_Mutation_Response = {
  __typename?: 'storage_oed_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Storage_Oed>;
};

/** on_conflict condition type for table "storage_oed" */
export type Storage_Oed_On_Conflict = {
  constraint: Storage_Oed_Constraint;
  update_columns?: Array<Storage_Oed_Update_Column>;
  where?: InputMaybe<Storage_Oed_Bool_Exp>;
};

/** Ordering options when selecting data from "storage_oed". */
export type Storage_Oed_Order_By = {
  active?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  oed_id?: InputMaybe<Order_By>;
  oed_type?: InputMaybe<Order_By>;
  storage?: InputMaybe<Storage_Order_By>;
  storage_id?: InputMaybe<Order_By>;
  strapi_oed_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: storage_oed */
export type Storage_Oed_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "storage_oed" */
export enum Storage_Oed_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  OedId = 'oed_id',
  /** column name */
  OedType = 'oed_type',
  /** column name */
  StorageId = 'storage_id',
  /** column name */
  StrapiOedId = 'strapi_oed_id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "storage_oed_aggregate_bool_exp_bool_and_arguments_columns" columns of table "storage_oed" */
export enum Storage_Oed_Select_Column_Storage_Oed_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** select "storage_oed_aggregate_bool_exp_bool_or_arguments_columns" columns of table "storage_oed" */
export enum Storage_Oed_Select_Column_Storage_Oed_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** input type for updating data in table "storage_oed" */
export type Storage_Oed_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  oed_id?: InputMaybe<Scalars['uuid']['input']>;
  oed_type?: InputMaybe<Scalars['String']['input']>;
  storage_id?: InputMaybe<Scalars['uuid']['input']>;
  strapi_oed_id?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate stddev on columns */
export type Storage_Oed_Stddev_Fields = {
  __typename?: 'storage_oed_stddev_fields';
  strapi_oed_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "storage_oed" */
export type Storage_Oed_Stddev_Order_By = {
  strapi_oed_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Storage_Oed_Stddev_Pop_Fields = {
  __typename?: 'storage_oed_stddev_pop_fields';
  strapi_oed_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "storage_oed" */
export type Storage_Oed_Stddev_Pop_Order_By = {
  strapi_oed_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Storage_Oed_Stddev_Samp_Fields = {
  __typename?: 'storage_oed_stddev_samp_fields';
  strapi_oed_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "storage_oed" */
export type Storage_Oed_Stddev_Samp_Order_By = {
  strapi_oed_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "storage_oed" */
export type Storage_Oed_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Storage_Oed_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Storage_Oed_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  oed_id?: InputMaybe<Scalars['uuid']['input']>;
  oed_type?: InputMaybe<Scalars['String']['input']>;
  storage_id?: InputMaybe<Scalars['uuid']['input']>;
  strapi_oed_id?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate sum on columns */
export type Storage_Oed_Sum_Fields = {
  __typename?: 'storage_oed_sum_fields';
  strapi_oed_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "storage_oed" */
export type Storage_Oed_Sum_Order_By = {
  strapi_oed_id?: InputMaybe<Order_By>;
};

/** update columns of table "storage_oed" */
export enum Storage_Oed_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  OedId = 'oed_id',
  /** column name */
  OedType = 'oed_type',
  /** column name */
  StorageId = 'storage_id',
  /** column name */
  StrapiOedId = 'strapi_oed_id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Storage_Oed_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Storage_Oed_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Storage_Oed_Set_Input>;
  /** filter the rows which have to be updated */
  where: Storage_Oed_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Storage_Oed_Var_Pop_Fields = {
  __typename?: 'storage_oed_var_pop_fields';
  strapi_oed_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "storage_oed" */
export type Storage_Oed_Var_Pop_Order_By = {
  strapi_oed_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Storage_Oed_Var_Samp_Fields = {
  __typename?: 'storage_oed_var_samp_fields';
  strapi_oed_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "storage_oed" */
export type Storage_Oed_Var_Samp_Order_By = {
  strapi_oed_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Storage_Oed_Variance_Fields = {
  __typename?: 'storage_oed_variance_fields';
  strapi_oed_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "storage_oed" */
export type Storage_Oed_Variance_Order_By = {
  strapi_oed_id?: InputMaybe<Order_By>;
};

/** on_conflict condition type for table "storage" */
export type Storage_On_Conflict = {
  constraint: Storage_Constraint;
  update_columns?: Array<Storage_Update_Column>;
  where?: InputMaybe<Storage_Bool_Exp>;
};

/** Ordering options when selecting data from "storage". */
export type Storage_Order_By = {
  active?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  storage_oeds_aggregate?: InputMaybe<Storage_Oed_Aggregate_Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: storage */
export type Storage_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "storage" */
export enum Storage_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** select "storage_aggregate_bool_exp_bool_and_arguments_columns" columns of table "storage" */
export enum Storage_Select_Column_Storage_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** select "storage_aggregate_bool_exp_bool_or_arguments_columns" columns of table "storage" */
export enum Storage_Select_Column_Storage_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** input type for updating data in table "storage" */
export type Storage_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "storage" */
export type Storage_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Storage_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Storage_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "storage" */
export enum Storage_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Storage_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Storage_Set_Input>;
  /** filter the rows which have to be updated */
  where: Storage_Bool_Exp;
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "address" */
  address: Array<Address>;
  /** fetch aggregated fields from the table: "address" */
  address_aggregate: Address_Aggregate;
  /** fetch data from the table: "address" using primary key columns */
  address_by_pk?: Maybe<Address>;
  /** fetch data from the table in a streaming manner: "address" */
  address_stream: Array<Address>;
  /** fetch data from the table: "billing_address" */
  billing_address: Array<Billing_Address>;
  /** fetch aggregated fields from the table: "billing_address" */
  billing_address_aggregate: Billing_Address_Aggregate;
  /** fetch data from the table: "billing_address" using primary key columns */
  billing_address_by_pk?: Maybe<Billing_Address>;
  /** fetch data from the table in a streaming manner: "billing_address" */
  billing_address_stream: Array<Billing_Address>;
  /** fetch data from the table: "buyer" */
  buyer: Array<Buyer>;
  /** fetch aggregated fields from the table: "buyer" */
  buyer_aggregate: Buyer_Aggregate;
  /** fetch data from the table: "buyer" using primary key columns */
  buyer_by_pk?: Maybe<Buyer>;
  /** fetch data from the table in a streaming manner: "buyer" */
  buyer_stream: Array<Buyer>;
  /** fetch data from the table: "config_oed" */
  config_oed: Array<Config_Oed>;
  /** fetch aggregated fields from the table: "config_oed" */
  config_oed_aggregate: Config_Oed_Aggregate;
  /** fetch data from the table: "config_oed" using primary key columns */
  config_oed_by_pk?: Maybe<Config_Oed>;
  /** fetch data from the table in a streaming manner: "config_oed" */
  config_oed_stream: Array<Config_Oed>;
  /** fetch data from the table: "config_plan" */
  config_plan: Array<Config_Plan>;
  /** fetch aggregated fields from the table: "config_plan" */
  config_plan_aggregate: Config_Plan_Aggregate;
  /** fetch data from the table: "config_plan" using primary key columns */
  config_plan_by_pk?: Maybe<Config_Plan>;
  /** fetch data from the table in a streaming manner: "config_plan" */
  config_plan_stream: Array<Config_Plan>;
  /** fetch data from the table: "event" */
  event: Array<Event>;
  /** fetch aggregated fields from the table: "event" */
  event_aggregate: Event_Aggregate;
  /** fetch data from the table: "event" using primary key columns */
  event_by_pk?: Maybe<Event>;
  /** fetch data from the table in a streaming manner: "event" */
  event_stream: Array<Event>;
  /** fetch data from the table: "exclusive_oed" */
  exclusive_oed: Array<Exclusive_Oed>;
  /** fetch aggregated fields from the table: "exclusive_oed" */
  exclusive_oed_aggregate: Exclusive_Oed_Aggregate;
  /** fetch data from the table in a streaming manner: "exclusive_oed" */
  exclusive_oed_stream: Array<Exclusive_Oed>;
  /** fetch data from the table: "library" */
  library: Array<Library>;
  /** fetch aggregated fields from the table: "library" */
  library_aggregate: Library_Aggregate;
  /** fetch data from the table: "library" using primary key columns */
  library_by_pk?: Maybe<Library>;
  /** fetch data from the table in a streaming manner: "library" */
  library_stream: Array<Library>;
  /** fetch data from the table: "login" */
  login: Array<Login>;
  /** fetch aggregated fields from the table: "login" */
  login_aggregate: Login_Aggregate;
  /** fetch data from the table: "login" using primary key columns */
  login_by_pk?: Maybe<Login>;
  /** fetch data from the table in a streaming manner: "login" */
  login_stream: Array<Login>;
  /** An array relationship */
  manager_teams: Array<Manager_Teams>;
  /** An aggregate relationship */
  manager_teams_aggregate: Manager_Teams_Aggregate;
  /** fetch data from the table: "manager_teams" using primary key columns */
  manager_teams_by_pk?: Maybe<Manager_Teams>;
  /** fetch data from the table in a streaming manner: "manager_teams" */
  manager_teams_stream: Array<Manager_Teams>;
  /** fetch data from the table: "oed" */
  oed: Array<Oed>;
  /** fetch aggregated fields from the table: "oed" */
  oed_aggregate: Oed_Aggregate;
  /** fetch data from the table: "oed" using primary key columns */
  oed_by_pk?: Maybe<Oed>;
  /** fetch data from the table in a streaming manner: "oed" */
  oed_stream: Array<Oed>;
  /** fetch data from the table: "oed_template" */
  oed_template: Array<Oed_Template>;
  /** fetch aggregated fields from the table: "oed_template" */
  oed_template_aggregate: Oed_Template_Aggregate;
  /** fetch data from the table in a streaming manner: "oed_template" */
  oed_template_stream: Array<Oed_Template>;
  /** fetch data from the table: "plan" */
  plan: Array<Plan>;
  /** fetch aggregated fields from the table: "plan" */
  plan_aggregate: Plan_Aggregate;
  /** fetch data from the table: "plan" using primary key columns */
  plan_by_pk?: Maybe<Plan>;
  /** fetch data from the table: "plan_oed" */
  plan_oed: Array<Plan_Oed>;
  /** fetch aggregated fields from the table: "plan_oed" */
  plan_oed_aggregate: Plan_Oed_Aggregate;
  /** fetch data from the table: "plan_oed" using primary key columns */
  plan_oed_by_pk?: Maybe<Plan_Oed>;
  /** fetch data from the table in a streaming manner: "plan_oed" */
  plan_oed_stream: Array<Plan_Oed>;
  /** fetch data from the table in a streaming manner: "plan" */
  plan_stream: Array<Plan>;
  /** fetch data from the table: "report" */
  report: Array<Report>;
  /** fetch aggregated fields from the table: "report" */
  report_aggregate: Report_Aggregate;
  /** fetch data from the table: "report" using primary key columns */
  report_by_pk?: Maybe<Report>;
  /** fetch data from the table in a streaming manner: "report" */
  report_stream: Array<Report>;
  /** fetch data from the table: "role" */
  role: Array<Role>;
  /** fetch aggregated fields from the table: "role" */
  role_aggregate: Role_Aggregate;
  /** fetch data from the table: "role" using primary key columns */
  role_by_pk?: Maybe<Role>;
  /** An array relationship */
  role_default_metadata: Array<Role_Default_Metadata>;
  /** An aggregate relationship */
  role_default_metadata_aggregate: Role_Default_Metadata_Aggregate;
  /** fetch data from the table: "role_default_metadata" using primary key columns */
  role_default_metadata_by_pk?: Maybe<Role_Default_Metadata>;
  /** fetch data from the table in a streaming manner: "role_default_metadata" */
  role_default_metadata_stream: Array<Role_Default_Metadata>;
  /** fetch data from the table: "role_oed_exclusive" */
  role_oed_exclusive: Array<Role_Oed_Exclusive>;
  /** fetch aggregated fields from the table: "role_oed_exclusive" */
  role_oed_exclusive_aggregate: Role_Oed_Exclusive_Aggregate;
  /** fetch data from the table in a streaming manner: "role_oed_exclusive" */
  role_oed_exclusive_stream: Array<Role_Oed_Exclusive>;
  /** fetch data from the table in a streaming manner: "role" */
  role_stream: Array<Role>;
  /** fetch data from the table: "status_oed" */
  status_oed: Array<Status_Oed>;
  /** fetch aggregated fields from the table: "status_oed" */
  status_oed_aggregate: Status_Oed_Aggregate;
  /** fetch data from the table: "status_oed" using primary key columns */
  status_oed_by_pk?: Maybe<Status_Oed>;
  /** fetch data from the table in a streaming manner: "status_oed" */
  status_oed_stream: Array<Status_Oed>;
  /** fetch data from the table: "status_oed_thumbnail" */
  status_oed_thumbnail: Array<Status_Oed_Thumbnail>;
  /** fetch aggregated fields from the table: "status_oed_thumbnail" */
  status_oed_thumbnail_aggregate: Status_Oed_Thumbnail_Aggregate;
  /** fetch data from the table: "status_oed_thumbnail" using primary key columns */
  status_oed_thumbnail_by_pk?: Maybe<Status_Oed_Thumbnail>;
  /** fetch data from the table in a streaming manner: "status_oed_thumbnail" */
  status_oed_thumbnail_stream: Array<Status_Oed_Thumbnail>;
  /** fetch data from the table: "storage" */
  storage: Array<Storage>;
  /** fetch aggregated fields from the table: "storage" */
  storage_aggregate: Storage_Aggregate;
  /** fetch data from the table: "storage" using primary key columns */
  storage_by_pk?: Maybe<Storage>;
  /** fetch data from the table: "storage_oed" */
  storage_oed: Array<Storage_Oed>;
  /** fetch aggregated fields from the table: "storage_oed" */
  storage_oed_aggregate: Storage_Oed_Aggregate;
  /** fetch data from the table: "storage_oed" using primary key columns */
  storage_oed_by_pk?: Maybe<Storage_Oed>;
  /** fetch data from the table in a streaming manner: "storage_oed" */
  storage_oed_stream: Array<Storage_Oed>;
  /** fetch data from the table in a streaming manner: "storage" */
  storage_stream: Array<Storage>;
  /** fetch data from the table: "team" */
  team: Array<Team>;
  /** fetch aggregated fields from the table: "team" */
  team_aggregate: Team_Aggregate;
  /** fetch data from the table: "team" using primary key columns */
  team_by_pk?: Maybe<Team>;
  /** fetch data from the table in a streaming manner: "team" */
  team_stream: Array<Team>;
  /** fetch data from the table: "temp_user_oed" */
  temp_user_oed: Array<Temp_User_Oed>;
  /** fetch aggregated fields from the table: "temp_user_oed" */
  temp_user_oed_aggregate: Temp_User_Oed_Aggregate;
  /** fetch data from the table in a streaming manner: "temp_user_oed" */
  temp_user_oed_stream: Array<Temp_User_Oed>;
  /** fetch data from the table: "user" */
  user: Array<User>;
  /** fetch aggregated fields from the table: "user" */
  user_aggregate: User_Aggregate;
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<User>;
  /** fetch data from the table: "user_role" */
  user_role: Array<User_Role>;
  /** fetch aggregated fields from the table: "user_role" */
  user_role_aggregate: User_Role_Aggregate;
  /** fetch data from the table: "user_role" using primary key columns */
  user_role_by_pk?: Maybe<User_Role>;
  /** fetch data from the table in a streaming manner: "user_role" */
  user_role_stream: Array<User_Role>;
  /** fetch data from the table in a streaming manner: "user" */
  user_stream: Array<User>;
  /** fetch data from the table: "user_wallet" */
  user_wallet: Array<User_Wallet>;
  /** fetch aggregated fields from the table: "user_wallet" */
  user_wallet_aggregate: User_Wallet_Aggregate;
  /** fetch data from the table: "user_wallet" using primary key columns */
  user_wallet_by_pk?: Maybe<User_Wallet>;
  /** fetch data from the table in a streaming manner: "user_wallet" */
  user_wallet_stream: Array<User_Wallet>;
  /** An array relationship */
  users_teams: Array<Users_Teams>;
  /** An aggregate relationship */
  users_teams_aggregate: Users_Teams_Aggregate;
  /** fetch data from the table: "users_teams" using primary key columns */
  users_teams_by_pk?: Maybe<Users_Teams>;
  /** fetch data from the table in a streaming manner: "users_teams" */
  users_teams_stream: Array<Users_Teams>;
};


export type Subscription_RootAddressArgs = {
  distinct_on?: InputMaybe<Array<Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Address_Order_By>>;
  where?: InputMaybe<Address_Bool_Exp>;
};


export type Subscription_RootAddress_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Address_Order_By>>;
  where?: InputMaybe<Address_Bool_Exp>;
};


export type Subscription_RootAddress_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootAddress_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Address_Stream_Cursor_Input>>;
  where?: InputMaybe<Address_Bool_Exp>;
};


export type Subscription_RootBilling_AddressArgs = {
  distinct_on?: InputMaybe<Array<Billing_Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Billing_Address_Order_By>>;
  where?: InputMaybe<Billing_Address_Bool_Exp>;
};


export type Subscription_RootBilling_Address_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Billing_Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Billing_Address_Order_By>>;
  where?: InputMaybe<Billing_Address_Bool_Exp>;
};


export type Subscription_RootBilling_Address_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootBilling_Address_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Billing_Address_Stream_Cursor_Input>>;
  where?: InputMaybe<Billing_Address_Bool_Exp>;
};


export type Subscription_RootBuyerArgs = {
  distinct_on?: InputMaybe<Array<Buyer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Buyer_Order_By>>;
  where?: InputMaybe<Buyer_Bool_Exp>;
};


export type Subscription_RootBuyer_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Buyer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Buyer_Order_By>>;
  where?: InputMaybe<Buyer_Bool_Exp>;
};


export type Subscription_RootBuyer_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootBuyer_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Buyer_Stream_Cursor_Input>>;
  where?: InputMaybe<Buyer_Bool_Exp>;
};


export type Subscription_RootConfig_OedArgs = {
  distinct_on?: InputMaybe<Array<Config_Oed_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Config_Oed_Order_By>>;
  where?: InputMaybe<Config_Oed_Bool_Exp>;
};


export type Subscription_RootConfig_Oed_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Config_Oed_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Config_Oed_Order_By>>;
  where?: InputMaybe<Config_Oed_Bool_Exp>;
};


export type Subscription_RootConfig_Oed_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootConfig_Oed_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Config_Oed_Stream_Cursor_Input>>;
  where?: InputMaybe<Config_Oed_Bool_Exp>;
};


export type Subscription_RootConfig_PlanArgs = {
  distinct_on?: InputMaybe<Array<Config_Plan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Config_Plan_Order_By>>;
  where?: InputMaybe<Config_Plan_Bool_Exp>;
};


export type Subscription_RootConfig_Plan_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Config_Plan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Config_Plan_Order_By>>;
  where?: InputMaybe<Config_Plan_Bool_Exp>;
};


export type Subscription_RootConfig_Plan_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootConfig_Plan_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Config_Plan_Stream_Cursor_Input>>;
  where?: InputMaybe<Config_Plan_Bool_Exp>;
};


export type Subscription_RootEventArgs = {
  distinct_on?: InputMaybe<Array<Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Event_Order_By>>;
  where?: InputMaybe<Event_Bool_Exp>;
};


export type Subscription_RootEvent_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Event_Order_By>>;
  where?: InputMaybe<Event_Bool_Exp>;
};


export type Subscription_RootEvent_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootEvent_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Event_Stream_Cursor_Input>>;
  where?: InputMaybe<Event_Bool_Exp>;
};


export type Subscription_RootExclusive_OedArgs = {
  distinct_on?: InputMaybe<Array<Exclusive_Oed_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Exclusive_Oed_Order_By>>;
  where?: InputMaybe<Exclusive_Oed_Bool_Exp>;
};


export type Subscription_RootExclusive_Oed_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Exclusive_Oed_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Exclusive_Oed_Order_By>>;
  where?: InputMaybe<Exclusive_Oed_Bool_Exp>;
};


export type Subscription_RootExclusive_Oed_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Exclusive_Oed_Stream_Cursor_Input>>;
  where?: InputMaybe<Exclusive_Oed_Bool_Exp>;
};


export type Subscription_RootLibraryArgs = {
  distinct_on?: InputMaybe<Array<Library_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Library_Order_By>>;
  where?: InputMaybe<Library_Bool_Exp>;
};


export type Subscription_RootLibrary_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Library_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Library_Order_By>>;
  where?: InputMaybe<Library_Bool_Exp>;
};


export type Subscription_RootLibrary_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootLibrary_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Library_Stream_Cursor_Input>>;
  where?: InputMaybe<Library_Bool_Exp>;
};


export type Subscription_RootLoginArgs = {
  distinct_on?: InputMaybe<Array<Login_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Login_Order_By>>;
  where?: InputMaybe<Login_Bool_Exp>;
};


export type Subscription_RootLogin_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Login_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Login_Order_By>>;
  where?: InputMaybe<Login_Bool_Exp>;
};


export type Subscription_RootLogin_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootLogin_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Login_Stream_Cursor_Input>>;
  where?: InputMaybe<Login_Bool_Exp>;
};


export type Subscription_RootManager_TeamsArgs = {
  distinct_on?: InputMaybe<Array<Manager_Teams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Manager_Teams_Order_By>>;
  where?: InputMaybe<Manager_Teams_Bool_Exp>;
};


export type Subscription_RootManager_Teams_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Manager_Teams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Manager_Teams_Order_By>>;
  where?: InputMaybe<Manager_Teams_Bool_Exp>;
};


export type Subscription_RootManager_Teams_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootManager_Teams_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Manager_Teams_Stream_Cursor_Input>>;
  where?: InputMaybe<Manager_Teams_Bool_Exp>;
};


export type Subscription_RootOedArgs = {
  distinct_on?: InputMaybe<Array<Oed_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Oed_Order_By>>;
  where?: InputMaybe<Oed_Bool_Exp>;
};


export type Subscription_RootOed_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Oed_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Oed_Order_By>>;
  where?: InputMaybe<Oed_Bool_Exp>;
};


export type Subscription_RootOed_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootOed_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Oed_Stream_Cursor_Input>>;
  where?: InputMaybe<Oed_Bool_Exp>;
};


export type Subscription_RootOed_TemplateArgs = {
  distinct_on?: InputMaybe<Array<Oed_Template_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Oed_Template_Order_By>>;
  where?: InputMaybe<Oed_Template_Bool_Exp>;
};


export type Subscription_RootOed_Template_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Oed_Template_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Oed_Template_Order_By>>;
  where?: InputMaybe<Oed_Template_Bool_Exp>;
};


export type Subscription_RootOed_Template_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Oed_Template_Stream_Cursor_Input>>;
  where?: InputMaybe<Oed_Template_Bool_Exp>;
};


export type Subscription_RootPlanArgs = {
  distinct_on?: InputMaybe<Array<Plan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Plan_Order_By>>;
  where?: InputMaybe<Plan_Bool_Exp>;
};


export type Subscription_RootPlan_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Plan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Plan_Order_By>>;
  where?: InputMaybe<Plan_Bool_Exp>;
};


export type Subscription_RootPlan_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootPlan_OedArgs = {
  distinct_on?: InputMaybe<Array<Plan_Oed_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Plan_Oed_Order_By>>;
  where?: InputMaybe<Plan_Oed_Bool_Exp>;
};


export type Subscription_RootPlan_Oed_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Plan_Oed_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Plan_Oed_Order_By>>;
  where?: InputMaybe<Plan_Oed_Bool_Exp>;
};


export type Subscription_RootPlan_Oed_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootPlan_Oed_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Plan_Oed_Stream_Cursor_Input>>;
  where?: InputMaybe<Plan_Oed_Bool_Exp>;
};


export type Subscription_RootPlan_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Plan_Stream_Cursor_Input>>;
  where?: InputMaybe<Plan_Bool_Exp>;
};


export type Subscription_RootReportArgs = {
  distinct_on?: InputMaybe<Array<Report_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Report_Order_By>>;
  where?: InputMaybe<Report_Bool_Exp>;
};


export type Subscription_RootReport_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Report_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Report_Order_By>>;
  where?: InputMaybe<Report_Bool_Exp>;
};


export type Subscription_RootReport_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootReport_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Report_Stream_Cursor_Input>>;
  where?: InputMaybe<Report_Bool_Exp>;
};


export type Subscription_RootRoleArgs = {
  distinct_on?: InputMaybe<Array<Role_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Role_Order_By>>;
  where?: InputMaybe<Role_Bool_Exp>;
};


export type Subscription_RootRole_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Role_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Role_Order_By>>;
  where?: InputMaybe<Role_Bool_Exp>;
};


export type Subscription_RootRole_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootRole_Default_MetadataArgs = {
  distinct_on?: InputMaybe<Array<Role_Default_Metadata_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Role_Default_Metadata_Order_By>>;
  where?: InputMaybe<Role_Default_Metadata_Bool_Exp>;
};


export type Subscription_RootRole_Default_Metadata_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Role_Default_Metadata_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Role_Default_Metadata_Order_By>>;
  where?: InputMaybe<Role_Default_Metadata_Bool_Exp>;
};


export type Subscription_RootRole_Default_Metadata_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootRole_Default_Metadata_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Role_Default_Metadata_Stream_Cursor_Input>>;
  where?: InputMaybe<Role_Default_Metadata_Bool_Exp>;
};


export type Subscription_RootRole_Oed_ExclusiveArgs = {
  distinct_on?: InputMaybe<Array<Role_Oed_Exclusive_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Role_Oed_Exclusive_Order_By>>;
  where?: InputMaybe<Role_Oed_Exclusive_Bool_Exp>;
};


export type Subscription_RootRole_Oed_Exclusive_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Role_Oed_Exclusive_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Role_Oed_Exclusive_Order_By>>;
  where?: InputMaybe<Role_Oed_Exclusive_Bool_Exp>;
};


export type Subscription_RootRole_Oed_Exclusive_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Role_Oed_Exclusive_Stream_Cursor_Input>>;
  where?: InputMaybe<Role_Oed_Exclusive_Bool_Exp>;
};


export type Subscription_RootRole_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Role_Stream_Cursor_Input>>;
  where?: InputMaybe<Role_Bool_Exp>;
};


export type Subscription_RootStatus_OedArgs = {
  distinct_on?: InputMaybe<Array<Status_Oed_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Status_Oed_Order_By>>;
  where?: InputMaybe<Status_Oed_Bool_Exp>;
};


export type Subscription_RootStatus_Oed_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Status_Oed_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Status_Oed_Order_By>>;
  where?: InputMaybe<Status_Oed_Bool_Exp>;
};


export type Subscription_RootStatus_Oed_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Subscription_RootStatus_Oed_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Status_Oed_Stream_Cursor_Input>>;
  where?: InputMaybe<Status_Oed_Bool_Exp>;
};


export type Subscription_RootStatus_Oed_ThumbnailArgs = {
  distinct_on?: InputMaybe<Array<Status_Oed_Thumbnail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Status_Oed_Thumbnail_Order_By>>;
  where?: InputMaybe<Status_Oed_Thumbnail_Bool_Exp>;
};


export type Subscription_RootStatus_Oed_Thumbnail_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Status_Oed_Thumbnail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Status_Oed_Thumbnail_Order_By>>;
  where?: InputMaybe<Status_Oed_Thumbnail_Bool_Exp>;
};


export type Subscription_RootStatus_Oed_Thumbnail_By_PkArgs = {
  value: Scalars['String']['input'];
};


export type Subscription_RootStatus_Oed_Thumbnail_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Status_Oed_Thumbnail_Stream_Cursor_Input>>;
  where?: InputMaybe<Status_Oed_Thumbnail_Bool_Exp>;
};


export type Subscription_RootStorageArgs = {
  distinct_on?: InputMaybe<Array<Storage_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Storage_Order_By>>;
  where?: InputMaybe<Storage_Bool_Exp>;
};


export type Subscription_RootStorage_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Storage_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Storage_Order_By>>;
  where?: InputMaybe<Storage_Bool_Exp>;
};


export type Subscription_RootStorage_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootStorage_OedArgs = {
  distinct_on?: InputMaybe<Array<Storage_Oed_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Storage_Oed_Order_By>>;
  where?: InputMaybe<Storage_Oed_Bool_Exp>;
};


export type Subscription_RootStorage_Oed_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Storage_Oed_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Storage_Oed_Order_By>>;
  where?: InputMaybe<Storage_Oed_Bool_Exp>;
};


export type Subscription_RootStorage_Oed_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootStorage_Oed_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Storage_Oed_Stream_Cursor_Input>>;
  where?: InputMaybe<Storage_Oed_Bool_Exp>;
};


export type Subscription_RootStorage_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Storage_Stream_Cursor_Input>>;
  where?: InputMaybe<Storage_Bool_Exp>;
};


export type Subscription_RootTeamArgs = {
  distinct_on?: InputMaybe<Array<Team_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Team_Order_By>>;
  where?: InputMaybe<Team_Bool_Exp>;
};


export type Subscription_RootTeam_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Team_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Team_Order_By>>;
  where?: InputMaybe<Team_Bool_Exp>;
};


export type Subscription_RootTeam_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootTeam_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Team_Stream_Cursor_Input>>;
  where?: InputMaybe<Team_Bool_Exp>;
};


export type Subscription_RootTemp_User_OedArgs = {
  distinct_on?: InputMaybe<Array<Temp_User_Oed_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Temp_User_Oed_Order_By>>;
  where?: InputMaybe<Temp_User_Oed_Bool_Exp>;
};


export type Subscription_RootTemp_User_Oed_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Temp_User_Oed_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Temp_User_Oed_Order_By>>;
  where?: InputMaybe<Temp_User_Oed_Bool_Exp>;
};


export type Subscription_RootTemp_User_Oed_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Temp_User_Oed_Stream_Cursor_Input>>;
  where?: InputMaybe<Temp_User_Oed_Bool_Exp>;
};


export type Subscription_RootUserArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type Subscription_RootUser_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type Subscription_RootUser_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootUser_RoleArgs = {
  distinct_on?: InputMaybe<Array<User_Role_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Role_Order_By>>;
  where?: InputMaybe<User_Role_Bool_Exp>;
};


export type Subscription_RootUser_Role_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Role_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Role_Order_By>>;
  where?: InputMaybe<User_Role_Bool_Exp>;
};


export type Subscription_RootUser_Role_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootUser_Role_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<User_Role_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Role_Bool_Exp>;
};


export type Subscription_RootUser_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<User_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type Subscription_RootUser_WalletArgs = {
  distinct_on?: InputMaybe<Array<User_Wallet_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Wallet_Order_By>>;
  where?: InputMaybe<User_Wallet_Bool_Exp>;
};


export type Subscription_RootUser_Wallet_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Wallet_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Wallet_Order_By>>;
  where?: InputMaybe<User_Wallet_Bool_Exp>;
};


export type Subscription_RootUser_Wallet_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootUser_Wallet_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<User_Wallet_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Wallet_Bool_Exp>;
};


export type Subscription_RootUsers_TeamsArgs = {
  distinct_on?: InputMaybe<Array<Users_Teams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Teams_Order_By>>;
  where?: InputMaybe<Users_Teams_Bool_Exp>;
};


export type Subscription_RootUsers_Teams_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Teams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Teams_Order_By>>;
  where?: InputMaybe<Users_Teams_Bool_Exp>;
};


export type Subscription_RootUsers_Teams_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootUsers_Teams_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Users_Teams_Stream_Cursor_Input>>;
  where?: InputMaybe<Users_Teams_Bool_Exp>;
};

/** columns and relationships of "team" */
export type Team = {
  __typename?: 'team';
  active?: Maybe<Scalars['Boolean']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  editions_limit?: Maybe<Scalars['Int']['output']>;
  /** An array relationship */
  events: Array<Event>;
  /** An aggregate relationship */
  events_aggregate: Event_Aggregate;
  exclusions_limit?: Maybe<Scalars['Int']['output']>;
  export_limit?: Maybe<Scalars['Int']['output']>;
  id: Scalars['uuid']['output'];
  /** An array relationship */
  manager_teams: Array<Manager_Teams>;
  /** An aggregate relationship */
  manager_teams_aggregate: Manager_Teams_Aggregate;
  name?: Maybe<Scalars['String']['output']>;
  oed_limit?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_limit?: Maybe<Scalars['Int']['output']>;
  /** An array relationship */
  users_teams: Array<Users_Teams>;
  /** An aggregate relationship */
  users_teams_aggregate: Users_Teams_Aggregate;
  views_limit?: Maybe<Scalars['Int']['output']>;
};


/** columns and relationships of "team" */
export type TeamEventsArgs = {
  distinct_on?: InputMaybe<Array<Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Event_Order_By>>;
  where?: InputMaybe<Event_Bool_Exp>;
};


/** columns and relationships of "team" */
export type TeamEvents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Event_Order_By>>;
  where?: InputMaybe<Event_Bool_Exp>;
};


/** columns and relationships of "team" */
export type TeamManager_TeamsArgs = {
  distinct_on?: InputMaybe<Array<Manager_Teams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Manager_Teams_Order_By>>;
  where?: InputMaybe<Manager_Teams_Bool_Exp>;
};


/** columns and relationships of "team" */
export type TeamManager_Teams_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Manager_Teams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Manager_Teams_Order_By>>;
  where?: InputMaybe<Manager_Teams_Bool_Exp>;
};


/** columns and relationships of "team" */
export type TeamUsers_TeamsArgs = {
  distinct_on?: InputMaybe<Array<Users_Teams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Teams_Order_By>>;
  where?: InputMaybe<Users_Teams_Bool_Exp>;
};


/** columns and relationships of "team" */
export type TeamUsers_Teams_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Teams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Teams_Order_By>>;
  where?: InputMaybe<Users_Teams_Bool_Exp>;
};

/** aggregated selection of "team" */
export type Team_Aggregate = {
  __typename?: 'team_aggregate';
  aggregate?: Maybe<Team_Aggregate_Fields>;
  nodes: Array<Team>;
};

/** aggregate fields of "team" */
export type Team_Aggregate_Fields = {
  __typename?: 'team_aggregate_fields';
  avg?: Maybe<Team_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Team_Max_Fields>;
  min?: Maybe<Team_Min_Fields>;
  stddev?: Maybe<Team_Stddev_Fields>;
  stddev_pop?: Maybe<Team_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Team_Stddev_Samp_Fields>;
  sum?: Maybe<Team_Sum_Fields>;
  var_pop?: Maybe<Team_Var_Pop_Fields>;
  var_samp?: Maybe<Team_Var_Samp_Fields>;
  variance?: Maybe<Team_Variance_Fields>;
};


/** aggregate fields of "team" */
export type Team_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Team_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Team_Avg_Fields = {
  __typename?: 'team_avg_fields';
  editions_limit?: Maybe<Scalars['Float']['output']>;
  exclusions_limit?: Maybe<Scalars['Float']['output']>;
  export_limit?: Maybe<Scalars['Float']['output']>;
  oed_limit?: Maybe<Scalars['Float']['output']>;
  user_limit?: Maybe<Scalars['Float']['output']>;
  views_limit?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "team". All fields are combined with a logical 'AND'. */
export type Team_Bool_Exp = {
  _and?: InputMaybe<Array<Team_Bool_Exp>>;
  _not?: InputMaybe<Team_Bool_Exp>;
  _or?: InputMaybe<Array<Team_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  editions_limit?: InputMaybe<Int_Comparison_Exp>;
  events?: InputMaybe<Event_Bool_Exp>;
  events_aggregate?: InputMaybe<Event_Aggregate_Bool_Exp>;
  exclusions_limit?: InputMaybe<Int_Comparison_Exp>;
  export_limit?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  manager_teams?: InputMaybe<Manager_Teams_Bool_Exp>;
  manager_teams_aggregate?: InputMaybe<Manager_Teams_Aggregate_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  oed_limit?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user_limit?: InputMaybe<Int_Comparison_Exp>;
  users_teams?: InputMaybe<Users_Teams_Bool_Exp>;
  users_teams_aggregate?: InputMaybe<Users_Teams_Aggregate_Bool_Exp>;
  views_limit?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "team" */
export enum Team_Constraint {
  /** unique or primary key constraint on columns "id" */
  TeamPk = 'team_pk',
  /** unique or primary key constraint on columns "id" */
  TeamUnique = 'team_unique'
}

/** input type for incrementing numeric columns in table "team" */
export type Team_Inc_Input = {
  editions_limit?: InputMaybe<Scalars['Int']['input']>;
  exclusions_limit?: InputMaybe<Scalars['Int']['input']>;
  export_limit?: InputMaybe<Scalars['Int']['input']>;
  oed_limit?: InputMaybe<Scalars['Int']['input']>;
  user_limit?: InputMaybe<Scalars['Int']['input']>;
  views_limit?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "team" */
export type Team_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  editions_limit?: InputMaybe<Scalars['Int']['input']>;
  events?: InputMaybe<Event_Arr_Rel_Insert_Input>;
  exclusions_limit?: InputMaybe<Scalars['Int']['input']>;
  export_limit?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  manager_teams?: InputMaybe<Manager_Teams_Arr_Rel_Insert_Input>;
  name?: InputMaybe<Scalars['String']['input']>;
  oed_limit?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_limit?: InputMaybe<Scalars['Int']['input']>;
  users_teams?: InputMaybe<Users_Teams_Arr_Rel_Insert_Input>;
  views_limit?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type Team_Max_Fields = {
  __typename?: 'team_max_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  editions_limit?: Maybe<Scalars['Int']['output']>;
  exclusions_limit?: Maybe<Scalars['Int']['output']>;
  export_limit?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  oed_limit?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_limit?: Maybe<Scalars['Int']['output']>;
  views_limit?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type Team_Min_Fields = {
  __typename?: 'team_min_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  editions_limit?: Maybe<Scalars['Int']['output']>;
  exclusions_limit?: Maybe<Scalars['Int']['output']>;
  export_limit?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  oed_limit?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_limit?: Maybe<Scalars['Int']['output']>;
  views_limit?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "team" */
export type Team_Mutation_Response = {
  __typename?: 'team_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Team>;
};

/** input type for inserting object relation for remote table "team" */
export type Team_Obj_Rel_Insert_Input = {
  data: Team_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Team_On_Conflict>;
};

/** on_conflict condition type for table "team" */
export type Team_On_Conflict = {
  constraint: Team_Constraint;
  update_columns?: Array<Team_Update_Column>;
  where?: InputMaybe<Team_Bool_Exp>;
};

/** Ordering options when selecting data from "team". */
export type Team_Order_By = {
  active?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  editions_limit?: InputMaybe<Order_By>;
  events_aggregate?: InputMaybe<Event_Aggregate_Order_By>;
  exclusions_limit?: InputMaybe<Order_By>;
  export_limit?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  manager_teams_aggregate?: InputMaybe<Manager_Teams_Aggregate_Order_By>;
  name?: InputMaybe<Order_By>;
  oed_limit?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_limit?: InputMaybe<Order_By>;
  users_teams_aggregate?: InputMaybe<Users_Teams_Aggregate_Order_By>;
  views_limit?: InputMaybe<Order_By>;
};

/** primary key columns input for table: team */
export type Team_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "team" */
export enum Team_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EditionsLimit = 'editions_limit',
  /** column name */
  ExclusionsLimit = 'exclusions_limit',
  /** column name */
  ExportLimit = 'export_limit',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OedLimit = 'oed_limit',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserLimit = 'user_limit',
  /** column name */
  ViewsLimit = 'views_limit'
}

/** input type for updating data in table "team" */
export type Team_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  editions_limit?: InputMaybe<Scalars['Int']['input']>;
  exclusions_limit?: InputMaybe<Scalars['Int']['input']>;
  export_limit?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  oed_limit?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_limit?: InputMaybe<Scalars['Int']['input']>;
  views_limit?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type Team_Stddev_Fields = {
  __typename?: 'team_stddev_fields';
  editions_limit?: Maybe<Scalars['Float']['output']>;
  exclusions_limit?: Maybe<Scalars['Float']['output']>;
  export_limit?: Maybe<Scalars['Float']['output']>;
  oed_limit?: Maybe<Scalars['Float']['output']>;
  user_limit?: Maybe<Scalars['Float']['output']>;
  views_limit?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Team_Stddev_Pop_Fields = {
  __typename?: 'team_stddev_pop_fields';
  editions_limit?: Maybe<Scalars['Float']['output']>;
  exclusions_limit?: Maybe<Scalars['Float']['output']>;
  export_limit?: Maybe<Scalars['Float']['output']>;
  oed_limit?: Maybe<Scalars['Float']['output']>;
  user_limit?: Maybe<Scalars['Float']['output']>;
  views_limit?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Team_Stddev_Samp_Fields = {
  __typename?: 'team_stddev_samp_fields';
  editions_limit?: Maybe<Scalars['Float']['output']>;
  exclusions_limit?: Maybe<Scalars['Float']['output']>;
  export_limit?: Maybe<Scalars['Float']['output']>;
  oed_limit?: Maybe<Scalars['Float']['output']>;
  user_limit?: Maybe<Scalars['Float']['output']>;
  views_limit?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "team" */
export type Team_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Team_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Team_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  editions_limit?: InputMaybe<Scalars['Int']['input']>;
  exclusions_limit?: InputMaybe<Scalars['Int']['input']>;
  export_limit?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  oed_limit?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_limit?: InputMaybe<Scalars['Int']['input']>;
  views_limit?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type Team_Sum_Fields = {
  __typename?: 'team_sum_fields';
  editions_limit?: Maybe<Scalars['Int']['output']>;
  exclusions_limit?: Maybe<Scalars['Int']['output']>;
  export_limit?: Maybe<Scalars['Int']['output']>;
  oed_limit?: Maybe<Scalars['Int']['output']>;
  user_limit?: Maybe<Scalars['Int']['output']>;
  views_limit?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "team" */
export enum Team_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EditionsLimit = 'editions_limit',
  /** column name */
  ExclusionsLimit = 'exclusions_limit',
  /** column name */
  ExportLimit = 'export_limit',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OedLimit = 'oed_limit',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserLimit = 'user_limit',
  /** column name */
  ViewsLimit = 'views_limit'
}

export type Team_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Team_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Team_Set_Input>;
  /** filter the rows which have to be updated */
  where: Team_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Team_Var_Pop_Fields = {
  __typename?: 'team_var_pop_fields';
  editions_limit?: Maybe<Scalars['Float']['output']>;
  exclusions_limit?: Maybe<Scalars['Float']['output']>;
  export_limit?: Maybe<Scalars['Float']['output']>;
  oed_limit?: Maybe<Scalars['Float']['output']>;
  user_limit?: Maybe<Scalars['Float']['output']>;
  views_limit?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Team_Var_Samp_Fields = {
  __typename?: 'team_var_samp_fields';
  editions_limit?: Maybe<Scalars['Float']['output']>;
  exclusions_limit?: Maybe<Scalars['Float']['output']>;
  export_limit?: Maybe<Scalars['Float']['output']>;
  oed_limit?: Maybe<Scalars['Float']['output']>;
  user_limit?: Maybe<Scalars['Float']['output']>;
  views_limit?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Team_Variance_Fields = {
  __typename?: 'team_variance_fields';
  editions_limit?: Maybe<Scalars['Float']['output']>;
  exclusions_limit?: Maybe<Scalars['Float']['output']>;
  export_limit?: Maybe<Scalars['Float']['output']>;
  oed_limit?: Maybe<Scalars['Float']['output']>;
  user_limit?: Maybe<Scalars['Float']['output']>;
  views_limit?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "temp_user_oed" */
export type Temp_User_Oed = {
  __typename?: 'temp_user_oed';
  active?: Maybe<Scalars['Boolean']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id: Scalars['uuid']['output'];
  limit_exports?: Maybe<Scalars['String']['output']>;
  limit_oed?: Maybe<Scalars['String']['output']>;
  limit_views?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  /** An object relationship */
  user?: Maybe<User>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** aggregated selection of "temp_user_oed" */
export type Temp_User_Oed_Aggregate = {
  __typename?: 'temp_user_oed_aggregate';
  aggregate?: Maybe<Temp_User_Oed_Aggregate_Fields>;
  nodes: Array<Temp_User_Oed>;
};

export type Temp_User_Oed_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Temp_User_Oed_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Temp_User_Oed_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Temp_User_Oed_Aggregate_Bool_Exp_Count>;
};

export type Temp_User_Oed_Aggregate_Bool_Exp_Bool_And = {
  arguments: Temp_User_Oed_Select_Column_Temp_User_Oed_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Temp_User_Oed_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Temp_User_Oed_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Temp_User_Oed_Select_Column_Temp_User_Oed_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Temp_User_Oed_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Temp_User_Oed_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Temp_User_Oed_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Temp_User_Oed_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "temp_user_oed" */
export type Temp_User_Oed_Aggregate_Fields = {
  __typename?: 'temp_user_oed_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Temp_User_Oed_Max_Fields>;
  min?: Maybe<Temp_User_Oed_Min_Fields>;
};


/** aggregate fields of "temp_user_oed" */
export type Temp_User_Oed_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Temp_User_Oed_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "temp_user_oed" */
export type Temp_User_Oed_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Temp_User_Oed_Max_Order_By>;
  min?: InputMaybe<Temp_User_Oed_Min_Order_By>;
};

/** input type for inserting array relation for remote table "temp_user_oed" */
export type Temp_User_Oed_Arr_Rel_Insert_Input = {
  data: Array<Temp_User_Oed_Insert_Input>;
};

/** Boolean expression to filter rows from the table "temp_user_oed". All fields are combined with a logical 'AND'. */
export type Temp_User_Oed_Bool_Exp = {
  _and?: InputMaybe<Array<Temp_User_Oed_Bool_Exp>>;
  _not?: InputMaybe<Temp_User_Oed_Bool_Exp>;
  _or?: InputMaybe<Array<Temp_User_Oed_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  limit_exports?: InputMaybe<String_Comparison_Exp>;
  limit_oed?: InputMaybe<String_Comparison_Exp>;
  limit_views?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** input type for inserting data into table "temp_user_oed" */
export type Temp_User_Oed_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  limit_exports?: InputMaybe<Scalars['String']['input']>;
  limit_oed?: InputMaybe<Scalars['String']['input']>;
  limit_views?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Temp_User_Oed_Max_Fields = {
  __typename?: 'temp_user_oed_max_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  limit_exports?: Maybe<Scalars['String']['output']>;
  limit_oed?: Maybe<Scalars['String']['output']>;
  limit_views?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "temp_user_oed" */
export type Temp_User_Oed_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  limit_exports?: InputMaybe<Order_By>;
  limit_oed?: InputMaybe<Order_By>;
  limit_views?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Temp_User_Oed_Min_Fields = {
  __typename?: 'temp_user_oed_min_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  limit_exports?: Maybe<Scalars['String']['output']>;
  limit_oed?: Maybe<Scalars['String']['output']>;
  limit_views?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "temp_user_oed" */
export type Temp_User_Oed_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  limit_exports?: InputMaybe<Order_By>;
  limit_oed?: InputMaybe<Order_By>;
  limit_views?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "temp_user_oed" */
export type Temp_User_Oed_Mutation_Response = {
  __typename?: 'temp_user_oed_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Temp_User_Oed>;
};

/** Ordering options when selecting data from "temp_user_oed". */
export type Temp_User_Oed_Order_By = {
  active?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  limit_exports?: InputMaybe<Order_By>;
  limit_oed?: InputMaybe<Order_By>;
  limit_views?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** select columns of table "temp_user_oed" */
export enum Temp_User_Oed_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LimitExports = 'limit_exports',
  /** column name */
  LimitOed = 'limit_oed',
  /** column name */
  LimitViews = 'limit_views',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** select "temp_user_oed_aggregate_bool_exp_bool_and_arguments_columns" columns of table "temp_user_oed" */
export enum Temp_User_Oed_Select_Column_Temp_User_Oed_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** select "temp_user_oed_aggregate_bool_exp_bool_or_arguments_columns" columns of table "temp_user_oed" */
export enum Temp_User_Oed_Select_Column_Temp_User_Oed_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** input type for updating data in table "temp_user_oed" */
export type Temp_User_Oed_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  limit_exports?: InputMaybe<Scalars['String']['input']>;
  limit_oed?: InputMaybe<Scalars['String']['input']>;
  limit_views?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "temp_user_oed" */
export type Temp_User_Oed_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Temp_User_Oed_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Temp_User_Oed_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  limit_exports?: InputMaybe<Scalars['String']['input']>;
  limit_oed?: InputMaybe<Scalars['String']['input']>;
  limit_views?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

export type Temp_User_Oed_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Temp_User_Oed_Set_Input>;
  /** filter the rows which have to be updated */
  where: Temp_User_Oed_Bool_Exp;
};

/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamp']['input']>;
  _gt?: InputMaybe<Scalars['timestamp']['input']>;
  _gte?: InputMaybe<Scalars['timestamp']['input']>;
  _in?: InputMaybe<Array<Scalars['timestamp']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['timestamp']['input']>;
  _lte?: InputMaybe<Scalars['timestamp']['input']>;
  _neq?: InputMaybe<Scalars['timestamp']['input']>;
  _nin?: InputMaybe<Array<Scalars['timestamp']['input']>>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']['input']>;
  _gt?: InputMaybe<Scalars['timestamptz']['input']>;
  _gte?: InputMaybe<Scalars['timestamptz']['input']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['timestamptz']['input']>;
  _lte?: InputMaybe<Scalars['timestamptz']['input']>;
  _neq?: InputMaybe<Scalars['timestamptz']['input']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
};

/** columns and relationships of "user" */
export type User = {
  __typename?: 'user';
  active?: Maybe<Scalars['Boolean']['output']>;
  /** An array relationship */
  addresses: Array<Address>;
  /** An aggregate relationship */
  addresses_aggregate: Address_Aggregate;
  avatar?: Maybe<Scalars['String']['output']>;
  birthday?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  buyers: Array<Buyer>;
  /** An aggregate relationship */
  buyers_aggregate: Buyer_Aggregate;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  /** An array relationship */
  events: Array<Event>;
  /** An aggregate relationship */
  events_aggregate: Event_Aggregate;
  first_name?: Maybe<Scalars['String']['output']>;
  id: Scalars['uuid']['output'];
  identification_number?: Maybe<Scalars['String']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  libraries: Array<Library>;
  /** An aggregate relationship */
  libraries_aggregate: Library_Aggregate;
  /** An object relationship */
  login?: Maybe<Login>;
  login_id?: Maybe<Scalars['uuid']['output']>;
  /** An array relationship */
  manager_teams: Array<Manager_Teams>;
  /** An aggregate relationship */
  manager_teams_aggregate: Manager_Teams_Aggregate;
  phone?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  storages: Array<Storage>;
  /** An aggregate relationship */
  storages_aggregate: Storage_Aggregate;
  /** An array relationship */
  temp_user_oeds: Array<Temp_User_Oed>;
  /** An aggregate relationship */
  temp_user_oeds_aggregate: Temp_User_Oed_Aggregate;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  /** An array relationship */
  user_roles: Array<User_Role>;
  /** An aggregate relationship */
  user_roles_aggregate: User_Role_Aggregate;
  /** An array relationship */
  user_wallets: Array<User_Wallet>;
  /** An aggregate relationship */
  user_wallets_aggregate: User_Wallet_Aggregate;
  /** An array relationship */
  users_teams: Array<Users_Teams>;
  /** An aggregate relationship */
  users_teams_aggregate: Users_Teams_Aggregate;
};


/** columns and relationships of "user" */
export type UserAddressesArgs = {
  distinct_on?: InputMaybe<Array<Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Address_Order_By>>;
  where?: InputMaybe<Address_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserAddresses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Address_Order_By>>;
  where?: InputMaybe<Address_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserBuyersArgs = {
  distinct_on?: InputMaybe<Array<Buyer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Buyer_Order_By>>;
  where?: InputMaybe<Buyer_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserBuyers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Buyer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Buyer_Order_By>>;
  where?: InputMaybe<Buyer_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserEventsArgs = {
  distinct_on?: InputMaybe<Array<Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Event_Order_By>>;
  where?: InputMaybe<Event_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserEvents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Event_Order_By>>;
  where?: InputMaybe<Event_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserLibrariesArgs = {
  distinct_on?: InputMaybe<Array<Library_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Library_Order_By>>;
  where?: InputMaybe<Library_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserLibraries_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Library_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Library_Order_By>>;
  where?: InputMaybe<Library_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserManager_TeamsArgs = {
  distinct_on?: InputMaybe<Array<Manager_Teams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Manager_Teams_Order_By>>;
  where?: InputMaybe<Manager_Teams_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserManager_Teams_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Manager_Teams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Manager_Teams_Order_By>>;
  where?: InputMaybe<Manager_Teams_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserStoragesArgs = {
  distinct_on?: InputMaybe<Array<Storage_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Storage_Order_By>>;
  where?: InputMaybe<Storage_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserStorages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Storage_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Storage_Order_By>>;
  where?: InputMaybe<Storage_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserTemp_User_OedsArgs = {
  distinct_on?: InputMaybe<Array<Temp_User_Oed_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Temp_User_Oed_Order_By>>;
  where?: InputMaybe<Temp_User_Oed_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserTemp_User_Oeds_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Temp_User_Oed_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Temp_User_Oed_Order_By>>;
  where?: InputMaybe<Temp_User_Oed_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserUser_RolesArgs = {
  distinct_on?: InputMaybe<Array<User_Role_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Role_Order_By>>;
  where?: InputMaybe<User_Role_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserUser_Roles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Role_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Role_Order_By>>;
  where?: InputMaybe<User_Role_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserUser_WalletsArgs = {
  distinct_on?: InputMaybe<Array<User_Wallet_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Wallet_Order_By>>;
  where?: InputMaybe<User_Wallet_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserUser_Wallets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Wallet_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Wallet_Order_By>>;
  where?: InputMaybe<User_Wallet_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserUsers_TeamsArgs = {
  distinct_on?: InputMaybe<Array<Users_Teams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Teams_Order_By>>;
  where?: InputMaybe<Users_Teams_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserUsers_Teams_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Teams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Teams_Order_By>>;
  where?: InputMaybe<Users_Teams_Bool_Exp>;
};

/** aggregated selection of "user" */
export type User_Aggregate = {
  __typename?: 'user_aggregate';
  aggregate?: Maybe<User_Aggregate_Fields>;
  nodes: Array<User>;
};

export type User_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<User_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<User_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<User_Aggregate_Bool_Exp_Count>;
};

export type User_Aggregate_Bool_Exp_Bool_And = {
  arguments: User_Select_Column_User_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<User_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type User_Aggregate_Bool_Exp_Bool_Or = {
  arguments: User_Select_Column_User_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<User_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type User_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<User_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "user" */
export type User_Aggregate_Fields = {
  __typename?: 'user_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<User_Max_Fields>;
  min?: Maybe<User_Min_Fields>;
};


/** aggregate fields of "user" */
export type User_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "user" */
export type User_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Max_Order_By>;
  min?: InputMaybe<User_Min_Order_By>;
};

/** input type for inserting array relation for remote table "user" */
export type User_Arr_Rel_Insert_Input = {
  data: Array<User_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_On_Conflict>;
};

/** Boolean expression to filter rows from the table "user". All fields are combined with a logical 'AND'. */
export type User_Bool_Exp = {
  _and?: InputMaybe<Array<User_Bool_Exp>>;
  _not?: InputMaybe<User_Bool_Exp>;
  _or?: InputMaybe<Array<User_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  addresses?: InputMaybe<Address_Bool_Exp>;
  addresses_aggregate?: InputMaybe<Address_Aggregate_Bool_Exp>;
  avatar?: InputMaybe<String_Comparison_Exp>;
  birthday?: InputMaybe<String_Comparison_Exp>;
  buyers?: InputMaybe<Buyer_Bool_Exp>;
  buyers_aggregate?: InputMaybe<Buyer_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  events?: InputMaybe<Event_Bool_Exp>;
  events_aggregate?: InputMaybe<Event_Aggregate_Bool_Exp>;
  first_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  identification_number?: InputMaybe<String_Comparison_Exp>;
  last_name?: InputMaybe<String_Comparison_Exp>;
  libraries?: InputMaybe<Library_Bool_Exp>;
  libraries_aggregate?: InputMaybe<Library_Aggregate_Bool_Exp>;
  login?: InputMaybe<Login_Bool_Exp>;
  login_id?: InputMaybe<Uuid_Comparison_Exp>;
  manager_teams?: InputMaybe<Manager_Teams_Bool_Exp>;
  manager_teams_aggregate?: InputMaybe<Manager_Teams_Aggregate_Bool_Exp>;
  phone?: InputMaybe<String_Comparison_Exp>;
  storages?: InputMaybe<Storage_Bool_Exp>;
  storages_aggregate?: InputMaybe<Storage_Aggregate_Bool_Exp>;
  temp_user_oeds?: InputMaybe<Temp_User_Oed_Bool_Exp>;
  temp_user_oeds_aggregate?: InputMaybe<Temp_User_Oed_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user_roles?: InputMaybe<User_Role_Bool_Exp>;
  user_roles_aggregate?: InputMaybe<User_Role_Aggregate_Bool_Exp>;
  user_wallets?: InputMaybe<User_Wallet_Bool_Exp>;
  user_wallets_aggregate?: InputMaybe<User_Wallet_Aggregate_Bool_Exp>;
  users_teams?: InputMaybe<Users_Teams_Bool_Exp>;
  users_teams_aggregate?: InputMaybe<Users_Teams_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "user" */
export enum User_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserPkey = 'user_pkey'
}

/** input type for inserting data into table "user" */
export type User_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  addresses?: InputMaybe<Address_Arr_Rel_Insert_Input>;
  avatar?: InputMaybe<Scalars['String']['input']>;
  birthday?: InputMaybe<Scalars['String']['input']>;
  buyers?: InputMaybe<Buyer_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  events?: InputMaybe<Event_Arr_Rel_Insert_Input>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  identification_number?: InputMaybe<Scalars['String']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  libraries?: InputMaybe<Library_Arr_Rel_Insert_Input>;
  login?: InputMaybe<Login_Obj_Rel_Insert_Input>;
  login_id?: InputMaybe<Scalars['uuid']['input']>;
  manager_teams?: InputMaybe<Manager_Teams_Arr_Rel_Insert_Input>;
  phone?: InputMaybe<Scalars['String']['input']>;
  storages?: InputMaybe<Storage_Arr_Rel_Insert_Input>;
  temp_user_oeds?: InputMaybe<Temp_User_Oed_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_roles?: InputMaybe<User_Role_Arr_Rel_Insert_Input>;
  user_wallets?: InputMaybe<User_Wallet_Arr_Rel_Insert_Input>;
  users_teams?: InputMaybe<Users_Teams_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type User_Max_Fields = {
  __typename?: 'user_max_fields';
  avatar?: Maybe<Scalars['String']['output']>;
  birthday?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  identification_number?: Maybe<Scalars['String']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  login_id?: Maybe<Scalars['uuid']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** order by max() on columns of table "user" */
export type User_Max_Order_By = {
  avatar?: InputMaybe<Order_By>;
  birthday?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identification_number?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  login_id?: InputMaybe<Order_By>;
  phone?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Min_Fields = {
  __typename?: 'user_min_fields';
  avatar?: Maybe<Scalars['String']['output']>;
  birthday?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  identification_number?: Maybe<Scalars['String']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  login_id?: Maybe<Scalars['uuid']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** order by min() on columns of table "user" */
export type User_Min_Order_By = {
  avatar?: InputMaybe<Order_By>;
  birthday?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identification_number?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  login_id?: InputMaybe<Order_By>;
  phone?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user" */
export type User_Mutation_Response = {
  __typename?: 'user_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<User>;
};

/** input type for inserting object relation for remote table "user" */
export type User_Obj_Rel_Insert_Input = {
  data: User_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<User_On_Conflict>;
};

/** on_conflict condition type for table "user" */
export type User_On_Conflict = {
  constraint: User_Constraint;
  update_columns?: Array<User_Update_Column>;
  where?: InputMaybe<User_Bool_Exp>;
};

/** Ordering options when selecting data from "user". */
export type User_Order_By = {
  active?: InputMaybe<Order_By>;
  addresses_aggregate?: InputMaybe<Address_Aggregate_Order_By>;
  avatar?: InputMaybe<Order_By>;
  birthday?: InputMaybe<Order_By>;
  buyers_aggregate?: InputMaybe<Buyer_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  events_aggregate?: InputMaybe<Event_Aggregate_Order_By>;
  first_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identification_number?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  libraries_aggregate?: InputMaybe<Library_Aggregate_Order_By>;
  login?: InputMaybe<Login_Order_By>;
  login_id?: InputMaybe<Order_By>;
  manager_teams_aggregate?: InputMaybe<Manager_Teams_Aggregate_Order_By>;
  phone?: InputMaybe<Order_By>;
  storages_aggregate?: InputMaybe<Storage_Aggregate_Order_By>;
  temp_user_oeds_aggregate?: InputMaybe<Temp_User_Oed_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_roles_aggregate?: InputMaybe<User_Role_Aggregate_Order_By>;
  user_wallets_aggregate?: InputMaybe<User_Wallet_Aggregate_Order_By>;
  users_teams_aggregate?: InputMaybe<Users_Teams_Aggregate_Order_By>;
};

/** primary key columns input for table: user */
export type User_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** columns and relationships of "user_role" */
export type User_Role = {
  __typename?: 'user_role';
  active?: Maybe<Scalars['Boolean']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id: Scalars['uuid']['output'];
  /** An object relationship */
  role?: Maybe<Role>;
  role_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  /** An object relationship */
  user?: Maybe<User>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** aggregated selection of "user_role" */
export type User_Role_Aggregate = {
  __typename?: 'user_role_aggregate';
  aggregate?: Maybe<User_Role_Aggregate_Fields>;
  nodes: Array<User_Role>;
};

export type User_Role_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<User_Role_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<User_Role_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<User_Role_Aggregate_Bool_Exp_Count>;
};

export type User_Role_Aggregate_Bool_Exp_Bool_And = {
  arguments: User_Role_Select_Column_User_Role_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<User_Role_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type User_Role_Aggregate_Bool_Exp_Bool_Or = {
  arguments: User_Role_Select_Column_User_Role_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<User_Role_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type User_Role_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Role_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<User_Role_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "user_role" */
export type User_Role_Aggregate_Fields = {
  __typename?: 'user_role_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<User_Role_Max_Fields>;
  min?: Maybe<User_Role_Min_Fields>;
};


/** aggregate fields of "user_role" */
export type User_Role_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Role_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "user_role" */
export type User_Role_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Role_Max_Order_By>;
  min?: InputMaybe<User_Role_Min_Order_By>;
};

/** input type for inserting array relation for remote table "user_role" */
export type User_Role_Arr_Rel_Insert_Input = {
  data: Array<User_Role_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Role_On_Conflict>;
};

/** Boolean expression to filter rows from the table "user_role". All fields are combined with a logical 'AND'. */
export type User_Role_Bool_Exp = {
  _and?: InputMaybe<Array<User_Role_Bool_Exp>>;
  _not?: InputMaybe<User_Role_Bool_Exp>;
  _or?: InputMaybe<Array<User_Role_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  role?: InputMaybe<Role_Bool_Exp>;
  role_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_role" */
export enum User_Role_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserRolePkey = 'user_role_pkey'
}

/** input type for inserting data into table "user_role" */
export type User_Role_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  role?: InputMaybe<Role_Obj_Rel_Insert_Input>;
  role_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type User_Role_Max_Fields = {
  __typename?: 'user_role_max_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  role_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "user_role" */
export type User_Role_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Role_Min_Fields = {
  __typename?: 'user_role_min_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  role_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "user_role" */
export type User_Role_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_role" */
export type User_Role_Mutation_Response = {
  __typename?: 'user_role_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Role>;
};

/** on_conflict condition type for table "user_role" */
export type User_Role_On_Conflict = {
  constraint: User_Role_Constraint;
  update_columns?: Array<User_Role_Update_Column>;
  where?: InputMaybe<User_Role_Bool_Exp>;
};

/** Ordering options when selecting data from "user_role". */
export type User_Role_Order_By = {
  active?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  role?: InputMaybe<Role_Order_By>;
  role_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_role */
export type User_Role_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "user_role" */
export enum User_Role_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  RoleId = 'role_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** select "user_role_aggregate_bool_exp_bool_and_arguments_columns" columns of table "user_role" */
export enum User_Role_Select_Column_User_Role_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** select "user_role_aggregate_bool_exp_bool_or_arguments_columns" columns of table "user_role" */
export enum User_Role_Select_Column_User_Role_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** input type for updating data in table "user_role" */
export type User_Role_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  role_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "user_role" */
export type User_Role_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Role_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Role_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  role_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "user_role" */
export enum User_Role_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  RoleId = 'role_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type User_Role_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Role_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Role_Bool_Exp;
};

/** select columns of table "user" */
export enum User_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  Avatar = 'avatar',
  /** column name */
  Birthday = 'birthday',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  IdentificationNumber = 'identification_number',
  /** column name */
  LastName = 'last_name',
  /** column name */
  LoginId = 'login_id',
  /** column name */
  Phone = 'phone',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "user_aggregate_bool_exp_bool_and_arguments_columns" columns of table "user" */
export enum User_Select_Column_User_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** select "user_aggregate_bool_exp_bool_or_arguments_columns" columns of table "user" */
export enum User_Select_Column_User_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** input type for updating data in table "user" */
export type User_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  avatar?: InputMaybe<Scalars['String']['input']>;
  birthday?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  identification_number?: InputMaybe<Scalars['String']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  login_id?: InputMaybe<Scalars['uuid']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** Streaming cursor of the table "user" */
export type User_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  avatar?: InputMaybe<Scalars['String']['input']>;
  birthday?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  identification_number?: InputMaybe<Scalars['String']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  login_id?: InputMaybe<Scalars['uuid']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** update columns of table "user" */
export enum User_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  Avatar = 'avatar',
  /** column name */
  Birthday = 'birthday',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  IdentificationNumber = 'identification_number',
  /** column name */
  LastName = 'last_name',
  /** column name */
  LoginId = 'login_id',
  /** column name */
  Phone = 'phone',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type User_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Bool_Exp;
};

/** columns and relationships of "user_wallet" */
export type User_Wallet = {
  __typename?: 'user_wallet';
  active?: Maybe<Scalars['Boolean']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id: Scalars['uuid']['output'];
  iugu_account?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  /** An object relationship */
  user?: Maybe<User>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** aggregated selection of "user_wallet" */
export type User_Wallet_Aggregate = {
  __typename?: 'user_wallet_aggregate';
  aggregate?: Maybe<User_Wallet_Aggregate_Fields>;
  nodes: Array<User_Wallet>;
};

export type User_Wallet_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<User_Wallet_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<User_Wallet_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<User_Wallet_Aggregate_Bool_Exp_Count>;
};

export type User_Wallet_Aggregate_Bool_Exp_Bool_And = {
  arguments: User_Wallet_Select_Column_User_Wallet_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<User_Wallet_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type User_Wallet_Aggregate_Bool_Exp_Bool_Or = {
  arguments: User_Wallet_Select_Column_User_Wallet_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<User_Wallet_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type User_Wallet_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Wallet_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<User_Wallet_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "user_wallet" */
export type User_Wallet_Aggregate_Fields = {
  __typename?: 'user_wallet_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<User_Wallet_Max_Fields>;
  min?: Maybe<User_Wallet_Min_Fields>;
};


/** aggregate fields of "user_wallet" */
export type User_Wallet_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Wallet_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "user_wallet" */
export type User_Wallet_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Wallet_Max_Order_By>;
  min?: InputMaybe<User_Wallet_Min_Order_By>;
};

/** input type for inserting array relation for remote table "user_wallet" */
export type User_Wallet_Arr_Rel_Insert_Input = {
  data: Array<User_Wallet_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Wallet_On_Conflict>;
};

/** Boolean expression to filter rows from the table "user_wallet". All fields are combined with a logical 'AND'. */
export type User_Wallet_Bool_Exp = {
  _and?: InputMaybe<Array<User_Wallet_Bool_Exp>>;
  _not?: InputMaybe<User_Wallet_Bool_Exp>;
  _or?: InputMaybe<Array<User_Wallet_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  iugu_account?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_wallet" */
export enum User_Wallet_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserWalletPkey = 'user_wallet_pkey'
}

/** input type for inserting data into table "user_wallet" */
export type User_Wallet_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  iugu_account?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type User_Wallet_Max_Fields = {
  __typename?: 'user_wallet_max_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  iugu_account?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "user_wallet" */
export type User_Wallet_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  iugu_account?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Wallet_Min_Fields = {
  __typename?: 'user_wallet_min_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  iugu_account?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "user_wallet" */
export type User_Wallet_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  iugu_account?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_wallet" */
export type User_Wallet_Mutation_Response = {
  __typename?: 'user_wallet_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Wallet>;
};

/** on_conflict condition type for table "user_wallet" */
export type User_Wallet_On_Conflict = {
  constraint: User_Wallet_Constraint;
  update_columns?: Array<User_Wallet_Update_Column>;
  where?: InputMaybe<User_Wallet_Bool_Exp>;
};

/** Ordering options when selecting data from "user_wallet". */
export type User_Wallet_Order_By = {
  active?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  iugu_account?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_wallet */
export type User_Wallet_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "user_wallet" */
export enum User_Wallet_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IuguAccount = 'iugu_account',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** select "user_wallet_aggregate_bool_exp_bool_and_arguments_columns" columns of table "user_wallet" */
export enum User_Wallet_Select_Column_User_Wallet_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** select "user_wallet_aggregate_bool_exp_bool_or_arguments_columns" columns of table "user_wallet" */
export enum User_Wallet_Select_Column_User_Wallet_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** input type for updating data in table "user_wallet" */
export type User_Wallet_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  iugu_account?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "user_wallet" */
export type User_Wallet_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Wallet_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Wallet_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  iugu_account?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "user_wallet" */
export enum User_Wallet_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IuguAccount = 'iugu_account',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type User_Wallet_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Wallet_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Wallet_Bool_Exp;
};

/** columns and relationships of "users_teams" */
export type Users_Teams = {
  __typename?: 'users_teams';
  active?: Maybe<Scalars['Boolean']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id: Scalars['uuid']['output'];
  /** An object relationship */
  role?: Maybe<Role>;
  role_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  team?: Maybe<Team>;
  team_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  /** An object relationship */
  user?: Maybe<User>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** aggregated selection of "users_teams" */
export type Users_Teams_Aggregate = {
  __typename?: 'users_teams_aggregate';
  aggregate?: Maybe<Users_Teams_Aggregate_Fields>;
  nodes: Array<Users_Teams>;
};

export type Users_Teams_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Users_Teams_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Users_Teams_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Users_Teams_Aggregate_Bool_Exp_Count>;
};

export type Users_Teams_Aggregate_Bool_Exp_Bool_And = {
  arguments: Users_Teams_Select_Column_Users_Teams_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Users_Teams_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Users_Teams_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Users_Teams_Select_Column_Users_Teams_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Users_Teams_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Users_Teams_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Users_Teams_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Users_Teams_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "users_teams" */
export type Users_Teams_Aggregate_Fields = {
  __typename?: 'users_teams_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Users_Teams_Max_Fields>;
  min?: Maybe<Users_Teams_Min_Fields>;
};


/** aggregate fields of "users_teams" */
export type Users_Teams_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Users_Teams_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "users_teams" */
export type Users_Teams_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Users_Teams_Max_Order_By>;
  min?: InputMaybe<Users_Teams_Min_Order_By>;
};

/** input type for inserting array relation for remote table "users_teams" */
export type Users_Teams_Arr_Rel_Insert_Input = {
  data: Array<Users_Teams_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Users_Teams_On_Conflict>;
};

/** Boolean expression to filter rows from the table "users_teams". All fields are combined with a logical 'AND'. */
export type Users_Teams_Bool_Exp = {
  _and?: InputMaybe<Array<Users_Teams_Bool_Exp>>;
  _not?: InputMaybe<Users_Teams_Bool_Exp>;
  _or?: InputMaybe<Array<Users_Teams_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  role?: InputMaybe<Role_Bool_Exp>;
  role_id?: InputMaybe<Uuid_Comparison_Exp>;
  team?: InputMaybe<Team_Bool_Exp>;
  team_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "users_teams" */
export enum Users_Teams_Constraint {
  /** unique or primary key constraint on columns "id" */
  UsersTeamsPk = 'users_teams_pk'
}

/** input type for inserting data into table "users_teams" */
export type Users_Teams_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  role?: InputMaybe<Role_Obj_Rel_Insert_Input>;
  role_id?: InputMaybe<Scalars['uuid']['input']>;
  team?: InputMaybe<Team_Obj_Rel_Insert_Input>;
  team_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Users_Teams_Max_Fields = {
  __typename?: 'users_teams_max_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  role_id?: Maybe<Scalars['uuid']['output']>;
  team_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "users_teams" */
export type Users_Teams_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Users_Teams_Min_Fields = {
  __typename?: 'users_teams_min_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  role_id?: Maybe<Scalars['uuid']['output']>;
  team_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "users_teams" */
export type Users_Teams_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "users_teams" */
export type Users_Teams_Mutation_Response = {
  __typename?: 'users_teams_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Users_Teams>;
};

/** on_conflict condition type for table "users_teams" */
export type Users_Teams_On_Conflict = {
  constraint: Users_Teams_Constraint;
  update_columns?: Array<Users_Teams_Update_Column>;
  where?: InputMaybe<Users_Teams_Bool_Exp>;
};

/** Ordering options when selecting data from "users_teams". */
export type Users_Teams_Order_By = {
  active?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  role?: InputMaybe<Role_Order_By>;
  role_id?: InputMaybe<Order_By>;
  team?: InputMaybe<Team_Order_By>;
  team_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: users_teams */
export type Users_Teams_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "users_teams" */
export enum Users_Teams_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  RoleId = 'role_id',
  /** column name */
  TeamId = 'team_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** select "users_teams_aggregate_bool_exp_bool_and_arguments_columns" columns of table "users_teams" */
export enum Users_Teams_Select_Column_Users_Teams_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** select "users_teams_aggregate_bool_exp_bool_or_arguments_columns" columns of table "users_teams" */
export enum Users_Teams_Select_Column_Users_Teams_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** input type for updating data in table "users_teams" */
export type Users_Teams_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  role_id?: InputMaybe<Scalars['uuid']['input']>;
  team_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "users_teams" */
export type Users_Teams_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Users_Teams_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Users_Teams_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  role_id?: InputMaybe<Scalars['uuid']['input']>;
  team_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "users_teams" */
export enum Users_Teams_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  RoleId = 'role_id',
  /** column name */
  TeamId = 'team_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Users_Teams_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Users_Teams_Set_Input>;
  /** filter the rows which have to be updated */
  where: Users_Teams_Bool_Exp;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']['input']>;
  _gt?: InputMaybe<Scalars['uuid']['input']>;
  _gte?: InputMaybe<Scalars['uuid']['input']>;
  _in?: InputMaybe<Array<Scalars['uuid']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['uuid']['input']>;
  _lte?: InputMaybe<Scalars['uuid']['input']>;
  _neq?: InputMaybe<Scalars['uuid']['input']>;
  _nin?: InputMaybe<Array<Scalars['uuid']['input']>>;
};

export type InsertEventMutationVariables = Exact<{
  strapi_oed_id?: InputMaybe<Scalars['Int']['input']>;
  oed_type?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
  event_type?: InputMaybe<Status_Oed_Enum>;
}>;


export type InsertEventMutation = { __typename?: 'mutation_root', insert_event_one?: { __typename?: 'event', id: any } | null };

export type InsertOedMutationVariables = Exact<{
  name?: InputMaybe<Scalars['String']['input']>;
  strapi_id?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
}>;


export type InsertOedMutation = { __typename?: 'mutation_root', insert_oed_one?: { __typename?: 'oed', id: any } | null };

export type InsertRoleDefaultMetadataMutationVariables = Exact<{
  role_id?: InputMaybe<Scalars['uuid']['input']>;
}>;


export type InsertRoleDefaultMetadataMutation = { __typename?: 'mutation_root', insert_role_default_metadata?: { __typename?: 'role_default_metadata_mutation_response', returning: Array<{ __typename?: 'role_default_metadata', role_id: any, updated_at: any, id: any, metadatas: Array<string>, required_metadatas: Array<string> }> } | null };

export type InsertStorageMutationVariables = Exact<{
  name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  user_id: Scalars['uuid']['input'];
}>;


export type InsertStorageMutation = { __typename?: 'mutation_root', insert_storage_one?: { __typename?: 'storage', id: any, name?: string | null } | null };

export type InsertStorageOedIdMutationVariables = Exact<{
  strapi_oed_id?: InputMaybe<Scalars['Int']['input']>;
  storage_id?: InputMaybe<Scalars['uuid']['input']>;
  oed_type?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
}>;


export type InsertStorageOedIdMutation = { __typename?: 'mutation_root', insert_storage_oed_one?: { __typename?: 'storage_oed', id: any } | null };

export type UpdateOedByStrapiIdMutationVariables = Exact<{
  strapi_id?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  _set?: InputMaybe<Oed_Set_Input>;
}>;


export type UpdateOedByStrapiIdMutation = { __typename?: 'mutation_root', update_oed?: { __typename?: 'oed_mutation_response', affected_rows: number } | null };

export type UpdateRoleMetadatasByPkMutationVariables = Exact<{
  id?: InputMaybe<Scalars['uuid']['input']>;
  metadatas?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  required_metadatas?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
}>;


export type UpdateRoleMetadatasByPkMutation = { __typename?: 'mutation_root', update_role_default_metadata_by_pk?: { __typename?: 'role_default_metadata', id: any, role_id: any, required_metadatas: Array<string>, metadatas: Array<string>, updated_at: any } | null };

export type GetCountEventQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']['input']>;
}>;


export type GetCountEventQuery = { __typename?: 'query_root', exports: { __typename?: 'event_aggregate', aggregate?: { __typename?: 'event_aggregate_fields', count: number } | null }, preview: { __typename?: 'event_aggregate', aggregate?: { __typename?: 'event_aggregate_fields', count: number } | null } };

export type GetExclusiveOedsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetExclusiveOedsQuery = { __typename?: 'query_root', exclusive_oed: Array<{ __typename?: 'exclusive_oed', type?: string | null }> };

export type GetOedQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOedQuery = { __typename?: 'query_root', oed: Array<{ __typename?: 'oed', id: any, locked?: boolean | null, name?: string | null }> };

export type GetOedByStrapiIdQueryVariables = Exact<{
  strapi_id?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetOedByStrapiIdQuery = { __typename?: 'query_root', oed: Array<{ __typename?: 'oed', id: any }> };

export type GetOedTemplateQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOedTemplateQuery = { __typename?: 'query_root', oed_template: Array<{ __typename?: 'oed_template', name?: string | null, type?: string | null, alias?: string | null }> };

export type GetOedThumbnailQueryVariables = Exact<{
  strapi_id: Scalars['Int']['input'];
  type: Scalars['String']['input'];
}>;


export type GetOedThumbnailQuery = { __typename?: 'query_root', oed: Array<{ __typename?: 'oed', thumbnail_status?: string | null }> };

export type GetRoleMetadataByUserStrapiIdQueryVariables = Exact<{
  _userStrapiId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetRoleMetadataByUserStrapiIdQuery = { __typename?: 'query_root', role_default_metadata: Array<{ __typename?: 'role_default_metadata', metadatas: Array<string>, required_metadatas: Array<string>, role_id: any, id: any, updated_at: any }> };

export type GetUserQueryVariables = Exact<{
  id?: InputMaybe<Scalars['uuid']['input']>;
}>;


export type GetUserQuery = { __typename?: 'query_root', user_by_pk?: { __typename?: 'user', first_name?: string | null, user_roles: Array<{ __typename?: 'user_role', role?: { __typename?: 'role', permission?: any | null, name?: string | null } | null }>, temp_user_oeds: Array<{ __typename?: 'temp_user_oed', limit_exports?: string | null, limit_oed?: string | null, limit_views?: string | null }> } | null };

export type GetUserByLoginQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetUserByLoginQuery = { __typename?: 'query_root', login: Array<{ __typename?: 'login', users: Array<{ __typename?: 'user', id: any, user_roles: Array<{ __typename?: 'user_role', role?: { __typename?: 'role', name?: string | null, permission?: any | null, id: any, role_oed_exclusives: Array<{ __typename?: 'role_oed_exclusive', exclusive_oed?: { __typename?: 'exclusive_oed', type?: string | null, name?: string | null } | null }> } | null }>, temp_user_oeds: Array<{ __typename?: 'temp_user_oed', id: any, limit_exports?: string | null, limit_oed?: string | null, limit_views?: string | null }> }> }> };

export type GetUserRoleQueryVariables = Exact<{
  userStrapiId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetUserRoleQuery = { __typename?: 'query_root', user_role: Array<{ __typename?: 'user_role', role_id?: any | null }> };


export const InsertEventDocument = `
    mutation insertEvent($strapi_oed_id: Int, $oed_type: String, $user_id: uuid, $event_type: status_oed_enum) {
  insert_event_one(
    object: {event_type: $event_type, strapi_oed_id: $strapi_oed_id, oed_type: $oed_type, user_id: $user_id}
  ) {
    id
  }
}
    `;
export const useInsertEventMutation = <
      TError = unknown,
      TContext = unknown
    >(
      dataSource: { endpoint: string, fetchParams?: RequestInit },
      options?: UseMutationOptions<InsertEventMutation, TError, InsertEventMutationVariables, TContext>
    ) =>
    useMutation<InsertEventMutation, TError, InsertEventMutationVariables, TContext>(
      ['insertEvent'],
      (variables?: InsertEventMutationVariables) => fetcher<InsertEventMutation, InsertEventMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, InsertEventDocument, variables)(),
      options
    );
export const InsertOedDocument = `
    mutation InsertOED($name: String = "", $strapi_id: Int = 10, $type: String = "") {
  insert_oed_one(object: {name: $name, strapi_id: $strapi_id, type: $type}) {
    id
  }
}
    `;
export const useInsertOedMutation = <
      TError = unknown,
      TContext = unknown
    >(
      dataSource: { endpoint: string, fetchParams?: RequestInit },
      options?: UseMutationOptions<InsertOedMutation, TError, InsertOedMutationVariables, TContext>
    ) =>
    useMutation<InsertOedMutation, TError, InsertOedMutationVariables, TContext>(
      ['InsertOED'],
      (variables?: InsertOedMutationVariables) => fetcher<InsertOedMutation, InsertOedMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, InsertOedDocument, variables)(),
      options
    );
export const InsertRoleDefaultMetadataDocument = `
    mutation InsertRoleDefaultMetadata($role_id: uuid = "") {
  insert_role_default_metadata(
    objects: {role_id: $role_id, metadatas: [], required_metadatas: []}
  ) {
    returning {
      role_id
      updated_at
      id
      metadatas
      required_metadatas
    }
  }
}
    `;
export const useInsertRoleDefaultMetadataMutation = <
      TError = unknown,
      TContext = unknown
    >(
      dataSource: { endpoint: string, fetchParams?: RequestInit },
      options?: UseMutationOptions<InsertRoleDefaultMetadataMutation, TError, InsertRoleDefaultMetadataMutationVariables, TContext>
    ) =>
    useMutation<InsertRoleDefaultMetadataMutation, TError, InsertRoleDefaultMetadataMutationVariables, TContext>(
      ['InsertRoleDefaultMetadata'],
      (variables?: InsertRoleDefaultMetadataMutationVariables) => fetcher<InsertRoleDefaultMetadataMutation, InsertRoleDefaultMetadataMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, InsertRoleDefaultMetadataDocument, variables)(),
      options
    );
export const InsertStorageDocument = `
    mutation insertStorage($name: String = "", $type: String = "", $user_id: uuid!) {
  insert_storage_one(object: {name: $name, type: $type, user_id: $user_id}) {
    id
    name
  }
}
    `;
export const useInsertStorageMutation = <
      TError = unknown,
      TContext = unknown
    >(
      dataSource: { endpoint: string, fetchParams?: RequestInit },
      options?: UseMutationOptions<InsertStorageMutation, TError, InsertStorageMutationVariables, TContext>
    ) =>
    useMutation<InsertStorageMutation, TError, InsertStorageMutationVariables, TContext>(
      ['insertStorage'],
      (variables?: InsertStorageMutationVariables) => fetcher<InsertStorageMutation, InsertStorageMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, InsertStorageDocument, variables)(),
      options
    );
export const InsertStorageOedIdDocument = `
    mutation insertStorageOedID($strapi_oed_id: Int = 0, $storage_id: uuid = "", $oed_type: String = "", $title: String = "") {
  insert_storage_oed_one(
    object: {strapi_oed_id: $strapi_oed_id, storage_id: $storage_id, oed_type: $oed_type, title: $title}
  ) {
    id
  }
}
    `;
export const useInsertStorageOedIdMutation = <
      TError = unknown,
      TContext = unknown
    >(
      dataSource: { endpoint: string, fetchParams?: RequestInit },
      options?: UseMutationOptions<InsertStorageOedIdMutation, TError, InsertStorageOedIdMutationVariables, TContext>
    ) =>
    useMutation<InsertStorageOedIdMutation, TError, InsertStorageOedIdMutationVariables, TContext>(
      ['insertStorageOedID'],
      (variables?: InsertStorageOedIdMutationVariables) => fetcher<InsertStorageOedIdMutation, InsertStorageOedIdMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, InsertStorageOedIdDocument, variables)(),
      options
    );
export const UpdateOedByStrapiIdDocument = `
    mutation UpdateOEDByStrapiID($strapi_id: Int = 10, $type: String = "", $_set: oed_set_input = {}) {
  update_oed(
    where: {strapi_id: {_eq: $strapi_id}, type: {_eq: $type}}
    _set: $_set
  ) {
    affected_rows
  }
}
    `;
export const useUpdateOedByStrapiIdMutation = <
      TError = unknown,
      TContext = unknown
    >(
      dataSource: { endpoint: string, fetchParams?: RequestInit },
      options?: UseMutationOptions<UpdateOedByStrapiIdMutation, TError, UpdateOedByStrapiIdMutationVariables, TContext>
    ) =>
    useMutation<UpdateOedByStrapiIdMutation, TError, UpdateOedByStrapiIdMutationVariables, TContext>(
      ['UpdateOEDByStrapiID'],
      (variables?: UpdateOedByStrapiIdMutationVariables) => fetcher<UpdateOedByStrapiIdMutation, UpdateOedByStrapiIdMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, UpdateOedByStrapiIdDocument, variables)(),
      options
    );
export const UpdateRoleMetadatasByPkDocument = `
    mutation UpdateRoleMetadatasByPK($id: uuid = "", $metadatas: [String!] = [], $required_metadatas: [String!] = []) {
  update_role_default_metadata_by_pk(
    pk_columns: {id: $id}
    _set: {required_metadatas: $required_metadatas, metadatas: $metadatas}
  ) {
    id
    role_id
    required_metadatas
    metadatas
    updated_at
  }
}
    `;
export const useUpdateRoleMetadatasByPkMutation = <
      TError = unknown,
      TContext = unknown
    >(
      dataSource: { endpoint: string, fetchParams?: RequestInit },
      options?: UseMutationOptions<UpdateRoleMetadatasByPkMutation, TError, UpdateRoleMetadatasByPkMutationVariables, TContext>
    ) =>
    useMutation<UpdateRoleMetadatasByPkMutation, TError, UpdateRoleMetadatasByPkMutationVariables, TContext>(
      ['UpdateRoleMetadatasByPK'],
      (variables?: UpdateRoleMetadatasByPkMutationVariables) => fetcher<UpdateRoleMetadatasByPkMutation, UpdateRoleMetadatasByPkMutationVariables>(dataSource.endpoint, dataSource.fetchParams || {}, UpdateRoleMetadatasByPkDocument, variables)(),
      options
    );
export const GetCountEventDocument = `
    query getCountEvent($_eq: uuid = "") {
  exports: event_aggregate(
    where: {event_type: {_eq: export_succeeded}, user_id: {_eq: $_eq}}
  ) {
    aggregate {
      count
    }
  }
  preview: event_aggregate(
    where: {event_type: {_eq: preview}, user_id: {_eq: $_eq}}
  ) {
    aggregate {
      count
    }
  }
}
    `;
export const useGetCountEventQuery = <
      TData = GetCountEventQuery,
      TError = unknown
    >(
      dataSource: { endpoint: string, fetchParams?: RequestInit },
      variables?: GetCountEventQueryVariables,
      options?: UseQueryOptions<GetCountEventQuery, TError, TData>
    ) =>
    useQuery<GetCountEventQuery, TError, TData>(
      variables === undefined ? ['getCountEvent'] : ['getCountEvent', variables],
      fetcher<GetCountEventQuery, GetCountEventQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, GetCountEventDocument, variables),
      options
    );
export const GetExclusiveOedsDocument = `
    query GetExclusiveOeds {
  exclusive_oed {
    type
  }
}
    `;
export const useGetExclusiveOedsQuery = <
      TData = GetExclusiveOedsQuery,
      TError = unknown
    >(
      dataSource: { endpoint: string, fetchParams?: RequestInit },
      variables?: GetExclusiveOedsQueryVariables,
      options?: UseQueryOptions<GetExclusiveOedsQuery, TError, TData>
    ) =>
    useQuery<GetExclusiveOedsQuery, TError, TData>(
      variables === undefined ? ['GetExclusiveOeds'] : ['GetExclusiveOeds', variables],
      fetcher<GetExclusiveOedsQuery, GetExclusiveOedsQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, GetExclusiveOedsDocument, variables),
      options
    );
export const GetOedDocument = `
    query getOed {
  oed {
    id
    locked
    name
  }
}
    `;
export const useGetOedQuery = <
      TData = GetOedQuery,
      TError = unknown
    >(
      dataSource: { endpoint: string, fetchParams?: RequestInit },
      variables?: GetOedQueryVariables,
      options?: UseQueryOptions<GetOedQuery, TError, TData>
    ) =>
    useQuery<GetOedQuery, TError, TData>(
      variables === undefined ? ['getOed'] : ['getOed', variables],
      fetcher<GetOedQuery, GetOedQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, GetOedDocument, variables),
      options
    );
export const GetOedByStrapiIdDocument = `
    query getOedByStrapiID($strapi_id: Int = 10, $type: String = "") {
  oed(where: {strapi_id: {_eq: $strapi_id}, type: {_eq: $type}}) {
    id
  }
}
    `;
export const useGetOedByStrapiIdQuery = <
      TData = GetOedByStrapiIdQuery,
      TError = unknown
    >(
      dataSource: { endpoint: string, fetchParams?: RequestInit },
      variables?: GetOedByStrapiIdQueryVariables,
      options?: UseQueryOptions<GetOedByStrapiIdQuery, TError, TData>
    ) =>
    useQuery<GetOedByStrapiIdQuery, TError, TData>(
      variables === undefined ? ['getOedByStrapiID'] : ['getOedByStrapiID', variables],
      fetcher<GetOedByStrapiIdQuery, GetOedByStrapiIdQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, GetOedByStrapiIdDocument, variables),
      options
    );
export const GetOedTemplateDocument = `
    query getOedTemplate {
  oed_template {
    name
    type
    alias
  }
}
    `;
export const useGetOedTemplateQuery = <
      TData = GetOedTemplateQuery,
      TError = unknown
    >(
      dataSource: { endpoint: string, fetchParams?: RequestInit },
      variables?: GetOedTemplateQueryVariables,
      options?: UseQueryOptions<GetOedTemplateQuery, TError, TData>
    ) =>
    useQuery<GetOedTemplateQuery, TError, TData>(
      variables === undefined ? ['getOedTemplate'] : ['getOedTemplate', variables],
      fetcher<GetOedTemplateQuery, GetOedTemplateQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, GetOedTemplateDocument, variables),
      options
    );
export const GetOedThumbnailDocument = `
    query GetOedThumbnail($strapi_id: Int!, $type: String!) {
  oed(where: {strapi_id: {_eq: $strapi_id}, type: {_eq: $type}}) {
    thumbnail_status
  }
}
    `;
export const useGetOedThumbnailQuery = <
      TData = GetOedThumbnailQuery,
      TError = unknown
    >(
      dataSource: { endpoint: string, fetchParams?: RequestInit },
      variables: GetOedThumbnailQueryVariables,
      options?: UseQueryOptions<GetOedThumbnailQuery, TError, TData>
    ) =>
    useQuery<GetOedThumbnailQuery, TError, TData>(
      ['GetOedThumbnail', variables],
      fetcher<GetOedThumbnailQuery, GetOedThumbnailQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, GetOedThumbnailDocument, variables),
      options
    );
export const GetRoleMetadataByUserStrapiIdDocument = `
    query GetRoleMetadataByUserStrapiId($_userStrapiId: Int) {
  role_default_metadata(
    where: {role: {user_roles: {user: {login: {strapi_user_id: {_eq: $_userStrapiId}}}}}}
  ) {
    metadatas
    required_metadatas
    role_id
    id
    updated_at
  }
}
    `;
export const useGetRoleMetadataByUserStrapiIdQuery = <
      TData = GetRoleMetadataByUserStrapiIdQuery,
      TError = unknown
    >(
      dataSource: { endpoint: string, fetchParams?: RequestInit },
      variables?: GetRoleMetadataByUserStrapiIdQueryVariables,
      options?: UseQueryOptions<GetRoleMetadataByUserStrapiIdQuery, TError, TData>
    ) =>
    useQuery<GetRoleMetadataByUserStrapiIdQuery, TError, TData>(
      variables === undefined ? ['GetRoleMetadataByUserStrapiId'] : ['GetRoleMetadataByUserStrapiId', variables],
      fetcher<GetRoleMetadataByUserStrapiIdQuery, GetRoleMetadataByUserStrapiIdQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, GetRoleMetadataByUserStrapiIdDocument, variables),
      options
    );
export const GetUserDocument = `
    query getUser($id: uuid = "") {
  user_by_pk(id: $id) {
    first_name
    user_roles {
      role {
        permission
        name
      }
    }
    temp_user_oeds {
      limit_exports
      limit_oed
      limit_views
    }
  }
}
    `;
export const useGetUserQuery = <
      TData = GetUserQuery,
      TError = unknown
    >(
      dataSource: { endpoint: string, fetchParams?: RequestInit },
      variables?: GetUserQueryVariables,
      options?: UseQueryOptions<GetUserQuery, TError, TData>
    ) =>
    useQuery<GetUserQuery, TError, TData>(
      variables === undefined ? ['getUser'] : ['getUser', variables],
      fetcher<GetUserQuery, GetUserQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, GetUserDocument, variables),
      options
    );
export const GetUserByLoginDocument = `
    query GetUserByLogin($_eq: Int = 10) {
  login(where: {strapi_user_id: {_eq: $_eq}}) {
    users {
      id
      user_roles {
        role {
          name
          permission
          id
          role_oed_exclusives {
            exclusive_oed {
              type
              name
            }
          }
        }
      }
      temp_user_oeds {
        id
        limit_exports
        limit_oed
        limit_views
      }
    }
  }
}
    `;
export const useGetUserByLoginQuery = <
      TData = GetUserByLoginQuery,
      TError = unknown
    >(
      dataSource: { endpoint: string, fetchParams?: RequestInit },
      variables?: GetUserByLoginQueryVariables,
      options?: UseQueryOptions<GetUserByLoginQuery, TError, TData>
    ) =>
    useQuery<GetUserByLoginQuery, TError, TData>(
      variables === undefined ? ['GetUserByLogin'] : ['GetUserByLogin', variables],
      fetcher<GetUserByLoginQuery, GetUserByLoginQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, GetUserByLoginDocument, variables),
      options
    );
export const GetUserRoleDocument = `
    query GetUserRole($userStrapiId: Int = 10) {
  user_role(where: {user: {login: {strapi_user_id: {_eq: $userStrapiId}}}}) {
    role_id
  }
}
    `;
export const useGetUserRoleQuery = <
      TData = GetUserRoleQuery,
      TError = unknown
    >(
      dataSource: { endpoint: string, fetchParams?: RequestInit },
      variables?: GetUserRoleQueryVariables,
      options?: UseQueryOptions<GetUserRoleQuery, TError, TData>
    ) =>
    useQuery<GetUserRoleQuery, TError, TData>(
      variables === undefined ? ['GetUserRole'] : ['GetUserRole', variables],
      fetcher<GetUserRoleQuery, GetUserRoleQueryVariables>(dataSource.endpoint, dataSource.fetchParams || {}, GetUserRoleDocument, variables),
      options
    );