type TDataSource = {
  endpoint: string;
  fetchParams?: RequestInit | undefined;
};

const dataSource: TDataSource = {
  endpoint: process.env.ENDPOINT || '',

  fetchParams: {
    headers: {
      'x-hasura-admin-secret': process.env.HASURA_SECRET || '',

      'content-type': 'application/json',
    } as HeadersInit,
  },
};

export default dataSource;

export const requestHeaders = {
  'x-hasura-admin-secret': process.env.HASURA_SECRET || '',
};

export const requestHeadersStrapi = {
  Authorization:
    'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNzAyMzIzNjQ1LCJleHAiOjE3MDQ5MTU2NDV9.knJRQggN5owrpQ64o4KVfIEmIqKsu7F08O6vBD8biaw',
  'Content-Type': 'application/json',
};
