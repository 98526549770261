export default function getCookie(name: string) {
  return extractCookie(document.cookie, name);
}

export function extractCookie(cookies: string, name: string) {
  const value = `; ${cookies}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    return parts?.pop?.()?.split(';').shift();
  }

  return null;
}
