import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { requestHeaders } from '@/config/dataSource';
import { useAppSelector } from '..';
import axios from 'axios';

export type ExclusiveOeds = {
  exclusive_oed: {
    type: string;
    oed_type_id: string;
    name: string;
  };
};

export type Role = {
  role: {
    id: string;
    name: string;
    permission: {
      edit: boolean;
      view: boolean;
      create: boolean;
      export: boolean;
    };
    role_oed_exclusives: ExclusiveOeds[];
  };
};

export type TempUserOed = {
  limit_exports: string;
  limit_oed: string;
  limit_views: string;
};

export type Storages = {
  name: string;
  storage_oeds: {
    strapi_oed_id: number;
    active: boolean;
  };
};

export type Users = {
  id: string;
  first_name: string;
  last_name: string;
  temp_user_oeds: TempUserOed[];
  user_roles: Role[];
  storages: Storages[];
};

export type UserData = {
  users: Users[];
  userStrapiId: number;
  email: string;
  oedTypesAvailable: string[];
};

export type UserDataState = {
  initialData: UserData;
  isLoading: boolean;
  hasError: boolean;
};

const initialState: UserDataState = {
  initialData: {} as any,
  // data: null,
  isLoading: false,
  hasError: false,
};

export const loadUserData = createAsyncThunk(
  'payload/userdata',
  async (strap_user_id: number) => {
    // console.log(strap_user_id);
    const userReq = axios.post(
      process.env.ENDPOINT || '',
      {
        query: `
      query GetUserByLogin($_eq: Int = 10) {
        login(where: {strapi_user_id: {_eq: $_eq}}) {
          email
          users {
            id
            first_name
            last_name
            user_roles {
              role {
                name
                permission
                id
                role_oed_exclusives {
                  exclusive_oed {
                    type
                    name
                  }
                }
              }
            }
            temp_user_oeds {
              id
              limit_exports
              limit_oed
              limit_views
            }
            storages {
              name
              storage_oeds {
                strapi_oed_id
                active
              }
            }
          }
        }
      }
    `,
        variables: {
          _eq: strap_user_id,
        },
      },
      {
        headers: requestHeaders,
      },
    );

    // groupOedsPermission
    const groupOedsPermissionReq = axios.post<
      Array<{
        oed: {
          type: any;
          persmission: any;
        };
      }>
    >('/api/getGroup');

    const [userRes, groupOedsPermissionRes] = await Promise.all([
      userReq,
      groupOedsPermissionReq,
    ]);

    const user = userRes.data;
    const groupOedsPermission = groupOedsPermissionRes.data;

    const groupOedTypes = groupOedsPermission.map((item) => item.oed.type);
    const userRoles = user.data.login[0].users[0].user_roles;

    userRoles.forEach(({ role }: any) => {
      const oedsExclusive = [] as string[];
      role.role_oed_exclusives.forEach((exclusive: any) => {
        oedsExclusive.push(exclusive.exclusive_oed.type);
      });

      groupOedTypes.push(...oedsExclusive);
    });

    return {
      userData: {
        ...user.data.login[0],
        userStrapiId: strap_user_id,
        oedTypesAvailable: groupOedTypes,
      },
    };
  },
);

const userData = createSlice({
  name: 'userData',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(loadUserData.pending, (state) => {
      state.isLoading = true;
      state.hasError = false;
    });

    builder.addCase(loadUserData.rejected, (state) => {
      state.isLoading = false;
      state.hasError = true;
    });

    builder.addCase(loadUserData.fulfilled, (state, action) => {
      state.isLoading = false;
      state.initialData = action.payload.userData;
      state.hasError = false;
    });
  },
});

export const user = userData.reducer;

export const userActions = userData.actions;

export const useUserData = () => {
  return useAppSelector((state) => {
    return state.user;
  });
};
