import axios from 'axios';

export const endpointPathMap = {
  quiz: 'quiz.quiz',
  pin: 'infografico-pin.infografico-pin',
  pintexto: 'infog-pin-texto-lateal.infog-pin-texto-lateal',
  acordeon: 'infografico-acordeon.infografico-acordeon',
  slideshow: 'slideshow.slideshow',
  'quiz-moderna': 'quiz-moderna.quiz-moderna',
  'miniclipe-moderna': 'miniclipe-moderna.miniclipe-moderna',
  'galeria-moderna': 'galeria-moderna.galeria-moderna',
  'carrossel-moderna': 'carrossel-moderna.carrossel-moderna',
  'quiz-nextoed': 'quiz-next-oed.quiz-next-oed',
  zoom: 'zoom.zoom',
  'carrossel-nextoed': 'carrossel-nextoed.carrossel-nextoed',
};

export default async function getOed<T>(
  userToken: string,
  oedType:
    | 'quiz'
    | 'pin'
    | 'pintexto'
    | 'acordeon'
    | 'slideshow'
    | 'quiz-moderna'
    | 'miniclipe-moderna'
    | 'galeria-moderna'
    | 'carrossel-moderna'
    | 'quiz-nextoed'
    | 'zoom'
    | 'carrossel-nextoed',
  oedId: string,
) {
  const response = await axios.get(
    `https://np-builder.nextpage.com.br/content-manager/collection-types/api::${endpointPathMap[oedType]}/${oedId}`,
    {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    },
  );

  return { type: oedType, data: response.data as T };
}
