import { globalCss } from '.';
import { colors, radii, fontSizes } from './tokens';

export const globalStyles = globalCss({
  '*': {
    boxSizing: 'border-box',
    padding: 0,
    margin: 0,
    fontFamily: 'Poppins',
  },
  body: {
    '-webkit-font-smoothing': 'antialiased',
    fontFamily: '$default',
  },
  button: {
    cursor: 'pointer',
  },

  '.ReactModal__Overlay': {
    backgroundColor: 'rgba(0,0,0, 0.8) !important',
    opacity: 0,
    zIndex: 99999,
    transition: 'all 500ms ease-in-out',
  },

  '.ReactModal__Overlay--after-open': {
    opacity: 1,
    transform: 'translateX(0px)',
  },

  '.ReactModal__Content.ReactModal__Content--after-open': {
    top: '50% !important',
    left: '50% !important',

    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    inset: 0,
  },

  '.ReactModal__Overlay--before-close': {
    opacity: 0,
    transform: 'translateX(-100px)',
  },

  '.ck-powered-by': {
    display: 'none !important',
  },

  '.ck': {
    '--ck-color-base-background': colors['gray-100'], // background do input
    '--ck-color-button-on-color': colors['primary-500'], // color dos botões pressionados
    '--ck-color-button-on-background': colors['primary-300'], // background dos botões pressionados
    '--ck-color-button-on-hover-background': colors['gray-300'], // background:hover dos botões pressionados
    '--ck-color-button-on-active-background': colors['primary-400'], // background:active dos botões pressionados
    '--ck-color-button-default-hover-background': colors['gray-200'], // background:hover dos botões
    '--ck-color-button-default-active-background': colors['gray-200'], // background:active dos botões
    '--ck-color-button-cancel': colors['errorMessage'], // color botões cancelar
    '--ck-color-base-error': colors['errorMessage'], // color das mensagens de erro
    '--ck-color-base-active': colors['primary-500'],
    '--ck-color-base-focus': colors['primary-500'],
    '--ck-color-base-active-focus': colors['primary-500'],
    '--ck-color-focus-border': colors['primary-500'],
    '--ck-color-focus-outer-shadow': colors['primary-300'],
    '--ck-color-focus-disabled-shadow': colors['primary-300'],
    '--ck-color-color-grid-check-icon': colors['primary-500'],
    '--ck-color-list-button-on-background': colors['primary-500'],
    '--ck-color-list-button-on-background-focus': colors['primary-500'],
    '--ck-color-upload-bar-background': colors['primary-400'],
    '--ck-focus-ring': colors['primary-400'],
    '--ck-focus-outer-shadow': `0 0 0 3px ${colors['primary-300']}`,
    '--ck-focus-disabled-outer-shadow': `0 0 0 3px ${colors['primary-300']}`,
    '--ck-clipboard-drop-target-color': colors['primary-400'],

    '--ck-color-base-border': colors['gray-300'], // borderColor geral
    '--ck-border-radius': radii['md'], // borderRadius geral
    '--ck-inner-shadow': `0 0 0 3px ${colors['primary-300']} inset`, // boxShadow do input
    '--ck-color-widget-type-around-button-active': colors['primary-500'], // color das setas das tabelas
    '--ck-color-selector-column-resizer-hover': colors['primary-500'],
  },

  '.ck.ck-button.ck-on, a.ck.ck-button.ck-on': {
    borderColor: '$primary-400',
    ':active': {
      color: '$white-100',
    },
  },

  '.ck-content': {
    minHeight: '100px',
    padding: '$2 $4 !important',
    borderRadius: ` 0 0 ${radii['md']} ${radii['md']} !important`,
  },

  '.ck-editor__editable, .ck-toolbar': {
    border: '2px solid $gray-300 !important',
  },

  '.np-custom-ck-counter': {
    width: '100%',
    fontSize: fontSizes['xs'],
    opacity: '0.8',
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: '$2',
  },

  '.np-custom-ck-counter-current.warning': {
    color: '$errorMessage',
  },

  '.ck.ck-dropdown .ck-dropdown__arrow': {
    zIndex: 0,
  },
});
