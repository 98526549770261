import { configureStore } from '@reduxjs/toolkit';
import { useSelector, TypedUseSelectorHook, useDispatch } from 'react-redux';
import { oeds } from './slices/oedsSlice';
import { user } from './slices/userSlice';
import { config } from './slices/configSlice';

export const store = configureStore({
  reducer: {
    oeds,
    user,
    config,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppDispatch: () => AppDispatch = useDispatch;
