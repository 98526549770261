import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import dataSource from '@/config/dataSource';
import { useAppSelector } from '..';
import axios, { AxiosHeaders } from 'axios';
import {
  GetRoleMetadataByUserStrapiIdDocument,
  GetUserRoleDocument,
  InsertRoleDefaultMetadataDocument,
  UpdateRoleMetadatasByPkDocument,
} from '@/generated/graphql';

export type RoleMetadata = {
  id: string;
  role_id: string;
  metadatas: Array<string>;
  required_metadatas: Array<string>;
  updated_at: string;
};

export type ConfigData = {
  metadata: RoleMetadata | null;
};

export type ConfigState = {
  data: ConfigData | null;
  initialData: ConfigData | null;
  isLoading: boolean;
  hasError: boolean;
};

const initialState: ConfigState = {
  initialData: null,
  data: null,
  isLoading: false,
  hasError: false,
};

export type ConfigUpdateRoleMetadatas = {
  configId: string;
  metadatas: Array<string>;
};

export const loadRoleMetadataConfig = createAsyncThunk(
  'get/role/metadata_config',
  async (userStrapiId: number) => {
    const response = await axios.post<{
      data: {
        role_default_metadata: Array<RoleMetadata>;
      };
    }>(
      dataSource.endpoint,
      {
        query: GetRoleMetadataByUserStrapiIdDocument,
        variables: {
          _userStrapiId: userStrapiId,
        },
      },
      { headers: (dataSource.fetchParams?.headers as AxiosHeaders) || {} },
    );

    if (response.data.data.role_default_metadata.length === 0) {
      const userRole = await axios.post<{
        data: { user_role: Array<{ role_id: string }> };
      }>(
        dataSource.endpoint,
        {
          query: GetUserRoleDocument,
          variables: {
            userStrapiId,
          },
        },
        { headers: (dataSource.fetchParams?.headers as AxiosHeaders) || {} },
      );

      const roleId = userRole.data.data.user_role[0].role_id;
      if (roleId) {
        const insertRole = await axios.post<{
          data: {
            insert_role_default_metadata: {
              returning: RoleMetadata[];
            };
          };
        }>(
          dataSource.endpoint,
          {
            query: InsertRoleDefaultMetadataDocument,
            variables: {
              role_id: userRole.data.data.user_role[0].role_id,
            },
          },
          { headers: (dataSource.fetchParams?.headers as AxiosHeaders) || {} },
        );

        return {
          metadataConfig:
            insertRole.data.data.insert_role_default_metadata.returning[0],
        };
      }
    }

    return {
      metadataConfig: response.data.data.role_default_metadata[0],
    };
  },
);

export const updateRoleMetadatas = createAsyncThunk(
  'update/role/metadata_config',
  async (params: {
    configId: string;
    metadatas: Array<string>;
    required_metadatas: Array<string>;
  }) => {
    const response = await axios.post<{
      data: {
        update_role_default_metadata_by_pk: RoleMetadata;
      };
    }>(
      dataSource.endpoint,
      {
        query: UpdateRoleMetadatasByPkDocument,
        variables: {
          id: params.configId,
          metadatas: params.metadatas,
          required_metadatas: params.required_metadatas,
        },
      },
      { headers: (dataSource.fetchParams?.headers as AxiosHeaders) || {} },
    );

    return {
      metadataConfig: response.data.data.update_role_default_metadata_by_pk,
    };
  },
);

const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(loadRoleMetadataConfig.pending, (state) => {
      state.isLoading = true;
      state.hasError = false;
    });

    builder.addCase(loadRoleMetadataConfig.rejected, (state) => {
      state.isLoading = false;
      state.hasError = true;
    });

    builder.addCase(loadRoleMetadataConfig.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = {
        ...(state.data || {}),
        metadata: action.payload.metadataConfig,
      };
      state.initialData = {
        ...(state.initialData || {}),
        metadata: action.payload.metadataConfig,
      };
      state.hasError = false;
    });

    builder.addCase(updateRoleMetadatas.pending, (state) => {
      state.isLoading = true;
      state.hasError = false;
    });

    builder.addCase(updateRoleMetadatas.rejected, (state) => {
      state.isLoading = false;
      state.hasError = true;
    });

    builder.addCase(updateRoleMetadatas.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = {
        ...(state.data || {}),
        metadata: action.payload.metadataConfig,
      };
      state.initialData = {
        ...(state.initialData || {}),
        metadata: action.payload.metadataConfig,
      };
      state.hasError = false;
    });
  },
});

export const config = configSlice.reducer;

// console.log(oeds)

export const configActions = configSlice.actions;

export const useConfig = () => {
  return useAppSelector((state) => {
    // console.log(data)
    return state.config;
  });
};
